import axiosTokenInstance from "../../../services/api/backoffice";

import { IMEI, IMEI_ERROR } from "_models/redux/types";

// Action function
export const getImei = (params) => async (dispatch) => {
    const { token, key, new_password, ticket_id } = params || {
        token: undefined,
        key: undefined,
        new_password: undefined,
        ticket_id: undefined,
    };

    const data = {
        token,
        key,
        new_password,
        ticket_id,
    };
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/${ticket_id}/actions/device-info`
        );

        const { data } = response || {};

        dispatch({
            type: IMEI,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: IMEI_ERROR,
            payload: e || {},
        });
    }
};
