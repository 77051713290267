import React, { useEffect, useState, useContext } from "react";
import {
    Grid,
    FormControl,
    Select,
    MenuItem,
    ListItemAvatar,
    Avatar,
} from "@mui/material";
import { connect } from "react-redux";
import { getMyTickets } from "_models/redux/my-tickets/action";
import ActionSendApn from "./actions-tickets/action-send-apn";
import ActionBarring from "./actions-tickets/action-barring";
import ActionSuspendLine from "./actions-tickets/action-suspend-line";
import ActionUnbarring from "./actions-tickets/action-unbarring";
import ModalSim from "./modal-new-icc";
import ActionImei from "./actions-tickets/action-imei";
import ActionPortability from "./actions-tickets/action-portability";
import LockImeiContainer from "./actions-tickets/action-lock-imei";
import UnlockImeiContainer from "./actions-tickets/action-unlock-imei";
import ResumeLine from "./actions-tickets/action-resume-line";
import ChangeNir from "./actions-tickets/action-change-nir";
import { LoadingButton } from "@mui/lab";
import SupplementaryService from "./actions-tickets/action-supplementary-service";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { getUsers } from "_models/redux/get-user-list/action";
import { changeUser } from "_models/redux/change-user/action";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import { useParams } from "react-router-dom";
import { checkPermission } from "_controllers/helpers/checkPermission";
import CustomDialog from "widgets/component/CustomDialog";
import ActionApplyPackage from "modules/support/my-tickets/flow/detailTickets3/widgets/actions-tickets/apply-package";
import { LoadersContext } from "_models/context/app";

const ActionsBar = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext;

    const { changeUser_ } = props;
    const [modalOpenSim, setModalOpenSim] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [imeiNumber, setImeiNumber] = useState("");
    const [valueAgent, setValueAgent] = useState();
    const [agentList, setAgentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketId, setTicketId] = useState();
    const [directNumber, setDirectNumber] = useState("");
    const [loader, setLoader] = useState(false);
    const [statusProfile, setStatusProfile] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    let params = useParams();

    const handleChange = async (event) => {
        setValueAgent(event.target.value);
        setIsScreenLoading(true);

        try {
            const response = await props.changeUser({
                ticketId: ticketId,
                user_id: event.target.value,
            });

            setIsScreenLoading(false);

            if (changeUser_?.response?.message === "success") {
                setDialogMessage("Usuario cambiado con éxito");
                setIsDialogOpen(true);
            } else if (changeUser_?.error?.response?.status > 300) {
                setDialogMessage(changeUser_?.error?.response?.data?.detail);
                setIsDialogOpen(true);
            }
        } catch (error) {
            setIsScreenLoading(false);

            console.error("Error en handleChange:", error);
            setDialogMessage("Ocurrió un error al cambiar el usuario.");
            setIsDialogOpen(true);
        } finally {
            setIsScreenLoading(false);
        }
    };

    useEffect(() => {
        setTicketId(params.ticketId);
        props.getUsers();
    }, []);

    useEffect(() => {
        const { events } = props.events || { events: undefined };

        if (Array.isArray(events.events)) {
            setEventsList(events.events);
            setLoader(false);
        }
    }, [props.events]);

    useEffect(() => {
        const { users } = props.userList.response || {
            users: undefined,
        };

        if (typeof users != "undefined") {
            setAgentList(users);
        }
    }, [props.userList]);

    useEffect(() => {
        const { customerProfile } = props.customerProfile || {
            customerProfile: undefined,
        };
        if (typeof customerProfile != "undefined") {
            setDirectNumber(customerProfile?.profile?.direct_number);
            setImeiNumber(customerProfile?.profile?.imei);
            setStatusProfile(customerProfile?.status?.subStatus);
        }
    }, [props.customerProfile]);

    useEffect(() => {
        setLoader(true);
    }, [props.changeUser_]);

    useEffect(() => {
        const response = props.ticketById.response || undefined;

        if (typeof response != "undefined") {
            setIsLoading(false);
            setValueAgent(response.assigned_to_user_id);
            setIsScreenLoading(false);
        }
    }, [props.ticketById]);

    const DropdownAgentSelect = () => (
        <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <FormControl variant="standard" fullWidth size="small">
                    <Select
                        variant="standard"
                        defaultValue={2}
                        fullWidth
                        labelId="agent-select"
                        id="agent-select"
                        value={valueAgent}
                        onChange={handleChange}
                    >
                        {agentList.map((agent) => (
                            <MenuItem key={agent.id} value={agent.id || -1}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                backgroundColor: "#FFC6F4",
                                                color: "#E452B4",
                                                fontSize: 14,
                                                borderRadius: 30,
                                            }}
                                        >
                                            {agent?.name[0]}
                                            {agent?.last_name[0]}
                                            {agent?.mothers_last_name ===
                                            undefined
                                                ? ""
                                                : agent?.mothers_last_name[0]}
                                        </Avatar>
                                    </ListItemAvatar>
                                    {agent.name}
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                className="button-box"
                textAlign={"start"}
                paddingLeft={2}
                paddingRight={2}
                direction="column"
            >
                {" "}
                {checkPermission("SUPPORT_TICKETS_SUPERVISOR") ? (
                    <Grid
                        container
                        direction={"row"}
                        alignItems={"center"}
                        className="m-b-10"
                    >
                        <Grid item xs={3} textAlign={"end"}>
                            <h5>Asignar a: </h5>
                        </Grid>
                        <Grid item xs={9} paddingLeft={1}>
                            <DropdownAgentSelect />
                        </Grid>
                    </Grid>
                ) : null}
                <ActionImei directNumber={directNumber} ticketId={ticketId} />
                {checkPermission("SUPPORT_TICKETS_ACTIONS") ? (
                    <LoadingButton
                        fullWidth
                        size="small"
                        onClick={() => {
                            setModalOpenSim(true);
                        }}
                        variant="outlined"
                        className="button"
                    >
                        <h4>Cambio de SIM</h4>
                    </LoadingButton>
                ) : null}
                {modalOpenSim && (
                    <ModalSim
                        modalOpen={modalOpenSim}
                        setOpenModal={setModalOpenSim}
                        ticketId={ticketId}
                    />
                )}
                <br />
                {checkPermission("SUPPORT_TICKETS_ACTIONS") && (
                    <>
                        <SupplementaryService
                            directNumber={directNumber}
                            ticketId={ticketId}
                        />
                        <ActionSendApn
                            directNumber={directNumber}
                            ticketId={ticketId}
                        />
                        <LockImeiContainer
                            directNumber={directNumber}
                            ticketId={ticketId}
                            imeiNumber={imeiNumber}
                        />
                        <UnlockImeiContainer
                            ticketId={ticketId}
                            imeiNumber={imeiNumber}
                        />
                    </>
                )}
                {checkPermission("SUPPORT_TICKETS_ACTIONS") &&
                statusProfile == "Active" ? (
                    <ActionBarring ticketId={ticketId} />
                ) : null}
                {checkPermission("SUPPORT_TICKETS_ACTIONS") &&
                statusProfile == "Barring (B1W) (Notified by client)" ? (
                    <ActionUnbarring ticketId={ticketId} />
                ) : null}
                {checkPermission("SUPPORT_TICKETS_ACTIONS") &&
                statusProfile == "Active" ? (
                    <ActionSuspendLine
                        ticketId={ticketId}
                        directNumber={directNumber}
                    />
                ) : null}
                {checkPermission("SUPPORT_TICKETS_ACTIONS") &&
                statusProfile == "Suspend (B2W)" ? (
                    <ResumeLine
                        ticketId={ticketId}
                        directNumber={directNumber}
                    />
                ) : null}
                {checkPermission("SUPPORT_TICKETS_ACTIONS") ? (
                    <ChangeNir
                        ticketId={ticketId}
                        directNumber={directNumber}
                    />
                ) : null}
                {checkPermission("SUPPORT_TICKETS_ACTIONS") ? (
                    <ActionPortability
                        ticketId={ticketId}
                        directNumber={directNumber}
                    />
                ) : null}
                {checkPermission("SUPPORT_TICKETS_ACTIONS") ? (
                    <ActionApplyPackage
                        ticketId={ticketId}
                        directNumber={directNumber}
                    />
                ) : null}
            </Grid>

            <CustomDialog
                title="Cambio de Usuario"
                content={dialogMessage}
                onClose={() => setIsDialogOpen(false)}
                open={isDialogOpen}
                fullWidth
                maxWidth="xs"
                showCloseButton={false}
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: () => setIsDialogOpen(false),
                        buttonColor: "#724ECB",
                        textColor: "white",
                    },
                ]}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    tickets: state.tickets,
    events: state.events,
    customerProfile: state.customerProfile,
    userList: state.userList,
    changeUser_: state.changeUser_,
    ticketById: state.ticketById,
});

const mapDispatchToProps = {
    getMyTickets,
    getCustomerProfile,
    getUsers,
    changeUser,
    getTicketById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsBar);
