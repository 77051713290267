import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const CustomPaginationTable = ({
    page,
    pageSize,
    rowCount,
    onPageChange,
    onPageSizeChange,
}) => {
    // Calculando el índice de inicio y fin de los elementos mostrados
    const startIndex = (page - 1) * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, rowCount);
    // Calculando el nuevo número total de páginas
    const totalPages = Math.ceil(rowCount / pageSize);

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            <Box>
                {rowCount === 0
                    ? "No se encontraron resultados"
                    : `${startIndex} - ${endIndex} de ${rowCount}`}
            </Box>

            <Select
                variant="standard"
                size="small"
                defaultValue={pageSize}
                onChange={onPageSizeChange}
                MenuProps={{
                    PaperProps: { sx: { maxHeight: 360 } },
                }}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={100}>100</MenuItem>
            </Select>

            <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => onPageChange(value)}
                boundaryCount={3}
                siblingCount={1}
                showFirstButton
                showLastButton
                color="secondary"
                variant="outlined"
                shape="rounded"
            />
        </Stack>
    );
};

export default CustomPaginationTable;
