import axiosTokenInstance from "../../../services/api/backoffice";
import {
    SUPPLEMENTARY_SERVICE,
    SUPPLEMENTARY_SERVICE_ERROR,
} from "_models/redux/types";

// Action function
export const getSupplementaryService = (params) => async (dispatch) => {
    const { token, key, new_password, ticket_id } = params || {
        token: undefined,
        key: undefined,
        new_password: undefined,
        ticket_id: undefined,
    };

    const data = {
        token,
        key,
        new_password,
        ticket_id,
    };
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/${ticket_id}/actions/suplementary-services`
        );

        const { data } = response || {};

        dispatch({
            type: SUPPLEMENTARY_SERVICE,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: SUPPLEMENTARY_SERVICE_ERROR,
            payload: e || {},
        });
    }
};
