import React, { useEffect, useState } from "react";
import { Grid, Avatar, LinearProgress, Fade, Button } from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { connect } from "react-redux";
import { getMyTickets } from "_models/redux/my-tickets/action";
import moment from "moment";
import noIssues from "assets/images/tickets/no-issues.png";
import { useNavigate, useLocation } from "react-router-dom";
import { cleanTicketsAction } from "_models/redux/my-tickets/clean-actions";

const TicketsDatagrid = (props) => {
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const { state } = useLocation();

    const { getMyTickets, ticketsReducer, cleanTicketsAction } = props || {};

    useEffect(() => {
        props.getMyTickets();
        setLoader(true);
        return () => {
            cleanTicketsAction();
        };
    }, []);

    useEffect(() => {
        generateRows();
    }, [ticketsReducer]);

    const generateRows = () => {
        const { loaded, method, payload, state, status } = ticketsReducer || {};
        if (loaded) {
            const { pagination, tickets } = payload || {};
            setLoader(false);

            setRows(tickets);
        }
    };

    const handleNavigate = (params) => {
        if (
            typeof params?.target?.id != "undefined" &&
            typeof params?.target?.name != "undefined"
        ) {
            navigate("/tickets/" + params.target.id, {
                state: { directNumber: params.target.name },
            });
        }
    };

    const TicketsList = () => {
        return (
            <div className={"list-box"}>
                {rows?.map((element, index) => {
                    const lastActionTime = moment(element.last_action_time)
                        .startOf("hour")
                        .fromNow();

                    const created = moment(element.created)
                        .startOf("hour")
                        .fromNow();

                    return (
                        <Grid container className="row" key={index}>
                            <Grid
                                item
                                xs={4}
                                className={"section-one"}
                                display={"flex"}
                                alignItems={"center"}
                            >
                                <p>
                                    <ConfirmationNumberIcon className="svg" />
                                    <span>
                                        <p>{element.id}</p>
                                    </span>
                                </p>
                                <Button
                                    id={element.id}
                                    name={element.direct_number}
                                    onClick={handleNavigate}
                                >
                                    {element.title}
                                </Button>
                            </Grid>
                            <Grid item xs={8} className={"section-two"}>
                                <Grid
                                    container
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"flex-end"}
                                >
                                    <Grid item>
                                        <p className="phone-number">
                                            <LocalPhoneIcon className="svg" />
                                            {element.direct_number}
                                        </p>
                                    </Grid>
                                    <Grid item className={"dates"}>
                                        Creado: {created}
                                    </Grid>
                                    <Grid item className={"dates"}>
                                        Última actividad: {lastActionTime}
                                    </Grid>

                                    <Grid item className="dropdown-status">
                                        {element.general_status_label}
                                    </Grid>
                                    <Grid item>
                                        <Avatar className={"avatar"}>
                                            {element.assigned_to_user_name[0]}
                                            {
                                                element
                                                    .assigned_to_user_last_name[0]
                                            }
                                            {
                                                element
                                                    .assigned_to_user_mothers_last_name[0]
                                            }
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </div>
        );
    };

    const ContainerTable = () => {
        return (
            <Grid className={"list-box"}>
                {rows?.length > 0 ? <TicketsList /> : <EmptyContainer />}
            </Grid>
        );
    };

    const EmptyContainer = () => {
        return (
            <Grid container className="no-issues">
                <Grid item xs={12}>
                    <img
                        title="No Issues"
                        alt="No Issues"
                        src={noIssues}
                        className={"no-issues-img"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h2>¡Felicidades!</h2>
                    <p>Todas tus incidencias han sido resueltas.</p>
                </Grid>
            </Grid>
        );
    };

    const LinearProgressContainer = () => {
        return (
            <Fade in={loader}>
                <LinearProgress
                    sx={{
                        backgroundColor: "white",
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: "#673ab7",
                        },
                    }}
                />
            </Fade>
        );
    };

    return (
        <div>{loader ? <LinearProgressContainer /> : <ContainerTable />}</div>
    );
};

const mapStateToProps = (state) => ({ ticketsReducer: state.ticketsReducer });

const mapDispatchToProps = { getMyTickets, cleanTicketsAction };

export default connect(mapStateToProps, mapDispatchToProps)(TicketsDatagrid);
