import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import CustomPaginationTable from "./data/pagination";

const DataTable = ({
    rows,
    columns,
    onRowClick,
    page,
    pageSize,
    rowCount,
    onPageChange,
    handleChangePageSize,
}) => {
    // Función para manejar el clic en una fila
    const handleGridRowClick = (params) => {
        if (onRowClick) {
            onRowClick(params.id);
        }
    };
    return (
        <Grid style={{ height: 400, width: "100%" }}>
            <DataGrid
                autoWidth
                autoHeight
                pagination
                paginationMode="server"
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={rowCount}
                page={page}
                onPageChange={(newPage) => onPageChange(newPage)}
                onRowClick={handleGridRowClick}
                // disableColumnMenu
                // disableSelectionOnClick
                components={{
                    Pagination: () => (
                        <CustomPaginationTable
                            page={page}
                            pageCount={page}
                            pageSize={pageSize}
                            rowCount={rowCount}
                            onPageChange={onPageChange}
                            onPageSizeChange={handleChangePageSize}
                        />
                    ),
                }}
            />
        </Grid>
    );
};

export default DataTable;
