import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { connect } from "react-redux";
import { getMyTickets } from "_models/redux/my-tickets/action";
import CustomerProfileHeaderUser from "../userTickets1/widgets/customer-profile-header-user";
import ClientsTicketsDatagrid from "./widgets/datagrid";
import RowButton from "./row-button";
import MyStopwatch from "../detailTickets3/widgets/title-header";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";

const ClientTicketsPage = (props) => {
    useEffect(() => {}, []);

    return (
        <div>
            <CustomerProfileHeaderUser />
            <RowButton />
            <ClientsTicketsDatagrid />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = { getMyTickets };

export default connect(mapStateToProps, mapDispatchToProps)(ClientTicketsPage);
