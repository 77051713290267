import React, { useState, useEffect, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Collapse, Grid, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { getEvents } from "_models/redux/events/action";
import { getNir } from "_models/redux/request-get-nir/action";
import { postChangeNir } from "_models/redux/post-change-nir/action";
import SendIcon from "@mui/icons-material/Send";
import { cleanChangeNir } from "_models/redux/post-change-nir/clean";
import { LoadersContext } from "_models/context/app";
import { cleanNirs } from "_models/redux/request-get-nir/clean";
import { useParams } from "react-router-dom";

const ChangeNir = (props) => {
    let params = useParams();
    const { ticketId, changeNirReducer } = props;
    const { loading, changeNir, error } = changeNirReducer || "";
    let detailMessage = props.changeNirReducer?.error?.response?.data?.detail;

    const [modalOpen, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [stateValue, setStateValue] = useState();
    const [stateName, setStateName] = useState();
    const [nirValueSelect, setNirValueSelect] = useState();
    const [nirNumber, setNirNumber] = useState();
    const [disableSelectNir, setdisableSelectNir] = useState(true);
    const [nirsMap, setNirs] = useState([]);
    const [loaderButton, setLoaderButton] = useState(false);
    const [alert, setAlert] = useState(false);

    const [errorMssge, setErrorMssge] = useState("");

    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {}, []);

    useEffect(() => {
        const { nir } = props.nir || {
            nirs: undefined,
            state_id: undefined,
        };
        const nirs = nir.nirs || undefined;

        if (Array.isArray(nirs)) {
            setNirs(nirs);
        }
    }, [props.nir]);

    useEffect(() => {
        let statusMessage = props.changeNirReducer?.changeNir?.status;
        let detailMessageError =
            props.changeNirReducer?.error?.response?.data?.detail;

        if (statusMessage == 200) {
            setSeverityAlert("success");
            setErrorMssge("El cambió de NIR se realizó satisfactoriamente");
            setAlert(true);
            setLoaderButton(false);
            props.getEvents({ ticket_id: params.ticketId });
        } else {
            setSeverityAlert("warning");
            setErrorMssge(detailMessageError);
            setAlert(true);
            setLoaderButton(false);
        }
    }, [props.changeNirReducer]);

    const sepomex = {
        states: [
            {
                id: 1,
                estado: "Aguascalientes",
            },
            {
                id: 2,
                estado: "Baja California",
            },
            {
                id: 3,
                estado: "Baja California Sur",
            },
            {
                id: 4,
                estado: "Campeche",
            },
            {
                id: 5,
                estado: "Coahuila de Zaragoza",
            },
            {
                id: 6,
                estado: "Colima",
            },
            {
                id: 7,
                estado: "Chiapas",
            },
            {
                id: 8,
                estado: "Chihuahua",
            },
            {
                id: 9,
                estado: "Ciudad de México",
            },
            {
                id: 10,
                estado: "Durango",
            },
            {
                id: 11,
                estado: "Guanajuato",
            },
            {
                id: 12,
                estado: "Guerrero",
            },
            {
                id: 13,
                estado: "Hidalgo",
            },
            {
                id: 14,
                estado: "Jalisco",
            },
            {
                id: 15,
                estado: "México",
            },
            {
                id: 16,
                estado: "Michoacán de Ocampo",
            },
            {
                id: 17,
                estado: "Morelos",
            },
            {
                id: 18,
                estado: "Nayarit",
            },
            {
                id: 19,
                estado: "Nuevo León",
            },
            {
                id: 20,
                estado: "Oaxaca",
            },
            {
                id: 21,
                estado: "Puebla",
            },
            {
                id: 22,
                estado: "Querétaro",
            },
            {
                id: 23,
                estado: "Quintana Roo",
            },
            {
                id: 24,
                estado: "San Luis Potosí",
            },
            {
                id: 25,
                estado: "Sinaloa",
            },
            {
                id: 26,
                estado: "Sonora",
            },
            {
                id: 27,
                estado: "Tabasco",
            },
            {
                id: 28,
                estado: "Tamaulipas",
            },
            {
                id: 29,
                estado: "Tlaxcala",
            },
            {
                id: 30,
                estado: "Veracruz de Ignacio de la Llave",
            },
            {
                id: 31,
                estado: "Yucatán",
            },
            {
                id: 32,
                estado: "Zacatecas",
            },
        ],
    };

    const {
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (e) => {
        const post = {
            ticket_id: ticketId,
            nirNumber: nirNumber,
        };

        props.postChangeNir(post);
        setLoaderButton(true);
    };

    const handleClickButton = () => {
        setIsLoading(true);
        setOpenModal(true);
    };

    useEffect(() => {
        if (modalOpen) {
            setIsLoading(false);
            props.cleanChangeNir();
            props.cleanNirs();
        }
    }, [modalOpen]);

    const handleCloseClean = () => {
        setStateValue();
        setNirValueSelect();
        setNirNumber();
        setdisableSelectNir(true);
    };

    const handleSelectState = (event) => {
        const stateValue = event.target.value || "";
        setStateValue(stateValue);
        setStateName(stateValue);
        setdisableSelectNir(false);
        props.getNir({ state_id: stateValue });
    };

    const handleSelectNIR = (event) => {
        setNirValueSelect(event?.target?.value);

        let nirNumberValue = event?.target?.value?.split("_");
        nirNumberValue =
            typeof nirNumberValue[1] != "undefined" ? nirNumberValue[1] : "";
        setNirNumber(nirNumberValue || "");
    };

    useEffect(() => {}, [nirNumber]);

    useEffect(() => {}, [nirValueSelect]);

    useEffect(() => {
        return () => {
            props.cleanNirs();
            props.cleanChangeNir();
            setStateValue();
            setNirValueSelect();
            setNirNumber();
            setdisableSelectNir(true);
            setErrorMssge();
        };
    }, [modalOpen]);

    const closePopup = () => {
        setOpenModal(false);
        setIsLoading(false);
    };

    const SelectState = () => {
        return (
            // <FormControl fullWidth>
            // </FormControl>
            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    select
                    fullWidth
                    // {...register("state", register.state)}
                    id="select-stateValue"
                    label="Estado"
                    onChange={handleSelectState}
                    value={stateValue}
                >
                    {sepomex.states.map((element) => {
                        return (
                            <MenuItem key={element.id} value={element.id}>
                                {element.estado}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
        );
    };

    const SelectNir = () => {
        return (
            // <FormControl>
            // </FormControl>
            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    select
                    fullWidth
                    // {...register("nir_number", register.nirNumber)}
                    disabled={disableSelectNir}
                    id="select-nir"
                    label="Municipio"
                    onChange={handleSelectNIR}
                    //+ "_" + nirNumber
                    value={nirValueSelect}
                >
                    {nirsMap.map((element) => {
                        return (
                            <MenuItem
                                key={element.id}
                                value={element.id + "_" + element.nir}
                            >
                                {element.municipality_name}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
        );
    };

    const ModalChangeNir = () => {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container className="modalBackground">
                    <Grid container xs={4} className="modalContainer">
                        <Grid container>
                            <Grid item xs={6} className="title">
                                <h3>Cambio de NIR</h3>
                            </Grid>
                            <Grid item xs={6} className="titleCloseBtn">
                                <button onClick={closePopup}>
                                    <CloseIcon />
                                </button>
                            </Grid>{" "}
                        </Grid>
                        <Grid container xs={12} rowSpacing={2}>
                            <SelectState />
                            <SelectNir />
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            direction={"column"}
                            rowSpacing={0}
                            textAlign={"start"}
                        >
                            <Grid item>
                                <h4>NUEVO NIR : {nirNumber}</h4>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Collapse in={alert}>
                                {error != undefined || changeNir != 0 ? (
                                    <Alert
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={closePopup}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                        severity={severityAlert}
                                    >
                                        {errorMssge}
                                    </Alert>
                                ) : null}
                            </Collapse>
                        </Grid>
                        <Grid item xs={12} className="footer">
                            <button onClick={closePopup} id="cancelBtn">
                                Cancelar
                            </button>
                            {/* <button type="submit">Cambiar</button> */}
                            <LoadingButton
                                size="small"
                                onClick={onSubmit}
                                endIcon={<SendIcon />}
                                loading={loaderButton}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Cambiar
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    };

    return (
        <Grid item>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickButton}
                variant="outlined"
                className="button"
            >
                <h4>Cambio de NIR</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalChangeNir
                    modalOpen={modalOpen}
                    setOpenModal={setOpenModal}
                />
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    nir: state.nir,
    changeNirReducer: state.changeNir,
});
const mapDispatchToProps = {
    getEvents,
    getNir,
    postChangeNir,
    cleanChangeNir,
    cleanNirs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeNir);
