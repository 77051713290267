import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { Grid } from "@mui/material";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

const TicketIncidenceInfo = (props) => {
    return (
        <Grid container className="ticket-id-status">
            <Grid item xs={6}>
                <h2>
                    <ConfirmationNumberIcon className="svg" /> {"D1234"}
                </h2>
            </Grid>
            <Grid item xs={6}>
                {/* <TicketStatus ticketId={ticketStatus} /> */}
            </Grid>
        </Grid>
    );
};

export default TicketIncidenceInfo;
