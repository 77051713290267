import React from "react";
import { Grid } from "@mui/material";

import UserTicketsPage from "./tickets-users/page";

const TicketsPage = () => {
    return <UserTicketsPage />;
};

export default TicketsPage;
