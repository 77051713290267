import React from "react";
import { Grid } from "@mui/material";

const PaymentDetails = ({ response }) => {
    return (
        <>
            <Grid item xs={6}>
                <div>
                    <span>Tarjeta</span>
                </div>
                <div>
                    <span>Número de autorización</span>
                </div>
                <div>
                    <span>Ejecutivo</span>
                </div>
            </Grid>
            <Grid item xs={6} className="device-right-data">
                <div>{response?.purchase?.payment?.maked_card}</div>
                <div>{response?.purchase?.payment?.authorization_number} </div>
                <div>
                    {response?.purchase?.order_details?.backoffice_user_name}
                </div>
            </Grid>
        </>
    );
};

export default PaymentDetails;
