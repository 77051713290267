import React, { useState, useEffect, useContext } from "react";
import { postBarringAction } from "_models/redux/resuqest-barring-action/action";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import ModalCustomActions from "modules/support/my-tickets/flow/detailTickets3/widgets/actions-tickets/modal-custom";
import { cleanBarringAction } from "_models/redux/resuqest-barring-action/clean-action";

import { LoadersContext } from "_models/context/app";

export const ActionBarring = (props) => {
    const { actionPostBarringAction, ticketId, imeiNumber } = props || {};

    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    const handleClickBarring = () => {
        setModalOpen(true);
    };
    useEffect(() => {
        const { postUpdateBarringObj } = actionPostBarringAction || {};
        if (actionPostBarringAction.postUpdateBarringObj.length != 0) {
            if (actionPostBarringAction.loading == false) {
                setErrorMssge(postUpdateBarringObj.message);
                setSeverityAlert("error");
                setAlert(true);
                setIsLoading(false);
            } else {
                setSeverityAlert("success");
                setErrorMssge("Barring satisfactorio");
                setAlert(true);
                setIsLoading(false);
            }
        }
    }, [actionPostBarringAction]);

    const handleSubmit = () => {
        setIsLoading(true);
        props.postBarringAction(ticketId);
    };

    const clearFunction = () => {
        setAlert(false);
    };

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickBarring}
                variant="outlined"
                className="button"
            >
                <h4>Suspender tráfico saliente (Barring)</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalCustomActions
                    modalOpen={modalOpen}
                    setOpenModal={setModalOpen}
                    titleModal="Actualizar Barring"
                    subTitleModal="¿Desea actualizar Barring?"
                    actionRequest={handleSubmit}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    alert={alert}
                    setAlert={setAlert}
                    errorMssge={errorMssge}
                    setErrorMssge={setErrorMssge}
                    severityAlert={severityAlert}
                    setSeverityAlert={setSeverityAlert}
                    clearFunction={clearFunction}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    actionPostBarringAction: state.actionPostBarringAction,
});
const mapDispatchToProps = {
    postBarringAction,
    cleanBarringAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionBarring);
