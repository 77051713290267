import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
    Grid,
    Alert,
    Collapse,
    MenuItem,
    TextField,
    InputLabel,
    IconButton,
    FormControl,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import { postPortabilityAction } from "_models/redux/portability/action";
import { cleanPortabilityAction } from "_models/redux/portability/clean-action";

import { LoadersContext } from "_models/context/app";

const ActionPortability = (props) => {
    const {
        ticketId,
        postPortabilityAction,
        cleanPortabilityAction,
        portability,
    } = props;

    const { loaded, payload, status, state } = portability || {};

    const [modalOpen, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loaderButton, setLoaderButton] = useState(false);

    const [response, setResponse] = useState("");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    const [nipValue, setNipValue] = useState();
    const [phoneValue, setPhoneValue] = useState();

    const {
        reset,
        value,
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (e) => {
        const post = {
            nip: parseInt(e?.nipInput),
            number_to_port: e?.phoneInput,
        };

        if (typeof postPortabilityAction == "function") {
            postPortabilityAction(ticketId, post);
            setLoaderButton(true);
        }
    };

    const handleClickButton = () => {
        setIsLoading(true);
        setOpenModal(true);
    };

    const closePopup = () => {
        cleanPortabilityAction();
        setOpenModal(false);
        setIsLoading(false);
        setResponse("");
        reset();
    };

    useEffect(() => {
        cleanPortabilityAction();
        return () => {
            cleanPortabilityAction();
        };
    }, []);

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const { message, additional_details, context, detail, title, type } =
            payload || {};

        if (loaderButton && loaded) {
            setResponse(
                <Grid container className={"m-t-15 response-container"}>
                    <Grid item xs={12} className={"response-title"}>
                        {title || ""}
                    </Grid>
                    <Grid item xs={12} className={"response-text"}>
                        {detail || message || ""}
                    </Grid>
                </Grid>
            );
            setLoaderButton(false);
        }
    }, [portability]);
    useEffect(() => {
        if (modalOpen) {
            setIsLoading(false);
        }
    }, [modalOpen]);

    const registerOptions = {
        nipInput: {
            required: "NIP es requerido",
            // minLength: {
            //     value: 4,
            //     message: "mínimo 4 caracteres",
            // },
            maxLength: {
                value: 4,
                message: "máximo 4 caracteres",
            },
        },
        phoneInput: {
            required: "Número de celular es requerido",
            // minLength: {
            //     value: 10,
            //     message: "mínimo 10 caracteres",
            // },
            maxLength: {
                value: 10,
                message: "máximo 10 caracteres",
            },
        },
    };

    const NipInput = () => {
        return (
            <Grid container>
                <Grid item xs={12} className="body p-tb-15">
                    <TextField
                        variant="standard"
                        fullWidth
                        type="number"
                        className="form-control"
                        placeholder="NIP"
                        label="NIP"
                        name="nipInput"
                        value={nipValue}
                        // onChange={onNipChange}
                        {...register("nipInput", registerOptions?.nipInput)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <span>
                        {errors?.nipInput && (
                            <p className="required-incidence m-0">
                                {" "}
                                {errors?.nipInput.message}
                            </p>
                        )}
                    </span>
                </Grid>
            </Grid>
        );
    };

    const PhoneInput = () => {
        return (
            <Grid container>
                <Grid item xs={12} className="body p-tb-15">
                    <TextField
                        variant="standard"
                        fullWidth
                        type="number"
                        className="form-control"
                        placeholder="Número de celular anterior"
                        label="Número de celular anterior"
                        name="phoneInput"
                        value={phoneValue}
                        // onChange={onPhoneChange}
                        {...register("phoneInput", registerOptions?.phoneInput)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <span>
                        {errors?.phoneInput && (
                            <p className="required-incidence m-0">
                                {" "}
                                {errors?.phoneInput.message}
                            </p>
                        )}
                    </span>
                </Grid>
            </Grid>
        );
    };

    const ModalChangeNir = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container className="modalBackground">
                            <Grid item xs={4} className="modalContainer">
                                {state == "success" && loaded ? (
                                    <Grid
                                        container
                                        className={
                                            "required-incidence no-before-icon m-0 j-c-c"
                                        }
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className={"d-flex j-c-c"}
                                        >
                                            {
                                                "La portabilidad del número está en proceso"
                                            }
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className={"d-flex j-c-c"}
                                        >
                                            <button
                                                className={"m-0"}
                                                onClick={closePopup}
                                                id="cancelBtn"
                                            >
                                                Aceptar
                                            </button>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container>
                                        <Grid item xs={6} className="title">
                                            <h3>Portabilidad</h3>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            className="titleCloseBtn"
                                        >
                                            <button onClick={closePopup}>
                                                <CloseIcon />
                                            </button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <NipInput />
                                            <PhoneInput />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            className={
                                                "required-incidence no-before-icon m-0"
                                            }
                                        >
                                            {response || ""}
                                        </Grid>

                                        <Grid item xs={12} className="footer">
                                            <button
                                                onClick={closePopup}
                                                id="cancelBtn"
                                            >
                                                Cancelar
                                            </button>
                                            <LoadingButton
                                                size="small"
                                                // onClick={onSubmit}
                                                // endIcon={<SendIcon />}
                                                loading={loaderButton}
                                                loadingPosition="end"
                                                variant="contained"
                                                className={"no-uppercase"}
                                                type="submit"
                                            >
                                                Aceptar
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid item>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickButton}
                variant="outlined"
                className="button"
            >
                <h4>Portabilidad</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalChangeNir
                    modalOpen={modalOpen}
                    setOpenModal={setOpenModal}
                />
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    portability: state?.portability,
});
const mapDispatchToProps = {
    postPortabilityAction,
    cleanPortabilityAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionPortability);
