import React from "react";
import { Dialog, DialogContent, TextField, Button, Grid } from "@mui/material";
import SelectField from "./SelectField";

const CustomDialogContent = ({
    open,
    description,
    register,
    registerOptions,
    handleDescriptionChange,
    inputChange,
    newOne,
    states,
    stateShrink,
    newTwo,
    municipalities,
    municipalityShrink,
    newThree,
    neighborhoods,
    neighborhoodShrink,
    zipcodeShrink,
    addressShrink,
    contactPhoneShrink,
    handleCopyToClipboard,
    handleCloseDialog,
    errors,
}) => {
    return (
        <Dialog open={open}>
            <DialogContent>
                <TextField
                    {...register("description", registerOptions?.description)}
                    label="Descripción"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={description}
                    onChange={handleDescriptionChange}
                />
                {open && (
                    <>
                        <SelectField
                            register={register}
                            registerOptions={registerOptions}
                            value={newOne}
                            onChange={inputChange}
                            options={states}
                            label="Estado"
                            name="stateInput"
                            shrink={stateShrink}
                            errors={errors}
                        />
                        <SelectField
                            register={register}
                            registerOptions={registerOptions}
                            value={newTwo}
                            onChange={inputChange}
                            options={municipalities}
                            label="Municipio"
                            name="municipalityInput"
                            shrink={municipalityShrink}
                            errors={errors}
                        />
                        <SelectField
                            register={register}
                            registerOptions={registerOptions}
                            value={newThree}
                            onChange={inputChange}
                            options={neighborhoods}
                            label="Colonia"
                            name="neighborhoodInput"
                            shrink={neighborhoodShrink}
                            errors={errors}
                        />
                        <Grid marginBottom={2}>
                            <TextField
                                {...register(
                                    "zipcodeInput",
                                    registerOptions?.zipcodeInput
                                )}
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Código postal"
                                placeholder="Código postal"
                                id="zipcodeInput"
                                name="zipcodeInput"
                                onChange={(e) => inputChange(e)}
                                InputLabelProps={{
                                    shrink: zipcodeShrink,
                                }}
                                className="m-tb-20 form-control data-input"
                            />
                        </Grid>
                        <Grid marginBottom={2}>
                            <TextField
                                {...register(
                                    "addressInput",
                                    registerOptions?.addressInput
                                )}
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Calle y número int/ext"
                                placeholder="Calle y número int/ext"
                                id="addressInput"
                                name="addressInput"
                                onChange={(e) => inputChange(e)}
                                InputLabelProps={{
                                    shrink: addressShrink,
                                }}
                                className="m-tb-20 form-control data-input"
                            />

                            {errors?.addressInput && (
                                <Grid
                                    item
                                    xs={12}
                                    className={"lead-form-input-error"}
                                >
                                    <span>
                                        <p className="required-incidence m-0">
                                            {errors?.addressInput.message}
                                        </p>
                                    </span>
                                </Grid>
                            )}
                        </Grid>

                        <TextField
                            fullWidth
                            type="text"
                            variant="outlined"
                            label="Número de contacto"
                            placeholder="Número de contacto"
                            id="contactPhoneInput"
                            name="contactPhoneInput"
                            {...register(
                                "contactPhoneInput",
                                registerOptions?.contactPhoneInput
                            )}
                            onChange={(e) => inputChange(e)}
                            InputLabelProps={{
                                shrink: contactPhoneShrink,
                            }}
                            className="m-tb-20 form-control data-input"
                        />

                        {errors?.contactForServiceInput && (
                            <Grid
                                item
                                xs={12}
                                className={"lead-form-input-error"}
                            >
                                <span>
                                    <p className="required-incidence m-0">
                                        {errors?.contactForServiceInput.message}
                                    </p>
                                </span>
                            </Grid>
                        )}
                    </>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCopyToClipboard}
                >
                    Cambiar
                </Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>{" "}
            </DialogContent>
        </Dialog>
    );
};

export default CustomDialogContent;
