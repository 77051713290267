import React, { useEffect } from "react";
import { connect } from "react-redux";
import TicketHeader from "./widgets/header";
import TicketsDatagrid from "./widgets/datagrid";

const UserMyTicketsPage = (props) => {
    useEffect(() => {}, []);

    return (
        <div>
            <TicketHeader />
            <TicketsDatagrid />
        </div>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserMyTicketsPage);
