import React, { useEffect, useState } from "react";
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Fade,
    LinearProgress,
} from "@mui/material";
import { connect } from "react-redux";
import { getMyTickets } from "_models/redux/my-tickets/action";
import moment from "moment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { getCustomerProfile } from "_models/redux/customer-profile/action";

import { getUsers } from "_models/redux/get-user-list/action";
import { changeUser } from "_models/redux/change-user/action";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import { getEvents } from "_models/redux/events/action";
import { useParams } from "react-router-dom";
import { checkPermission } from "_controllers/helpers/checkPermission";

const LineEvents = (props) => {
    const [modalOpenSim, setModalOpenSim] = useState(false);
    const [eventsList, setEventsList] = useState([]);
    const [imeiNumber, setImeiNumber] = useState("");
    const [valueAgent, setValueAgent] = useState();
    const [agentList, setAgentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketId, setTicketId] = useState();
    const [directNumber, setDirectNumber] = useState("");
    const [loader, setLoader] = useState(false);
    const [statusProfile, setStatusProfile] = useState("");

    let params = useParams();

    const handleChange = (event) => {
        setValueAgent(event.target.value);
        props.changeUser({
            ticketId: ticketId,
            id: event.target.value,
        });
    };

    useEffect(() => {
        setTicketId(params.ticketId);
        props.getUsers();
    }, []);

    useEffect(() => {
        const { events } = props.events || { events: undefined };

        if (Array.isArray(events.events)) {
            setEventsList(events.events);
            setLoader(false);
        }
    }, [props.events]);

    useEffect(() => {
        const { users } = props.userList.response || {
            users: undefined,
        };
        if (typeof users != "undefined") {
            setAgentList(users);
        }
    }, [props.userList]);

    useEffect(() => {
        const { customerProfile } = props.customerProfile || {
            customerProfile: undefined,
        };

        if (typeof customerProfile != "undefined") {
            setDirectNumber(customerProfile.profile.direct_number);
            setImeiNumber(customerProfile.profile.imei);
            setStatusProfile(customerProfile.status.subStatus);
        }
    }, [props.customerProfile]);

    useEffect(() => {
        props.getEvents({ ticket_id: params.ticketId });
        setLoader(true);
    }, [props.changeUser_]);

    useEffect(() => {
        const response = props.ticketById.response || undefined;
        if (typeof response != "undefined") {
            setIsLoading(false);
            setValueAgent(response.assigned_to_user_id);
            // setDirectNumber(response.direct_number);
            // // setTicketById(response.id);
            // setName(response.created_by_user_name);
            // setLastName(response.created_by_user_last_name);
            // setMothersLastName(response.created_by_user_mothers_last_name);
        }
    }, [props.ticketById]);

    const DropdownAgentSelect = () => {
        return (
            <FormControl variant="standard" fullWidth size="small">
                <Select
                    variant="standard"
                    defaultValue={2}
                    fullWidth
                    labelId="agent-select"
                    id="agent-select"
                    value={valueAgent}
                    onChange={handleChange}
                >
                    {agentList.map((agent) => {
                        return (
                            <MenuItem key={agent.id} value={agent.id || -1}>
                                {agent.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    };

    return (
        <Grid container direction="row">
            <Grid item xs={12} className="ticket-detail-header">
                <h4> Eventos de la línea</h4>
            </Grid>
            <Grid
                item
                xs={12}
                className="events-box"
                textAlign={"center"}
                paddingLeft={2}
            >
                <Fade in={loader}>
                    <LinearProgress
                        sx={{
                            backgroundColor: "white",
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: "#673ab7",
                            },
                        }}
                    />
                </Fade>
                <List
                    sx={{
                        width: "100%",
                        minHeight: "300px",
                        bgcolor: "background.paper",
                        position: "relative",
                        "& ul": { padding: 0 },
                    }}
                    subheader={<li />}
                >
                    {eventsList.map((event, index) => {
                        const dateTime = moment
                            .unix(event.created)
                            .format("DD/MM/YYYY, h:mm:ss a");

                        return (
                            <ListItem
                                sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                    padding: 0,
                                }}
                                key={index}
                            >
                                <ListItemText
                                    sx={{
                                        padding: 2,
                                    }}
                                    primary={event.data.message}
                                    secondary={dateTime}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    tickets: state.tickets,
    events: state.events,
    customerProfile: state.customerProfile,
    userList: state.userList,
    changeUser_: state.changeUser_,
    ticketById: state.ticketById,
});

const mapDispatchToProps = {
    getMyTickets,
    getEvents,
    getCustomerProfile,
    getUsers,
    changeUser,
    getTicketById,
};

export default connect(mapStateToProps, mapDispatchToProps)(LineEvents);
