import { Grid } from "@mui/material";

//Component - Layout subnavigation routes (child):
import SubnavigationRoutes from "router/routes/subnavigation";

//Hook - Layout content:
import { useLayoutContent } from "../hooks/useLayoutContent";

const LayoutContent = () => {
    //Properties from hook:
    const { layoutContentWrapper } = useLayoutContent() || {};

    return (
        <Grid
            container
            className="content no-prod-content"
            sx={{ flexGrow: 1, p: 3 }}
            // sx={layoutContentWrapper || {}}
        >
            <Grid item xs={12}>
                <SubnavigationRoutes />
            </Grid>
        </Grid>
    );
};

export default LayoutContent;
