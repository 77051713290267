import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import BarChartIcon from "@mui/icons-material/BarChart";

import TableHelperComponent from "modules/dashoboard/kpis/manager/sales/monthly/components/table-helper-old";

const SalesMonthlyChart = (props) => {
    const { parentProperties } = props || {};
    const { form, redux } = parentProperties || {};
    const {} = form || {};

    const { getSalesMonthly } = redux || {};

    const [headers, setHeaders] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [data, setData] = useState([]);

    const [noDataForGrid, setNoDataForGrid] = useState(false);

    const generateColumns = () => {
        let columnsArray = [
            {
                field: "type_esim",
                headerName: "eSIM",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "type_sim",
                headerName: "SIM",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "total",
                headerName: "Total",
                width: 160,
                headerAlign: "center",
                align: "right",
            },
        ];

        setColumns(columnsArray);
    };

    useEffect(() => {
        generateColumns();
        return () => {};
    }, []);

    useEffect(() => {
        const { payload } = getSalesMonthly || {};
        const { result } = payload || {};

        let headersArray = [];
        let rowsArray = [];

        result?.map((element) => {
            const { date, product_name } = element || {};

            const compare = headersArray?.includes(date);

            if (!compare) {
                headersArray.push(date);
            }

            const compareNew = rowsArray?.includes(product_name);
            if (!compareNew) {
                rowsArray.push(product_name);
            }
        });

        setHeaders(headersArray);
        setRows(rowsArray);

        let records = result?.reduce((obj, item) => {
            const { date, product_name, sim_type, quantity } = item || {};

            headersArray?.map((hItem) => {
                let hName = hItem?.toString().replaceAll(" ", "_");

                rowsArray?.map((rItem) => {
                    let rName = rItem?.toString().replaceAll(" ", "_");

                    if (!obj[hName + "=" + rName]) {
                        if (
                            hName === date &&
                            rName === product_name?.replaceAll(" ", "_")
                        ) {
                            if (sim_type === "E-SIM") {
                                obj[hName + "=" + rName] = {
                                    esim: quantity,
                                    sim: 0,
                                    total: quantity,
                                    hName: hName,
                                    rName: rName,
                                };
                            } else {
                                obj[hName + "=" + rName] = {
                                    esim: 0,
                                    sim: quantity,
                                    total: quantity,
                                    hName: hName,
                                    rName: rName,
                                };
                            }
                        }
                    } else {
                        if (
                            hName === date &&
                            rName === product_name?.replaceAll(" ", "_")
                        ) {
                            const { esim, sim, total } =
                                obj[hName + "=" + rName];

                            if (sim_type === "E-SIM") {
                                obj[hName + "=" + rName] = {
                                    esim: esim + quantity,
                                    sim: sim,
                                    total: total + quantity,
                                    hName: hName,
                                    rName: rName,
                                };
                            } else {
                                obj[hName + "=" + rName] = {
                                    esim: esim,
                                    sim: sim + quantity,
                                    total: total + quantity,
                                    hName: hName,
                                    rName: rName,
                                };
                            }
                        }
                    }
                });

                return;
            });

            return obj;
        }, {});

        setData(records);

        if (result?.length == 0) {
            setNoDataForGrid(true);
        } else {
            setNoDataForGrid(false);
        }
    }, [getSalesMonthly]);

    return (
        <Grid container spacing={5}>
            {getSalesMonthly.loaded === true && noDataForGrid === false ? (
                <Grid item xs={12}>
                    <Grid container mt={7}>
                        <Grid xs={12} md={12}>
                            <TableHelperComponent
                                raws={data}
                                headers={headers}
                                columns={columns}
                                rows={rows}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : noDataForGrid ? (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    height={345}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>No contiene datos, por favor intenta otra fecha</p>
                    </div>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    height={363}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>Selecciona una fecha para búsqueda de datos </p>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default SalesMonthlyChart;
