import {
    SUPPLEMENTARY_SERVICE,
    SUPPLEMENTARY_SERVICE_ERROR,
    SUPPLEMENTARY_SERVICE_CLEAN,
    SUPPLEMENTARY_SERVICE_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    supplementaryService: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SUPPLEMENTARY_SERVICE:
        case SUPPLEMENTARY_SERVICE_CLEAN:
            return {
                ...state,
                supplementaryService: action.payload,
                loading: true,
            };

        case SUPPLEMENTARY_SERVICE_ERROR:
        case SUPPLEMENTARY_SERVICE_CLEAN_ERROR:
        default:
            return state;
    }
}
