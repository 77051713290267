import React, { useEffect } from "react";
import { connect } from "react-redux";

import { useTableHook } from "./hook";

import { getLinksAction } from "_models/redux/links/action";
import { cleanLinksAction } from "_models/redux/links/clean-action";

import LinksTable from "./table";

const LinksContent = (props) => {
    useEffect(() => {}, []);

    const { columns, rows, generateColumns, generateRows } = useTableHook({
        props,
    });

    return <LinksTable columns={columns} rows={rows} />;
};

/******/

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    linksObj: state?.links,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getLinksAction,
    cleanLinksAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(LinksContent);
