import { useContext } from "react";
import { Grid } from "@mui/material";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { DailySalesContext } from "../data/context";

import { useDailySalesManagerTableHook } from "../hooks/table-hook";

const DailySalesManagerTable = () => {
    const dailySalesContext = useContext(DailySalesContext);
    const { states } = dailySalesContext || {};
    const { headers, columns, rows, data } = states;

    const lclStr = "es-MX";
    const cls = data ? Object?.keys(data).map((key) => key) : [];
    const rws = data ? Object.keys(data).map((key) => data[key]) : [];

    const {
        StyledTableCell,
        CustomStyledTableCell1,
        CustomStyledTableCell2,
        StyledTableRow,
    } = useDailySalesManagerTableHook();

    return (
        <Grid container>
            <Grid xs={12} md={12}>
                <TableContainer className="reportsTableCLS" component={Paper}>
                    <Table
                        stickyHeader
                        sx={{ minWidth: 250 }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow key={0}>
                                <CustomStyledTableCell2
                                    key={0}
                                    align="center"
                                    colSpan={1}
                                ></CustomStyledTableCell2>
                                {headers?.map((header, headerIndex) => (
                                    <>
                                        <CustomStyledTableCell2
                                            key={headerIndex}
                                            align="center"
                                            colSpan={3}
                                        >
                                            {header}
                                        </CustomStyledTableCell2>
                                    </>
                                ))}
                            </TableRow>
                            <TableRow key={1}>
                                <StyledTableCell
                                    key={0}
                                    align={"center"}
                                    style={{ top: "57px" }}
                                >
                                    {"Product"}
                                </StyledTableCell>
                                {headers?.map((header, headerIndex) => (
                                    <>
                                        {columns?.map((column, columnIndex) => {
                                            return column.headerName ===
                                                "Total" ? (
                                                <StyledTableCell
                                                    key={columnIndex}
                                                    align={column.headerAlign}
                                                    style={{ top: "57px" }}
                                                >
                                                    {column.headerName}
                                                </StyledTableCell>
                                            ) : (
                                                <StyledTableCell
                                                    key={columnIndex}
                                                    align={column.headerAlign}
                                                    style={{ top: "57px" }}
                                                >
                                                    {column.headerName}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row, rowIndex) => (
                                <StyledTableRow
                                    key={rowIndex}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <CustomStyledTableCell1
                                        component="th"
                                        scope="row"
                                        key={1 + rowIndex}
                                    >
                                        {row}
                                    </CustomStyledTableCell1>

                                    {headers?.map((header, headerIndex) => (
                                        <>
                                            {columns?.map(
                                                (column, columnIndex) => {
                                                    return column.headerName ===
                                                        "eSIM" ? (
                                                        <StyledTableCell
                                                            key={
                                                                1 +
                                                                (rowIndex *
                                                                    columnIndex +
                                                                    columnIndex)
                                                            }
                                                            align={
                                                                column?.align
                                                            }
                                                        >
                                                            {!isNaN(
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.esim
                                                                )
                                                            ) ? (
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.esim
                                                                ).toLocaleString(
                                                                    lclStr
                                                                )
                                                            ) : (
                                                                <span className="zero-number">
                                                                    0
                                                                </span>
                                                            )}
                                                        </StyledTableCell>
                                                    ) : column.headerName ===
                                                      "SIM" ? (
                                                        <StyledTableCell
                                                            key={
                                                                1 +
                                                                rowIndex +
                                                                columnIndex
                                                            }
                                                            align={
                                                                column?.align
                                                            }
                                                        >
                                                            {!isNaN(
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.sim
                                                                )
                                                            ) ? (
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.sim
                                                                ).toLocaleString(
                                                                    lclStr
                                                                )
                                                            ) : (
                                                                <span className="zero-number">
                                                                    0
                                                                </span>
                                                            )}
                                                        </StyledTableCell>
                                                    ) : (
                                                        <CustomStyledTableCell1
                                                            key={
                                                                1 +
                                                                rowIndex +
                                                                columnIndex
                                                            }
                                                            align={
                                                                column?.align
                                                            }
                                                        >
                                                            {!isNaN(
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.total
                                                                )
                                                            ) ? (
                                                                parseFloat(
                                                                    rws?.filter(
                                                                        (
                                                                            entry
                                                                        ) =>
                                                                            entry.hName ===
                                                                                header &&
                                                                            entry.rName ===
                                                                                row.replaceAll(
                                                                                    " ",
                                                                                    "_"
                                                                                )
                                                                    )[0]?.total
                                                                ).toLocaleString(
                                                                    lclStr
                                                                )
                                                            ) : (
                                                                <span className="zero-number">
                                                                    0
                                                                </span>
                                                            )}
                                                        </CustomStyledTableCell1>
                                                    );
                                                }
                                            )}
                                        </>
                                    ))}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default DailySalesManagerTable;
