import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { Grid, Container, Button } from "@mui/material";
import LeadsByCampaingFilters from "modules/dashoboard/filters/filters";
import SalesMonthlyChart from "./chart";
import { LoadersContext } from "_models/context/app";
import { getSalesMonthlyAction } from "_models/redux/get-sales-monthly/action";
import { cleanGetSalesMonthlyAction } from "_models/redux/get-sales-monthly/clean-action";

const SalesMonthlyPage = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    const {
        leadsByCampaing,
        cleanLeadsByCampaingAction,
        getOrdersByProductAction,
        getSalesMonthlyAction,
        getSalesMonthly,
        getChannelObj,
        getCampaingObj,
        landingListObj,
        cleanOrdersByProductAction,
        ordersByProduct,
        getChannelAction,
        getLandingList,
        getCampaingAction,
    } = props || {};

    useEffect(() => {}, []);

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [filters, setFilters] = useState({});

    const [fromTime, setFromTime] = useState(
        moment().startOf("month").format()
    );
    const [toTime, setToTime] = useState(moment().format());

    const [channelId, setChannelId] = useState(0);
    const [campaignId, setCampaignId] = useState(0);
    const [landingId, setLandingId] = useState(0);

    const [displayDatepickers, setDisplayDatepickers] = useState(
        "displayDatepickers hidden"
    );

    useEffect(() => {
        const { loaded, method, payload, state, status } =
            getSalesMonthly || {};

        const { rows } = payload || {};

        if (loaded) {
            setIsScreenLoading(false);
        }
    }, [getSalesMonthly]);

    useEffect(() => {
        // Format the dates in "YYYY-MM-DD" format
        const formattedFromTime = moment(fromTime).format("YYYY-MM-DD");
        const formattedToTime = moment(toTime).format("YYYY-MM-DD");

        // Set the formatted dates back into the state
        setFromTime(formattedFromTime);
        setToTime(formattedToTime);

        // getLoadedMethods();
    }, [fromTime, toTime]);

    const getLoadedMethods = () => {
        getSalesMonthlyAction({
            from_time: fromTime,
            to_time: toTime,
        });
    };

    const loadData = (e) => {
        setIsScreenLoading(true);
        getLoadedMethods();
    };

    const parentProperties = {
        form: {
            watch,
            reset,
            control,
            setValue,
            getValues,
            register,
            handleSubmit,
            setError,
            errors,
        },
        redux: {
            leadsByCampaing,
            cleanLeadsByCampaingAction,
            getOrdersByProductAction,
            getSalesMonthlyAction,
            getSalesMonthly,
            getChannelObj,
            getCampaingObj,
            landingListObj,
            cleanOrdersByProductAction,
            ordersByProduct,
        },
        states: {
            filters,
            setFilters,
            fromTime,
            setFromTime,
            toTime,
            setToTime,
            channelId,
            setChannelId,
            campaignId,
            landingId,
            setLandingId,
            setCampaignId,
            setLandingId,

            setIsScreenLoading,

            displayDatepickers,
            setDisplayDatepickers,
        },
        methods: {
            loadData,
        },
    };

    return (
        <Container className="kpi-container">
            <Grid container>
                <Grid item xs={12}>
                    <LeadsByCampaingFilters
                        parentProperties={parentProperties}
                    />

                    {/* <LeadsByCampaingCustomFilters
                        parentProperties={parentProperties}
                    /> */}
                </Grid>

                <Grid item xs={12}>
                    <Grid container className="filters-button-container">
                        <Button
                            type="button"
                            variant="contained"
                            onClick={loadData}
                        >
                            Cargar
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <SalesMonthlyChart parentProperties={parentProperties} />
                </Grid>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    getSalesMonthly: state.getSalesMonthly,
});

const mapDispatchToProps = {
    getSalesMonthlyAction,
    cleanGetSalesMonthlyAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesMonthlyPage);
