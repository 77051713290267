import { useContext } from "react";

import { Drawer } from "../styles/layoutStyles";

import { LayoutContext } from "../data/layoutContext";

export const useLayoutMenu = () => {
    const { layoutVariables, methods } = useContext(LayoutContext) || {};

    const { open } = layoutVariables || {};

    return { Drawer, open };
};
