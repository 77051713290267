import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Avatar, Button, Fade, Grid, LinearProgress } from "@mui/material";
import Popover from "@mui/material/Popover";
import { changeUser } from "_models/redux/change-user/action";
import { getUsers } from "_models/redux/get-user-list/action";
import { getTickets } from "_models/redux/tickets/action";
import { cleanGeneralTicketsAction } from "_models/redux/tickets/clean-actions";
import noIssues from "assets/images/tickets/no-issues.png";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";

const TicketsDatagrid = (props) => {
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(true);
    const [showPopover, setShowPopover] = useState(null);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [ticketId, setTicketId] = useState(0);
    const [dataUsersList, setDataUserList] = useState(null);
    const navigate = useNavigate();
    const { state } = useLocation();

    const {
        getTickets,
        generalTickets,
        cleanGeneralTicketsAction,
        getUsers,
        userList,
        changeUser,
        changeUser_,
    } = props || {};

    const { response } = userList || {};

    const open = Boolean(showPopover);

    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        getTickets();
        getUsers();
        setLoader(true);
        return () => {
            cleanGeneralTicketsAction();
        };
    }, []);

    useEffect(() => {
        generateRows();
    }, [generalTickets]);

    useEffect(() => {
        if (userList) {
            setDataUserList(response.users);
        }
    }, [userList]);

    useEffect(() => {
        if (changeUser_.loading) {
            getTickets();
            handleClose();
        }
    }, [changeUser_]);

    const generateRows = () => {
        const { loaded, method, payload, state, status } = generalTickets || {};

        if (loaded) {
            const { pagination, tickets } = payload || {};
            setLoader(false);

            setRows(tickets);
        }
    };

    const handleNavigate = (params) => {
        if (
            typeof params?.target?.id != "undefined" &&
            typeof params?.target?.name != "undefined"
        ) {
            navigate("/tickets/" + params.target.id, {
                state: { directNumber: params.target.name },
            });
        }
    };

    const handleChangeUser = (params) => {
        changeUser({ ticketId: ticketId, user_id: params.id });
    };

    const handleClick = (params) => {
        setShowPopover(params.events.currentTarget);
        setPositionX(params.events.clientX + 30);
        setPositionY(params.events.clientY + 10);
        setTicketId(params.ticketId);
    };

    const handleClose = () => {
        setShowPopover(null);
    };

    const ContainerTable = () => {
        if (rows.length > 0)
            return (
                <div className={"list-box"}>
                    {rows.map((element, index) => {
                        const lastActionTime = moment(element.last_action_time)
                            .startOf("hour")
                            .fromNow();
                        // const lastActionTime = element.last_action_time;
                        const created = moment(element.created)
                            .startOf("hour")
                            .fromNow();
                        return (
                            <Grid container className="row" key={index}>
                                <Grid
                                    item
                                    xs={4}
                                    className={"section-one"}
                                    display={"flex"}
                                    alignItems={"center"}
                                >
                                    <p>
                                        <ConfirmationNumberIcon className="svg" />
                                        <span>{element.id}</span>
                                    </p>
                                    <Button
                                        id={element.id}
                                        name={element.direct_number}
                                        onClick={handleNavigate}
                                    >
                                        {element.title}
                                    </Button>
                                </Grid>
                                <Grid item xs={8} className={"section-two"}>
                                    <Grid
                                        container
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"flex-end"}
                                    >
                                        <Grid item>
                                            <p className="phone-number">
                                                <LocalPhoneIcon className="svg" />
                                                {element.direct_number}
                                            </p>
                                        </Grid>
                                        <Grid item className={"dates"}>
                                            Creado: {created}
                                        </Grid>
                                        <Grid item className={"dates"}>
                                            Última actividad: {lastActionTime}
                                        </Grid>

                                        <Grid item className="dropdown-status">
                                            {element.general_status_label}
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                id={index}
                                                onClick={(e) =>
                                                    handleClick({
                                                        ticketId: element.id,
                                                        events: e,
                                                    })
                                                }
                                            >
                                                <Avatar className={"avatar"}>
                                                    {
                                                        element
                                                            .assigned_to_user_name[0]
                                                    }
                                                    {
                                                        element
                                                            .assigned_to_user_last_name[0]
                                                    }
                                                    {
                                                        element
                                                            .assigned_to_user_mothers_last_name[0]
                                                    }
                                                </Avatar>
                                            </Button>
                                            <Popover
                                                id={index}
                                                open={open}
                                                onClose={handleClose}
                                                anchorReference="anchorPosition"
                                                anchorPosition={{
                                                    top: positionY,
                                                    left: positionX,
                                                }}
                                            >
                                                <h3>Asignar ticket a :</h3>

                                                <List
                                                    sx={{
                                                        width: "100%",
                                                        maxWidth: 500,
                                                        bgcolor:
                                                            "background.paper",
                                                        position: "relative",
                                                        overflow: "scroll",
                                                        maxHeight: 260,
                                                        "& ul": { padding: 0 },
                                                    }}
                                                >
                                                    {dataUsersList?.map(
                                                        (elem, index) => {
                                                            return (
                                                                <ListItem
                                                                    key={index}
                                                                >
                                                                    <Button
                                                                        onClick={() =>
                                                                            handleChangeUser(
                                                                                {
                                                                                    ticketId:
                                                                                        element.id,
                                                                                    id: elem.id,
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        <ListItemAvatar>
                                                                            <Avatar
                                                                                className={
                                                                                    "avatar"
                                                                                }
                                                                            >
                                                                                {
                                                                                    elem
                                                                                        .name[0]
                                                                                }
                                                                                {
                                                                                    elem
                                                                                        .last_name[0]
                                                                                }
                                                                                {elem.mother_last_name ===
                                                                                undefined
                                                                                    ? ""
                                                                                    : elem.mother_last_name}
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                elem.name +
                                                                                " " +
                                                                                elem.last_name +
                                                                                " " +
                                                                                (elem.mother_last_name ===
                                                                                undefined
                                                                                    ? ""
                                                                                    : elem.mother_last_name)
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </ListItem>
                                                            );
                                                        }
                                                    )}
                                                </List>
                                            </Popover>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </div>
            );
        else {
            return <EmptyContainer />;
        }
    };

    const EmptyContainer = () => {
        return (
            <Grid container className="no-issues">
                <Grid item xs={12}>
                    <img
                        title="No Issues"
                        alt="No Issues"
                        src={noIssues}
                        className={"no-issues-img"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h2>¡Felicidades!</h2>
                    <p>Todas tus incidencias han sido resueltas.</p>
                </Grid>
            </Grid>
        );
    };

    const LinearProgressContainer = () => {
        return (
            <Fade in={loader}>
                <LinearProgress
                    sx={{
                        backgroundColor: "white",
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: "#673ab7",
                        },
                    }}
                />
            </Fade>
        );
    };

    return (
        <div>{loader ? <LinearProgressContainer /> : <ContainerTable />}</div>
    );
};

const mapStateToProps = (state) => ({
    ticketsReducer: state.ticketsReducer,
    generalTickets: state?.generalTickets,
    userList: state.userList,
    changeUser_: state.changeUser_,
});

const mapDispatchToProps = {
    getTickets,
    cleanGeneralTicketsAction,
    getUsers,
    changeUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsDatagrid);
