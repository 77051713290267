import {
    CREATE_INCIDENCE,
    CREATE_INCIDENCE_ERROR,
    CLEAN_CREATE_INCIDENCE,
    CLEAN_CREATE_INCIDENCE_ERROR,
} from "_models/redux/types";

const initialState = {
    createIncidenceObj: undefined,
    loading: false,
    createIncidenceStatus: undefined,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_INCIDENCE:
            return {
                ...state,
                createIncidenceObj: action.payload,
                createIncidenceStatus: action.status,
                loading: true,
            };

        case CREATE_INCIDENCE_ERROR:
            return {
                ...state,
                createIncidenceObj: action.payload,
                createIncidenceStatus: action.status,
                loading: false,
            };

        case CLEAN_CREATE_INCIDENCE:
        case CLEAN_CREATE_INCIDENCE_ERROR:
            return {
                ...state,
                createIncidenceObj: action.payload,
                createIncidenceStatus: action.status,
                loading: false,
            };

        default:
            return state;
    }
}
