import {
    INCIDENCE_CHANNEL,
    INCIDENCE_CHANNEL_ERROR,
} from "_models/redux/types";

const initialState = {
    incidenceChannelObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INCIDENCE_CHANNEL:
            return {
                ...state,
                incidenceChannelObj: action.payload,
                loading: true,
            };

        case INCIDENCE_CHANNEL_ERROR:
            return {
                ...state,
                incidenceChannelObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
