import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#e9e0fd",
        color: "#715d7d",
        fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const CustomStyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#f4ebff",
        color: "#715d7d",
        fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: "#f7f1ff",
        color: "#715d7d",
        fontWeight: "700",
        fontSize: 14,
    },
}));

const CustomStyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#dcd2f1",
        color: "#715d7d",
        fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: "#f4ebff",
        color: "#715d7d",
        fontWeight: "700",
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#faf6ff",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const TableHelperComponent = ({ headers, columns, rows, raws }) => {
    const lclStr = "es-MX";
    const cls = raws ? Object?.keys(raws).map((key) => key) : [];
    const rws = raws ? Object.keys(raws).map((key) => raws[key]) : [];

    return (
        <>
            <TableContainer className="reportsTableCLS" component={Paper}>
                <Table
                    stickyHeader
                    sx={{ minWidth: 250 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow key={0}>
                            <CustomStyledTableCell2
                                key={0}
                                align="center"
                                colSpan={1}
                            ></CustomStyledTableCell2>
                            {headers?.map((header, headerIndex) => (
                                <>
                                    <CustomStyledTableCell2
                                        key={headerIndex}
                                        align="center"
                                        colSpan={3}
                                    >
                                        {header}
                                    </CustomStyledTableCell2>
                                </>
                            ))}
                        </TableRow>
                        <TableRow key={1}>
                            <StyledTableCell
                                key={0}
                                align={"center"}
                                style={{ top: "57px" }}
                            >
                                {"Product"}
                            </StyledTableCell>
                            {headers?.map((header, headerIndex) => (
                                <>
                                    {columns?.map((column, columnIndex) => {
                                        return column.headerName === "Total" ? (
                                            <StyledTableCell
                                                key={columnIndex}
                                                align={column.headerAlign}
                                                style={{ top: "57px" }}
                                            >
                                                {column.headerName}
                                            </StyledTableCell>
                                        ) : (
                                            <StyledTableCell
                                                key={columnIndex}
                                                align={column.headerAlign}
                                                style={{ top: "57px" }}
                                            >
                                                {column.headerName}
                                            </StyledTableCell>
                                        );
                                    })}
                                </>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, rowIndex) => (
                            <StyledTableRow
                                key={rowIndex}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <CustomStyledTableCell1 key={1 + rowIndex}>
                                    {row}
                                </CustomStyledTableCell1>

                                {headers?.map((header, headerIndex) => (
                                    <>
                                        {columns?.map((column, columnIndex) => {
                                            return column.headerName ===
                                                "eSIM" ? (
                                                <StyledTableCell
                                                    key={
                                                        1 +
                                                        (rowIndex *
                                                            columnIndex +
                                                            columnIndex)
                                                    }
                                                    align={column?.align}
                                                >
                                                    {!isNaN(
                                                        parseFloat(
                                                            rws?.filter(
                                                                (entry) =>
                                                                    entry.hName ===
                                                                        header &&
                                                                    entry.rName ===
                                                                        row.replaceAll(
                                                                            " ",
                                                                            "_"
                                                                        )
                                                            )[0]?.esim
                                                        )
                                                    ) ? (
                                                        parseFloat(
                                                            rws?.filter(
                                                                (entry) =>
                                                                    entry.hName ===
                                                                        header &&
                                                                    entry.rName ===
                                                                        row.replaceAll(
                                                                            " ",
                                                                            "_"
                                                                        )
                                                            )[0]?.esim
                                                        ).toLocaleString(lclStr)
                                                    ) : (
                                                        <span className="zero-number">
                                                            0
                                                        </span>
                                                    )}
                                                </StyledTableCell>
                                            ) : column.headerName === "SIM" ? (
                                                <StyledTableCell
                                                    key={
                                                        1 +
                                                        rowIndex +
                                                        columnIndex
                                                    }
                                                    align={column?.align}
                                                >
                                                    {!isNaN(
                                                        parseFloat(
                                                            rws?.filter(
                                                                (entry) =>
                                                                    entry.hName ===
                                                                        header &&
                                                                    entry.rName ===
                                                                        row.replaceAll(
                                                                            " ",
                                                                            "_"
                                                                        )
                                                            )[0]?.sim
                                                        )
                                                    ) ? (
                                                        parseFloat(
                                                            rws?.filter(
                                                                (entry) =>
                                                                    entry.hName ===
                                                                        header &&
                                                                    entry.rName ===
                                                                        row.replaceAll(
                                                                            " ",
                                                                            "_"
                                                                        )
                                                            )[0]?.sim
                                                        ).toLocaleString(lclStr)
                                                    ) : (
                                                        <span className="zero-number">
                                                            0
                                                        </span>
                                                    )}
                                                </StyledTableCell>
                                            ) : (
                                                <CustomStyledTableCell1
                                                    key={
                                                        1 +
                                                        rowIndex +
                                                        columnIndex
                                                    }
                                                    align={column?.align}
                                                >
                                                    {!isNaN(
                                                        parseFloat(
                                                            rws?.filter(
                                                                (entry) =>
                                                                    entry.hName ===
                                                                        header &&
                                                                    entry.rName ===
                                                                        row.replaceAll(
                                                                            " ",
                                                                            "_"
                                                                        )
                                                            )[0]?.total
                                                        )
                                                    ) ? (
                                                        parseFloat(
                                                            rws?.filter(
                                                                (entry) =>
                                                                    entry.hName ===
                                                                        header &&
                                                                    entry.rName ===
                                                                        row.replaceAll(
                                                                            " ",
                                                                            "_"
                                                                        )
                                                            )[0]?.total
                                                        ).toLocaleString(lclStr)
                                                    ) : (
                                                        <span className="zero-number">
                                                            0
                                                        </span>
                                                    )}
                                                </CustomStyledTableCell1>
                                            );
                                        })}
                                    </>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default TableHelperComponent;
