import React, { useState, useEffect, useContext } from "react";

import { postUnbarringAction } from "_models/redux/request-unbarring-action/action";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import ModalCustomActions from "modules/support/my-tickets/flow/detailTickets3/widgets/actions-tickets/modal-custom";

import { LoadersContext } from "_models/context/app";

export const ActionUnbarring = (props) => {
    const { actionPostUnbarringAction, ticketId, imeiNumber } = props || {};

    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    const handleClickUnbarring = () => {
        setModalOpen(true);
    };
    useEffect(() => {
        const { postUpdateUnbarringObj } = actionPostUnbarringAction || {};

        if (actionPostUnbarringAction.postUpdateUnbarringObj.length != 0) {
            if (actionPostUnbarringAction.loading == false) {
                setErrorMssge(postUpdateUnbarringObj.message);
                setSeverityAlert("error");
                setAlert(true);
                setIsLoading(false);
            } else {
                setSeverityAlert("success");
                setErrorMssge("Unbarring satisfactorio");
                setAlert(true);
                setIsLoading(false);
            }
        }
    }, [actionPostUnbarringAction]);

    const handleSubmit = () => {
        setIsLoading(true);
        props.postUnbarringAction(ticketId);
    };
    const clearFunction = () => {
        setAlert(false);
    };

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickUnbarring}
                variant="outlined"
                className="button"
            >
                <h4>Reanudar tráfico saliente (Unbarring)</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalCustomActions
                    modalOpen={modalOpen}
                    setOpenModal={setModalOpen}
                    titleModal="Actualizar Barring"
                    subTitleModal="¿Desea actualizar Barring?"
                    actionRequest={handleSubmit}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    alert={alert}
                    setAlert={setAlert}
                    errorMssge={errorMssge}
                    setErrorMssge={setErrorMssge}
                    severityAlert={severityAlert}
                    setSeverityAlert={setSeverityAlert}
                    clearFunction={clearFunction}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    actionPostUnbarringAction: state.actionPostUnbarringAction,
});
const mapDispatchToProps = {
    postUnbarringAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionUnbarring);
