import React from "react";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import { Grid } from "@mui/material";

const OrderDetails = ({ response }) => {
    return (
        <>
            <Grid item xs={12}>
                <div>
                    <span>Número Dalefon: </span>{" "}
                    {response?.purchase?.order_details?.direct_number}
                </div>
                <div>
                    <span>Número de orden: </span>{" "}
                    {response?.purchase?.order_details?.order_number}
                </div>
                <div>
                    <span>Lead ID:</span>{" "}
                    {response?.purchase?.order_details?.lead_id}{" "}
                </div>
                <hr></hr>
                <div>
                    <p>
                        Se compró el{" "}
                        {convertToMexicoTime(
                            response?.purchase?.order_details?.order_created
                        )}{" "}
                    </p>
                </div>
            </Grid>
        </>
    );
};

export default OrderDetails;
