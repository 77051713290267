import React, { useState, useEffect, useContext } from "react";
import { SessionContext } from "_models/context/app";
import { getImei } from "_models/redux/request-imei/action";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import ModalDeviceInfo from "../modal-device-info";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { LoadersContext } from "_models/context/app";
import { listcommentsTikets } from "_models/redux/request-list-comments/action";

const ActionImei = (props) => {
    const { directNumber, ticketId } = props;

    const { getActionImei } = props || {};
    const [imei, createImei] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [modalOpenDeviceInfo, setModalOpenDeviceInfo] = useState(false);
    const [infoDevicePopup, setInfoDevicePopup] = useState(false);

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    let params = useParams();

    useEffect(() => {}, []);

    useEffect(() => {
        const imeiObj = getActionImei || undefined;
        if (imeiObj.imeiObj.length != 0 && infoDevicePopup) {
            setIsLoading(false);
            setModalOpenDeviceInfo(true);
        }
    }, [getActionImei]);

    const handleActionInfo = () => {
        setIsLoading(true);
        setInfoDevicePopup(true);
        props.getImei({ ticket_id: ticketId });
    };

    // const onSubmit = async (e) => {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    return (
        <form
        // onSubmit={onSubmit}
        // className={"" + formCls}
        >
            <div>
                <LoadingButton
                    fullWidth
                    property="textAlign"
                    size="small"
                    onClick={handleActionInfo}
                    variant="outlined"
                    className="button"
                >
                    <h4>dispositivo</h4>
                </LoadingButton>
                {modalOpenDeviceInfo && (
                    <ModalDeviceInfo
                        modalOpen={modalOpenDeviceInfo}
                        setOpenModal={setModalOpenDeviceInfo}
                    />
                )}
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    getActionImei: state.getActionImei,
});
const mapDispatchToProps = {
    listcommentsTikets,
    getImei,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionImei);
