import axiosTokenInstance from "services/api/backoffice";
import { LEAD_ASSIGNMENT, LEAD_ASSIGNMENT_ERROR } from "_models/redux/types";

export const setLeadAssignmentAction = (params) => async (dispatch) => {
    const { lead_id } = params || {};

    const dispatchObj = {
        method: "post",
        status: undefined,
        payload: undefined,
        type: undefined,
    };

    try {
        const response = await axiosTokenInstance.post(
            `/api/callcenter/leads/${lead_id}/assign`
        );

        const { data, status } = response || {};

        dispatchObj.type = LEAD_ASSIGNMENT;
        dispatchObj.status = status || {};
        dispatchObj.payload = data || {};

        dispatchObj.payload.id = lead_id;

        dispatch(dispatchObj);
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatchObj.type = LEAD_ASSIGNMENT_ERROR;
        dispatchObj.status = status || null;
        dispatchObj.payload = e || {};

        dispatch(dispatchObj);
    }
};
