import { useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { LeadsListContext } from "../context";

export default function CustomPagination() {
    const leadsListContext = useContext(LeadsListContext);

    const props = {
        page: 0,
        pageCount: 1,
        paginationState: {
            paginationModel: {
                page: 0,
                pages: 1,
                pageSize: 100,
                rowCount: 1000,
            },
        },
    };

    const {
        page,
        pageCount,
        paginationState,
        myLeadsListPage,
        datagridPageSize,
        changePage,
        changePageSize,
    } = leadsListContext || props;

    const { paginationModel } = paginationState || {};

    const { pageSize, rowCount, pages } = paginationModel || {};

    return (
        <Stack direction="row" spacing={2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                {datagridPageSize * page + 1} -
                {datagridPageSize * page + datagridPageSize > rowCount
                    ? rowCount
                    : datagridPageSize * page + datagridPageSize}{" "}
                of {rowCount}
            </Box>
            <>
                <Select
                    variant="standard"
                    size="small"
                    onChange={(event, value) =>
                        changePageSize(event.target.value)
                    }
                    value={datagridPageSize}
                    MenuProps={{
                        PaperProps: { sx: { maxHeight: 360 } },
                    }}
                >
                    <MenuItem id={1} key={50} value={50}>
                        {50}
                    </MenuItem>
                    <MenuItem id={2} key={75} value={75}>
                        {75}
                    </MenuItem>
                    <MenuItem id={3} key={100} value={100}>
                        {100}
                    </MenuItem>
                </Select>
            </>
            <Pagination
                count={pages}
                page={page + 1}
                onChange={(event, value) => changePage(value - 1)}
                boundaryCount={3}
                siblingCount={1}
                showFirstButton
                showLastButton
                color="secondary"
                variant="outlined"
                shape="rounded"
            />
        </Stack>
    );
}
