import { RESUME_LINE, RESUME_LINE_ERROR } from "_models/redux/types";

const initialState = {
    postResumeLineObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RESUME_LINE:
            return {
                ...state,
                postResumeLineObj: action.payload,
                loading: true,
            };

        case RESUME_LINE_ERROR:
            return {
                ...state,
                postResumeLineObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
