import { useContext } from "react";

import { Grid } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";

import DailySalesManagerContent from "./content-component";

import { DailySalesContext } from "../data/context";
import { useDailySalesManagerHook } from "../hooks/hook";

const DailySalesManagerComponent = (props) => {
    const {} = useDailySalesManagerHook();
    const dailySalesContext = useContext(DailySalesContext);
    const { redux, states } = dailySalesContext || {};
    const { getSalesDaily } = redux || {};
    const { noDataForGrid } = states;

    return (
        <Grid container spacing={5}>
            {getSalesDaily.loaded === true && noDataForGrid === false ? (
                <DailySalesManagerContent />
            ) : noDataForGrid ? (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    height={345}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>No contiene datos, por favor intenta otra fecha</p>
                    </div>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    height={363}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>Selecciona una fecha para búsqueda de datos</p>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default DailySalesManagerComponent;
