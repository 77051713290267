import React from "react";
import { Typography, Grid, Divider } from "@mui/material";
import { getSimTypeStyle, gridItemSX } from "../style/style";

export const ProductList = ({ products, totalAmount }) => {
    const formattedTotal = totalAmount.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
    });

    return (
        <>
            <Typography
                variant="h6"
                style={{
                    color: "#800080",
                    fontWeight: "bold",
                    fontFamily: "Arial, sans-serif",
                }}
            >
                PRODUCTOS
            </Typography>
            {products?.map((product, index) => (
                <Grid
                    container
                    key={index}
                    spacing={3}
                    sx={{ marginBottom: 2 }}
                    xs={12}
                >
                    <Grid item {...gridItemSX} xs={12} lg={4}>
                        <Typography variant="body1">
                            <strong>Nombre del Producto:</strong>{" "}
                            {product.product_name || ""}
                        </Typography>
                    </Grid>
                    <Grid item {...gridItemSX} xs={12} lg={4}>
                        <Typography variant="body1">
                            <strong>Tipo de SIM:</strong>{" "}
                            <span
                                style={getSimTypeStyle(product.sim_type_name)}
                            >
                                {product.sim_type_name || ""}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid item {...gridItemSX} xs={12} lg={4}>
                        <Typography variant="body1">
                            <strong>Cantidad:</strong>{" "}
                            {product.total_quantity || ""}
                        </Typography>
                    </Grid>
                </Grid>
            ))}
            <Divider sx={{ my: 2 }} />
            <Grid container justifyContent="flex-end">
                <Typography variant="h6">Total: {formattedTotal}</Typography>
            </Grid>
        </>
    );
};
