import React, { useState, useEffect, useContext } from "react";

import { postResumeLineAction } from "_models/redux/request-resume-line/action";
import { connect } from "react-redux";
import { getEvents } from "_models/redux/events/action";
import { LoadingButton } from "@mui/lab";
import ModalCustomActions from "modules/support/my-tickets/flow/detailTickets3/widgets/actions-tickets/modal-custom";
import { useParams } from "react-router-dom";
import { LoadersContext } from "_models/context/app";

const ResumeLine = (props) => {
    let params = useParams();
    const { postResumeLine, ticketId, directNumber } = props || {};
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    const handleClickApn = () => {
        setModalOpen(true);
    };

    useEffect(() => {
        const actionResumeLine = props.postResumeLineReducer || {};

        if (actionResumeLine.postResumeLineObj.length != 0) {
            if (actionResumeLine.loading == false) {
                setErrorMssge("Error");
                setSeverityAlert("error");
                setAlert(true);
                setIsLoading(false);
            } else {
                setSeverityAlert("success");
                setErrorMssge("Se reanudó la línea satisfactoriamente");
                setAlert(true);
                setIsLoading(false);
                props.getEvents({ ticket_id: params.ticketId });
            }
        }
    }, [props.postResumeLineReducer]);

    const handleResumeLine = () => {
        setIsLoading(true);
        props.postResumeLineAction(ticketId);
    };
    const clearFunction = () => {
        setAlert(false);
    };

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickApn}
                variant="outlined"
                className="button"
            >
                <h4>Reanudar Línea</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalCustomActions
                    modalOpen={modalOpen}
                    setOpenModal={setModalOpen}
                    titleModal="Reanudar la línea"
                    subTitleModal="¿Está seguro que desea reanudar la línea?"
                    actionRequest={handleResumeLine}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    alert={alert}
                    setAlert={setAlert}
                    errorMssge={errorMssge}
                    setErrorMssge={setErrorMssge}
                    severityAlert={severityAlert}
                    setSeverityAlert={setSeverityAlert}
                    clearFunction={clearFunction}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    postResumeLineReducer: state.resumeLineAction,
    events: state.events,
});
const mapDispatchToProps = {
    postResumeLineAction,
    getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResumeLine);
