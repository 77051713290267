import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import ColumnStackedChart from "./components/ColumnStackedChart";

const LeadsByCampaingCharts = (props) => {
    const { parentProperties } = props || {};
    const { redux } = parentProperties || {};
    const { campaignChannel } = redux || {};

    const [noData, setNoData] = useState(true);

    useEffect(() => {
        const { payload } = campaignChannel || {};
        setNoData(!payload || Object.keys(payload).length === 0);
    }, [campaignChannel]);

    if (!campaignChannel.loaded) {
        return null;
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <h3>
                    Leads por canal<span className="line"></span>
                </h3>
                <Grid container>
                    {noData ? (
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            flexDirection={"column"}
                            height={345}
                        >
                            <div className="kpi-empty">
                                <p>
                                    <BarChartIcon />
                                </p>
                                <p>
                                    No contiene datos, por favor inténtelo
                                    nuevamente.
                                </p>
                            </div>
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={12} mt={3}>
                            <ColumnStackedChart
                                data={campaignChannel.payload}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LeadsByCampaingCharts;
