import { useContext, useEffect } from "react";

import { GiftOrdersContext } from "../../data/giftOrdersContexts";

import { registerValidations } from "../../data/giftOrdersValidations";

export const useGiftOrdersHeaders = () => {
    // Obtener el contexto del módulo y sus propiedades
    const giftOrdersContext = useContext(GiftOrdersContext);

    // Desestructuración de las propiedades del contexto
    const { formProps, reduxProps, reducerProps, moduleMethods } =
        giftOrdersContext || {};

    // Desestructuración de las propiedades del formulario
    const { handleSubmit, register, errors, reset } = formProps || {};

    // Desestructuración de las propiedades del Redux
    const { getGiftOrdersCleanAction } = reduxProps || {};

    // Desestructuración de las propiedades del Reducer
    const { giftOrdersReducer, dispatchGiftOrdersReducer } = reducerProps || {};
    const { fromDateValue, toDateValue } = giftOrdersReducer || {};

    // HEADERS Filters Methods:

    // Función que se ejecuta al enviar el formulario de búsqueda
    const onSubmit = (e) => {
        dispatchGiftOrdersReducer({
            property: "searchClicked",
            value: true,
        });
    };

    // Función que se ejecuta al cambiar el valor del DatePicker (from or to / desde o hasta)
    const datePickerOnChange = (value, type) => {
        const datePicker = type === "from" ? "fromDateValue" : "toDateValue";

        dispatchGiftOrdersReducer({
            property: datePicker,
            value: value,
        });
    };

    const inputOnChange = (e) => {};

    // Función que limpia los valores de los datepickers
    const cleanDatePickers = (e) => {
        dispatchGiftOrdersReducer({
            property: "fromDateValue",
            value: null,
        });

        dispatchGiftOrdersReducer({
            property: "toDateValue",
            value: null,
        });
    };

    // Función que limpia los valores del formulario y el estado del redux
    const cleanAll = () => {
        cleanDatePickers();

        reset({
            phoneInput: "",
        });

        getGiftOrdersCleanAction();
    };

    // Propiedades / variables / estados y métodos / funciones que se usarán en los componentes
    return {
        // Formulario:
        handleSubmit,
        register,
        errors,

        // Métodos:
        onSubmit,
        cleanAll,
        cleanDatePickers,

        // Eventos:
        datePickerOnChange,
        inputOnChange,

        // Estados:
        fromDateValue,
        toDateValue,

        // Validaciones:
        registerValidations,
    };
};
