import React from "react";

import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

import { useShippingSearchHeader } from "./_hook";

import Modal from "../_datagrid/_widgets/_shipping";
import { useState } from "react";

const ShippingSearchHeaderComponent = (props) => {
    // const { setModalOpen } = useShippingSearchHeader();
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <Grid container className="page-header">
            <Grid item xs={12}>
                <Grid container direction={"row"}>
                    <Grid item xs={6} textAlign={"start"}>
                        <h3>Búsqueda de envíos</h3>
                    </Grid>
                    <Grid item xs={6} textAlign={"end"}>
                        <button
                            className="right openModalBtn"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            <SearchIcon className="svg" />
                            Buscar por número de orden
                        </button>

                        {modalOpen && (
                            <Modal
                                modalOpen={modalOpen}
                                setOpenModal={setModalOpen}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ShippingSearchHeaderComponent;
