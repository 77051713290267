import {
    INCIDENCE,
    INCIDENCE_ERROR,
    INCIDENCE_CLEAN,
    INCIDENCE_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    incidenceObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case INCIDENCE:
        case INCIDENCE_CLEAN:
            return {
                ...state,
                incidenceObj: action.payload,
                loading: true,
            };

        case INCIDENCE_ERROR:
        case INCIDENCE_CLEAN_ERROR:
            return {
                ...state,
                incidenceObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
