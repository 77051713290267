import React, { useEffect, useState, useContext } from "react";
import { Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LoadersContext } from "_models/context/app";
import CircularProgress from "@mui/material/CircularProgress";

const ModalGeneric = (props) => {
    const navigate = useNavigate();
    const {
        dropdownOptionsChannels,
        setDropdownOptionsChannels,
        CreateLead,
        selectChannel,
        setSelectChannel,
        createLeadObj,
        setModalOpen,
        cleanCreateLead,
        modalOpen,
        cleanChannelLead,
    } = props || {};

    const [directNumber, setDirectNumber] = useState("");

    const [isLoading, setloading] = useState(false);

    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        if (createLeadObj?.status >= 200 && createLeadObj?.status < 300) {
            navigate("/lead-detail/" + createLeadObj?.payload?.lead_id);
            setloading(false);
        }

        if (createLeadObj?.loaded) {
            setloading(false);
        }
    }, [createLeadObj]);

    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm();

    const registerRequired = {
        selectChannel: {
            required: { required: "Canal es requerido" },
        },
    };

    const onSubmit = (e) => {
        setloading(true);

        const postCreateLead = {
            source_id: 1,
            channel_id: selectChannel,
        };

        CreateLead(postCreateLead);
    };

    useEffect(() => {
        return () => {
            cleanCreateLead();
            cleanChannelLead();
        };
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="modalBackground">
                <Grid item xs={4} className="modalContainer">
                    <Grid container className="title">
                        <Grid item xs={6}>
                            <h3>Nuevo Ticket</h3>
                        </Grid>
                        <Grid item xs={6} className="titleCloseBtn">
                            <button
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <p>Selecciona un canal</p>
                            <TextField
                                variant="standard"
                                fullWidth
                                {...register(
                                    "selectChannel",
                                    registerRequired.selectChannel
                                )}
                                select
                                id="outlined-select-currency"
                                label="Canal"
                                onChange={(event) => {
                                    setSelectChannel(event.target.value);
                                }}
                                value={selectChannel}
                            >
                                {dropdownOptionsChannels?.map(
                                    (option, index) => {
                                        return (
                                            <MenuItem
                                                align="center"
                                                key={option?.id}
                                                value={option?.id || 0}
                                            >
                                                {option?.label}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </TextField>
                            <span>
                                {errors?.selectChannel && (
                                    <p className="required-incidence">
                                        {errors.selectChannel.message}
                                    </p>
                                )}
                            </span>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="footer">
                        <button
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            id="cancelBtn"
                        >
                            Cancelar
                        </button>

                        <button type="submit">Crear</button>
                    </Grid>

                    {isLoading ? (
                        <CircularProgress />
                    ) : createLeadObj?.state == "error" ? (
                        <span className="required-incidence">
                            {createLeadObj?.payload?.data?.detail}
                        </span>
                    ) : null}

                    {/* {
                        <span className="required-incidence">
                            {createLeadObj?.payload?.data?.detail}
                        </span>
                    }
                    <center> {isLoading && <h1>Cargando...</h1>}</center> */}
                </Grid>
            </Grid>
        </form>
    );
};

export default ModalGeneric;
