import React, { useEffect, useState, useContext } from "react";
import { Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { NavLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { cleanGetProfile } from "../../../../../../_models/redux/customer-profile/clean";
import { LoadersContext } from "_models/context/app";
import CircularProgress from "@mui/material/CircularProgress";

const Modal = (props) => {
    const { modalOpen, setOpenModal, customerProfileReducer } = props || {};
    const { customerProfile, customerProfileStatus } =
        customerProfileReducer || {};
    const { response } = customerProfile || {};

    const [directNumber, setDirectNumber] = useState("");

    const [isLoading, setloading] = useState(false);
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        if (customerProfileStatus == 200) {
            navigate("/incidenciacliente", {
                state: { directNumber: directNumber },
            });
        }
    }, [props.customerProfileReducer]);

    useEffect(() => {
        if (response?.status >= 300) {
            setloading(false);
        }
    }, [customerProfileReducer]);

    const navigate = useNavigate();

    const [validationError, setValidationError] = useState(false);
    const [textError, setTextError] = useState("");

    const onInputChange = ({ target }) => {
        const { value } = target;

        setDirectNumber(value.replace(/\s+/g, ""));
        setValidationError(false);
        setTextError("");
    };

    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm();

    const registerOptions = {
        directNumber: {
            required: "Número Dalefon es requerido",
            minLength: {
                value: 10,
                message: "mínimo 10 caracteres",
            },
            maxLength: {
                value: 10,
                message: "mínimo 10 caracteres",
            },
        },
    };

    const onSubmit = (e) => {
        setloading(true);
        props.getCustomerProfile({ directNumber: directNumber });
    };

    useEffect(() => {
        return () => {
            if (customerProfileStatus != 200) {
                props.cleanGetProfile();
            }
        };
    }, [modalOpen]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="modalBackground">
                <Grid item xs={4} className="modalContainer">
                    <Grid container className="title">
                        <Grid item xs={9}>
                            <h4>Buscar número telefónico</h4>
                        </Grid>
                        <Grid item xs={3} className="titleCloseBtn">
                            <button
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="body">
                        <TextField
                            variant="standard"
                            {...register(
                                "directNumber",
                                registerOptions.directNumber
                            )}
                            // error={validationError}
                            helperText={textError}
                            fullWidth
                            type="text"
                            className="form-control"
                            placeholder="Número Dalefon a 10 dígitos"
                            value={directNumber}
                            onChange={onInputChange}
                        />
                    </Grid>
                    <span>
                        {errors?.directNumber && (
                            <p className="required-incidence">
                                {errors.directNumber.message}
                                {response?.data?.detail}
                            </p>
                        )}
                    </span>
                    <center>
                        {" "}
                        {isLoading ? (
                            <CircularProgress />
                        ) : response?.status >= 300 ? (
                            <span className="required-incidence">
                                {response?.data?.detail}
                            </span>
                        ) : null}
                    </center>

                    <Grid item xs={12} className="footer">
                        <button
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            id="cancelBtn"
                        >
                            Cancelar
                        </button>

                        <LoadingButton
                            size="small"
                            type="submit"
                            isLoading={true}
                            // endIcon={<SendIcon />}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Buscar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = (state) => ({
    customerProfileReducer: state.customerProfile,
});
const mapDispatchToProps = {
    getCustomerProfile,
    cleanGetProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
