import CustomDialog from "widgets/component/CustomDialog.js";

import GiftOrdersErrorContent from "./GiftOrdersErrorContent";

import { useGiftOrdersError } from "modules/support-gift-orders/hooks/gift-orders-error/useGiftOrdersError";

const GiftOrdersError = () => {
    // Hook propio de la funcionalidad del manejo de errores,
    // destrucuturando los objetos que necesita la interfaz
    const { errorOpenModal, handleErrorCloseModal, buttons } =
        useGiftOrdersError();

    return (
        // Modal genérico que recibe los parámetros del error
        <CustomDialog
            title="Error"
            open={errorOpenModal}
            onClose={handleErrorCloseModal}
            content={<GiftOrdersErrorContent />}
            extraButtons={[{ label: "Cerrar", onClick: handleErrorCloseModal }]}
            showCloseButton={true}
            fullWidth={true}
            maxWidth={"md"}
        />
    );
};

export default GiftOrdersError;
