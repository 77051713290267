import { useEffect, useState } from "react";
import { getStaticStates } from "_models/data/states";
import { useForm } from "react-hook-form";
import { cleanSaleOrderEsimQr } from "_models/redux/esim-qr/clean-action";
import {
    Grid,
    Button,
    DialogContent,
    Dialog,
    DialogContentText,
    DialogActions,
} from "@mui/material";

import moment from "moment";
import "moment/locale/es";

export const DataTableDetailOrderController = ({
    saleOrderDetail,
    getEsimQr,
    saleOrderEsimQr,
    municipality,
    municipalityAction,
    neighborhoodAction,
    neighborhood,
    getSaleOrderDetailAction,
    cleanChangeSimToEsimAction,
    cleanChangeEsimToSimAction,
    changeSimToEsimAction,
    changeEsimToSimAction,
    changeSimToEsim,
    changeEsimToSim,
    simActivationAction,
    simActivation,
    response,
}) => {
    const { payload, state } = saleOrderDetail || {};
    const { client, device, order_details, payment, product } = payload || {};
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [description, setDescription] = useState("");
    const [stateShrink, setStateShrink] = useState(false);
    const [municipalityShrink, setMunicipalityShrink] = useState(false);
    const [neighborhoodShrink, setNeighborhoodShrink] = useState(false);
    const [newOne, setNewOne] = useState();
    const [newTwo, setNewTwo] = useState();
    const [newThree, setNewThree] = useState();
    const [addressShrink, setAddressShrink] = useState(false);
    const [zipcodeShrink, setZipcodeShrink] = useState(false);
    const [isCustomDialogOpen, setIsCustomDialogOpen] = useState(false);
    const [contactPhoneShrink, setContactPhoneShrink] = useState(false);
    const { states } = getStaticStates();
    const [dialogMessage, setDialogMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleRequest = (order) => {
        console.log("order", order);
        setLoading(true);
        simActivationAction(order);
    };

    useEffect(() => {
        if (simActivation?.payload?.response?.status === 200) {
            setLoading(false);
            setIsCustomDialogOpen(true);
            setDialogMessage("La operación se completó con éxito.");
            setIsDialogOpen(false);
        } else if (simActivation?.payload?.response?.status > 200) {
            setLoading(false);
            setIsCustomDialogOpen(true);
            setDialogMessage(
                <>
                    <p>{simActivation?.payload?.response?.data?.detail}</p>
                </>
            );
        }
    }, [simActivation]);

    const [simOrderId, setSimOrderId] = useState();

    useEffect(() => {
        if (
            changeSimToEsim?.payload?.message === "success" &&
            changeSimToEsim?.state === "success" &&
            changeSimToEsim?.status >= 200 &&
            changeSimToEsim?.status < 300
        ) {
            setIsCustomDialogOpen(true);
            setDialogMessage(
                "La operación se completó con éxito: Cambio de SIM a eSIM."
            );
            setIsDialogOpen(false);
        } else if (
            changeSimToEsim?.state === "error" &&
            (changeSimToEsim?.status >= 300 || changeSimToEsim?.status === null)
        ) {
            setIsCustomDialogOpen(true);
            setDialogMessage(
                <>
                    <p>
                        {changeSimToEsim?.payload?.response?.data?.detail
                            ? changeSimToEsim?.payload?.response?.data?.detail
                            : "Ha ocurrido un error."}
                    </p>
                    <p>
                        {changeSimToEsim?.payload?.message
                            ? "[" + changeSimToEsim?.payload?.message + "]"
                            : ""}
                    </p>
                </>
            );
        } else {
            setIsCustomDialogOpen(false);
            setDialogMessage("");
        }
    }, [changeSimToEsim]);

    useEffect(() => {
        if (!isCustomDialogOpen) {
            cleanChangeSimToEsimAction();
            cleanChangeEsimToSimAction();
        }
    }, [isCustomDialogOpen]);

    useEffect(() => {
        if (
            changeEsimToSim?.payload?.message === "success" &&
            changeEsimToSim?.state === "success" &&
            changeEsimToSim?.status >= 200 &&
            changeEsimToSim?.status < 300
        ) {
            setIsCustomDialogOpen(true);
            setDialogMessage(
                "La operación se completó con éxito: Cambio de eSIM a SIM."
            );
            setIsDialogOpen(false);
        } else if (
            changeEsimToSim?.state === "error" &&
            (changeEsimToSim?.status >= 300 || changeEsimToSim?.status === null)
        ) {
            setIsCustomDialogOpen(true);
            setDialogMessage(
                <>
                    <p>
                        {changeEsimToSim?.payload?.response?.data?.detail
                            ? changeEsimToSim?.payload?.response?.data?.detail
                            : "Ha ocurrido un error."}{" "}
                    </p>
                    <p>
                        {changeEsimToSim?.payload?.message
                            ? "[" + changeEsimToSim?.payload?.message + "]"
                            : ""}
                    </p>
                </>
            );
        } else {
            setIsCustomDialogOpen(false);
            setDialogMessage("");
        }
    }, [changeEsimToSim]);

    const registerOptions = {
        contactPhoneInput: {
            required: "El número de contacto es requerido",
        },
        addressInput: {
            required: "La dirección es requerida",
        },
        zipcodeInput: {
            required: "El código postal es requerido",
        },
        stateInput: {
            required: "El estado es requerido",
        },
        municipalityInput: {
            required: "El municipio es requerido",
        },
        neighborhoodInput: {
            required: "La colonia es requerida",
        },
    };

    const { municipality_id, neighborhood_id, state_id, address, zipcode } =
        payload || {};
    const { municipalities } = municipality?.payload || {};
    const { neighborhoods } = neighborhood?.payload || {};

    const {
        reset,
        value,
        setFocus,
        register,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleCopyToClipboard = (data) => {
        console.log(data);
        let payload = {};
        const formData = getValues();
        console.log(formData);
        console.log(payload);
        console.log("reponse", response?.purchase?.order_details?.sim_type_id);

        if (
            order_details?.sim_type_id === 2 ||
            response?.purchase?.order_details?.sim_type_id === 2
        ) {
            const {
                zipcodeInput,
                addressInput,
                contactPhoneInput,
                neighborhoodInput,
            } = formData;

            payload = {
                reason: description,
                shipping: {
                    zipcode: zipcodeInput,
                    neighborhood_id: neighborhoodInput,
                    address: addressInput,
                    contact_phone: contactPhoneInput,
                },
                sim_order_id:
                    order_details?.sim_order_id ||
                    response?.purchase?.order_details?.sim_order_id,
            };
            changeEsimToSimAction(payload);
        } else if (
            order_details?.sim_type_id === 1 ||
            response?.purchase?.order_details?.sim_type_id === 1
        ) {
            payload = {
                reason: description,
                sim_order_id:
                    order_details?.sim_order_id ||
                    response?.purchase?.order_details?.sim_order_id,
            };
            changeSimToEsimAction(payload);
        }
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const [copySuccess, setCopySuccess] = useState("");
    const [dateSale, setDateSale] = useState("");
    useEffect(() => {
        if (saleOrderDetail) {
            const date = order_details?.order_created?.split("T") || "";
            const dateFormat = date[0];
            const timeFormat = date[1]?.split(".")[0];
            const timeFormatDiff = date[1]?.split(".")[1]?.split("+")[1];

            let localTimeFormat = "";

            if (timeFormatDiff === "00:00") {
                localTimeFormat = moment(order_details?.order_created)
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss");
            }

            setDateSale(dateFormat + " " + localTimeFormat);
        }
    }, [saleOrderDetail]);

    const CustomDialog = ({ message, onClose, open }) => {
        return (
            <Dialog open={open}>
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const inputChange = (e) => {
        const inputValue = e?.target?.value;
        const inputName = e?.target?.name;

        if (inputValue == "") {
            switch (inputName) {
                case "zipcodeInput":
                    setZipcodeShrink(false);
                    break;
                case "contactPhoneInput":
                    setContactPhoneShrink(false);
                    break;

                default:
                    break;
            }
        } else {
            switch (inputName) {
                case "addressInput":
                    setAddressShrink(true);
                    break;
                case "zipcodeInput":
                    setZipcodeShrink(true);
                    break;
                case "contactPhoneInput":
                    setContactPhoneShrink(true);
                    break;
                case "stateInput":
                    setNewOne(inputValue);
                    setValue(inputName, inputValue);
                    setStateShrink(true);
                    municipalityAction({ state_id: inputValue });
                    neighborhoodAction({ municipality_id: inputValue });
                    break;
                case "municipalityInput":
                    setNewTwo(inputValue);
                    setValue(inputName, inputValue);
                    setMunicipalityShrink(true);
                    neighborhoodAction({ municipality_id: inputValue });
                    break;
                case "neighborhoodInput":
                    setNewThree(inputValue);
                    setValue(inputName, inputValue);
                    setNeighborhoodShrink(true);
                    // Encuentra el barrio seleccionado y actualiza el código postal
                    const selectedNeighborhood = neighborhoods.find(
                        (n) => n.id === Number(inputValue)
                    );
                    if (selectedNeighborhood && selectedNeighborhood.zipcode) {
                        setValue("zipcodeInput", selectedNeighborhood.zipcode);
                        setZipcodeShrink(true);
                    }
                    break;

                default:
                    break;
            }
        }
    };

    useEffect(() => {
        if (state != "error") {
            if (address) {
                setValue("addressInput", address);
                setAddressShrink(true);
            }
            if (zipcode) {
                setValue("zipcodeInput", zipcode);
                setZipcodeShrink(true);
            }

            if (state_id) {
                setNewOne(state_id);
                setValue("stateInput", state_id);
                setStateShrink(true);
            }
            if (municipality_id) {
                setNewTwo(municipality_id);
                setValue("municipalityInput", municipality_id);
                setMunicipalityShrink(true);
            }
            if (neighborhood_id) {
                setNewThree(neighborhood_id);
                setValue("neighborhoodInput", neighborhood_id);
                setNeighborhoodShrink(true);
            }
        }
    }, []);

    useEffect(() => {
        if (saleOrderEsimQr?.loaded) {
            const { payload } = saleOrderEsimQr || {};
            copyToClipBoard(payload?.esim_qr_url);
        }

        return () => {
            cleanSaleOrderEsimQr();
        };
    }, [saleOrderEsimQr]);

    const labelSimType = () => {
        switch (order_details?.sim_type_id) {
            case 1:
                return "SIM";

            case 2:
                return "eSIM";

            case 3:
                return "MiFi";

            case 4:
                return "IoT";
            default:
                return "SIM";
        }
    };

    const copyToClipBoard = async (props) => {
        try {
            await navigator.clipboard.writeText(props);
            setCopySuccess("¡Enlace copiado!");
        } catch (err) {
            setCopySuccess("No se ha copiado el enlace");
        }
    };

    useEffect(() => {
        setSimOrderId(order_details?.sim_order_id);
    }, [order_details]);

    return {
        saleOrderDetail,
        getEsimQr,
        saleOrderEsimQr,
        municipality,
        municipalityAction,
        neighborhoodAction,
        neighborhood,
        cleanChangeSimToEsimAction,
        cleanChangeEsimToSimAction,
        changeSimToEsimAction,
        changeEsimToSimAction,
        changeSimToEsim,
        changeEsimToSim,
        isDialogOpen,
        description,
        stateShrink,
        municipalityShrink,
        neighborhoodShrink,
        newOne,
        newTwo,
        newThree,
        addressShrink,
        zipcodeShrink,
        isCustomDialogOpen,
        contactPhoneShrink,
        states,
        copySuccess,
        dateSale,
        handleOpenDialog,
        handleCloseDialog,
        handleCopyToClipboard,
        handleDescriptionChange,
        CustomDialog,
        inputChange,
        labelSimType,
        copyToClipBoard,
        order_details,
        client,
        device,
        product,
        payment,
        register,
        registerOptions,
        municipalities,
        errors,
        neighborhoods,
        registerOptions,
        setIsCustomDialogOpen,
        dialogMessage,
        simOrderId,
        handleRequest,
        loading,
    };
};
