import axiosTokenInstance from "../../../services/api/backoffice";

import { CUSTOMER_PROFILE, CUSTOMER_PROFILE_ERROR } from "_models/redux/types";

// Action function
export const getCustomerProfile = (params) => async (dispatch) => {
    const { directNumber } = params || {
        directNumber: undefined,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/${directNumber}/profile`
        );

        const { data, status } = response || {};

        dispatch({
            type: CUSTOMER_PROFILE,
            payload: data || {},
            status: status,
        });
    } catch (e) {
        const { response } = e || {};
        const { status } = response || {};

        dispatch({
            type: CUSTOMER_PROFILE_ERROR,
            payload: e || {},
            status: status,
        });
    }
};
