import { connect } from "react-redux";
import {
    Grid,
    Button,
    DialogContent,
    Dialog,
    TextField,
    CircularProgress,
    Divider,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { getEsimQr } from "_models/redux/esim-qr/action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { cleanChangeSimToEsimAction } from "_models/redux/change-sim-esim/clean-action";
import { cleanChangeEsimToSimAction } from "_models/redux/change-esim-sim/clean-action";
import { changeSimToEsimAction } from "_models/redux/change-sim-esim/action";
import { changeEsimToSimAction } from "_models/redux/change-esim-sim/action";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { DataTableDetailOrderController } from "../../hook/DataTableDetailOrderController";
import { GridCloseIcon } from "@mui/x-data-grid";
import { simActivationAction } from "_models/redux/sim-activation/action";
import Box from "@mui/material/Box";
const DataTableDetailOrder = (props) => {
    const {
        saleOrderDetail,
        getEsimQr,
        saleOrderEsimQr,
        municipality,
        municipalityAction,
        neighborhoodAction,
        neighborhood,
        getSaleOrderDetailAction,
        cleanChangeSimToEsimAction,
        cleanChangeEsimToSimAction,
        changeSimToEsimAction,
        changeEsimToSimAction,
        changeSimToEsim,
        changeEsimToSim,
        simActivationAction,
        simActivation,
    } = props || {};
    const {
        isDialogOpen,
        description,
        stateShrink,
        municipalityShrink,
        neighborhoodShrink,
        newOne,
        newTwo,
        newThree,
        addressShrink,
        zipcodeShrink,
        isCustomDialogOpen,
        contactPhoneShrink,
        states,
        dialogMessage,
        copySuccess,
        dateSale,
        handleOpenDialog,
        handleCloseDialog,
        handleCopyToClipboard,
        handleDescriptionChange,
        CustomDialog,
        inputChange,
        labelSimType,
        order_details,
        client,
        device,
        product,
        payment,
        register,
        registerOptions,
        municipalities,
        errors,
        neighborhoods,
        setIsCustomDialogOpen,
        simOrderId,
        handleRequest,
        loading,
    } = DataTableDetailOrderController({
        saleOrderDetail,
        getEsimQr,
        saleOrderEsimQr,
        municipality,
        municipalityAction,
        neighborhoodAction,
        neighborhood,
        getSaleOrderDetailAction,
        cleanChangeSimToEsimAction,
        cleanChangeEsimToSimAction,
        changeSimToEsimAction,
        changeEsimToSimAction,
        changeSimToEsim,
        changeEsimToSim,
        simActivationAction,
        simActivation,
    });

    return (
        <Grid container spacing={5} className="device-info-container m-b-15">
            <Grid item xs={6} direction={"column"}>
                <h2>Orden de compra</h2>

                <Grid container className="device-info">
                    <Grid item xs={12}>
                        <div>
                            <span>Número Dalefon: </span>{" "}
                            {order_details?.direct_number}
                        </div>
                        <div>
                            <span>Número de orden: </span>{" "}
                            {order_details?.order_number}
                        </div>
                        <div>
                            <span>Lead ID:</span> {order_details?.lead_id}{" "}
                        </div>
                        <hr></hr>
                        <div>
                            <p>Se compró el {dateSale} </p>
                        </div>
                    </Grid>
                </Grid>

                <h2>Cliente</h2>

                <Grid container className="device-info">
                    <Grid item xs={12}>
                        <div>
                            <span>Nombre:</span> {client?.name}{" "}
                        </div>
                        <div>
                            <span>Teléfono:</span> {client?.phone}
                        </div>
                        <div>
                            <span>Correo:</span> {client?.email}{" "}
                        </div>
                        <div>
                            <span>Dirección:</span> {client?.address}{" "}
                        </div>
                        <hr></hr>
                        <div>
                            <span>Correo del ticket:</span>{" "}
                            {client?.ticket_email}
                        </div>
                    </Grid>
                </Grid>

                <h2>Equipo</h2>

                <Grid container className="device-info">
                    <Grid item xs={6}>
                        <div>
                            <span>Marca:</span> {device?.brand}{" "}
                        </div>
                        <div>
                            <span>Modelo:</span> {device?.model}{" "}
                        </div>
                        <div>
                            <span>IMEI:</span> {device?.imei}
                        </div>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        <Grid
                            container
                            xs={12}
                            className="type-label"
                            id={labelSimType()}
                        >
                            <div>{labelSimType()}</div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} direction={"column"}>
                <h2>Producto</h2>
                <Grid container className="device-info">
                    <Grid item xs={6}>
                        <div>
                            <span>Producto </span>
                        </div>
                        <div>
                            <span>Costo</span>{" "}
                        </div>
                        <div>
                            <span>Costo SIM/eSIM</span>{" "}
                        </div>
                        <div>
                            <span>Costo envío</span>{" "}
                        </div>
                        <div>
                            <span>Descuento</span>{" "}
                        </div>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        <div> {product?.name}</div>
                        <div> ${product?.prepaid_cost}</div>
                        <div> ${product?.sim_esim_cost}</div>
                        <div> ${product?.shipping_cost}</div>
                        <div> -${product?.prepaid_discount}</div>
                    </Grid>
                    {/* Divider before "TotalPa Amount" */}
                    <Divider orientation="horizontal" />

                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="space-between">
                            <div style={{ textAlign: "left" }}>
                                <p>Total </p>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="space-between">
                            <div
                                style={{
                                    textAlign: "right",
                                    marginLeft: "auto",
                                    width: "100px",
                                }}
                            >
                                <p>${product?.total_amount}</p>
                            </div>
                        </Box>
                    </Grid>
                </Grid>

                <h2>Forma de pago</h2>
                <Grid container className="device-info">
                    <Grid item xs={6}>
                        <div>
                            <span>Tarjeta</span>
                        </div>
                        <div>
                            <span>Número de autorización</span>
                        </div>
                        <div>
                            <span>Ejecutivo</span>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        <div>{payment?.maked_card}</div>
                        <div>{payment?.authorization_number} </div>
                        <div>{order_details?.backoffice_user_name}</div>
                    </Grid>
                </Grid>
                <h2></h2>
                <Grid
                    container
                    className={
                        order_details?.sim_type_id === 2
                            ? "device-info"
                            : "hidden-element"
                    }
                >
                    <Grid item xs={6}>
                        <span>
                            <div>URL del QR</div>
                        </span>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        <Button
                            variant="outlined"
                            startIcon={<ContentPasteIcon />}
                            className="copy-btn"
                            onClick={() =>
                                getEsimQr(order_details?.sim_order_id)
                            }
                        >
                            Copiar enlace
                        </Button>
                        <p className={"copy-success"}>{copySuccess}</p>
                    </Grid>
                </Grid>
                <h2></h2>

                <Grid
                    container
                    className={
                        order_details?.sim_type_id === 1
                            ? "device-info"
                            : "hidden-element"
                    }
                >
                    <Grid item xs={6}>
                        <span>
                            <div>Acción de cambio de SIM a eSIM</div>
                        </span>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        <button className="copy-btn" onClick={handleOpenDialog}>
                            Cambiar
                        </button>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className={
                        order_details?.sim_type_id === 2
                            ? "device-info"
                            : "hidden-element"
                    }
                >
                    <Grid item xs={6}>
                        <span>
                            <div>Acción de cambio de eSIM a SIM</div>
                        </span>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        <button className="copy-btn" onClick={handleOpenDialog}>
                            Cambiar
                        </button>
                    </Grid>
                </Grid>

                <Grid container className={"device-info"} marginTop={2}>
                    <Grid item xs={6}>
                        <span>
                            <div>Activación de SIM </div>
                        </span>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <button
                                className="copy-btn"
                                onClick={() =>
                                    handleRequest(order_details?.sim_order_id)
                                }
                            >
                                Activar
                            </button>
                        )}
                    </Grid>
                </Grid>
                {/* device-info 
                //TODO: agregar funcionalidad de reembolso
                */}

                <Grid container className="hidden-element">
                    <Grid item xs={6}>
                        <span>
                            <div>Reembolso</div>
                        </span>
                    </Grid>
                    <Grid item xs={6} className="device-right-data">
                        <span>
                            <p>-ButtonGenerar-</p>
                        </span>
                    </Grid>
                </Grid>
                {isDialogOpen && (
                    <Dialog open={isDialogOpen}>
                        <DialogContent>
                            <TextField
                                {...register(
                                    "description",
                                    registerOptions?.description
                                )}
                                label="Descripción"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                            {order_details?.sim_type_id === 2 && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        className={"lead-form-input-error"}
                                    >
                                        <span>
                                            {/* {errors?.zipcodeInput && (
                                                <p className="required-incidence m-0">
                                                    {" "}
                                                    {
                                                        errors?.zipcodeInput
                                                            .message
                                                    }
                                                </p>
                                            )} */}
                                        </span>
                                    </Grid>
                                    {/* Mostrar estos TextField solo si sim_type_id es 2 */}

                                    <TextField
                                        {...register(
                                            "stateInput",
                                            registerOptions?.stateInput
                                        )}
                                        select
                                        fullWidth
                                        variant="outlined"
                                        // label="Estado"
                                        placeholder="Estado"
                                        id="stateInput"
                                        name="stateInput"
                                        value={newOne}
                                        defaultValue={0}
                                        onChange={(e) => inputChange(e)}
                                        InputLabelProps={{
                                            shrink: stateShrink,
                                        }}
                                        className="m-tb-20 form-control data-input"
                                        sx={{
                                            mb: 2,
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    borderColor: "#6C63FF",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem key={0} value={0}>
                                            {"Seleccione un estado"}
                                        </MenuItem>
                                        {states?.map((option) => (
                                            <MenuItem
                                                key={option?.id}
                                                value={option?.id || 0}
                                            >
                                                {option?.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        {...register(
                                            "municipalityInput",
                                            registerOptions?.municipalityInput
                                        )}
                                        select
                                        fullWidth
                                        variant="outlined"
                                        // label="Municipio"
                                        placeholder="Municipio"
                                        id="municipalityInput"
                                        name="municipalityInput"
                                        value={newTwo}
                                        defaultValue={0}
                                        onChange={(e) => inputChange(e)}
                                        InputLabelProps={{
                                            shrink: municipalityShrink,
                                        }}
                                        className="m-tb-20 form-control data-input"
                                        sx={{
                                            mb: 2,
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    borderColor: "#6C63FF",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem key={0} value={0}>
                                            {"Seleccione un municipio"}
                                        </MenuItem>
                                        {municipalities?.map((option) => (
                                            <MenuItem
                                                key={option?.id}
                                                value={option?.id || 0}
                                            >
                                                {option?.name}
                                            </MenuItem>
                                        ))}
                                        <Grid
                                            item
                                            xs={12}
                                            className={"lead-form-input-error"}
                                        >
                                            <span>
                                                {errors?.municipalityInput && (
                                                    <p className="required-incidence m-0">
                                                        {" "}
                                                        {
                                                            errors
                                                                ?.municipalityInput
                                                                .message
                                                        }
                                                    </p>
                                                )}
                                            </span>
                                        </Grid>
                                    </TextField>
                                    <TextField
                                        {...register(
                                            "neighborhoodInput",
                                            registerOptions?.neighborhoodInput
                                        )}
                                        select
                                        fullWidth
                                        variant="outlined"
                                        // label="Colonia"
                                        placeholder="Colonia"
                                        id="neighborhoodInput"
                                        name="neighborhoodInput"
                                        value={newThree}
                                        defaultValue={0}
                                        onChange={(e) => inputChange(e)}
                                        InputLabelProps={{
                                            shrink: neighborhoodShrink,
                                        }}
                                        className="m-tb-20 form-control data-input"
                                        sx={{
                                            mb: 2,
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    borderColor: "#6C63FF",
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem key={0} value={0}>
                                            {"Seleccione una colonia"}
                                        </MenuItem>
                                        {neighborhoods?.map((option) => (
                                            <MenuItem
                                                key={option?.id}
                                                value={option?.id || 0}
                                            >
                                                {option?.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <Grid
                                        item
                                        xs={12}
                                        className={"lead-form-input-error"}
                                    >
                                        <span>
                                            {errors?.neighborhoodInput && (
                                                <p className="required-incidence m-0">
                                                    {" "}
                                                    {
                                                        errors
                                                            ?.neighborhoodInput
                                                            .message
                                                    }
                                                </p>
                                            )}
                                        </span>
                                    </Grid>
                                    <TextField
                                        {...register(
                                            "zipcodeInput",
                                            registerOptions?.zipcodeInput
                                        )}
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Código postal"
                                        placeholder="Código postal"
                                        id="zipcodeInput"
                                        name="zipcodeInput"
                                        onChange={(e) => inputChange(e)}
                                        InputLabelProps={{
                                            shrink: zipcodeShrink,
                                        }}
                                        className="m-tb-20 form-control data-input"
                                        sx={{
                                            mb: 2,
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    borderColor: "#6C63FF",
                                                },
                                            },
                                        }}
                                    />

                                    <TextField
                                        {...register(
                                            "addressInput",
                                            registerOptions?.addressInput
                                        )}
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Calle y número int/ext"
                                        placeholder="Calle y número int/ext"
                                        id="addressInput"
                                        name="addressInput"
                                        onChange={(e) => inputChange(e)}
                                        InputLabelProps={{
                                            shrink: addressShrink,
                                        }}
                                        className="m-tb-20 form-control data-input"
                                        sx={{
                                            mb: 2,
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    borderColor: "#6C63FF",
                                                },
                                            },
                                        }}
                                    />

                                    <Grid
                                        item
                                        xs={12}
                                        className={"lead-form-input-error"}
                                    >
                                        <span>
                                            {errors?.addressInput && (
                                                <p className="required-incidence m-0">
                                                    {" "}
                                                    {
                                                        errors?.addressInput
                                                            .message
                                                    }
                                                </p>
                                            )}
                                        </span>
                                    </Grid>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Número de contacto"
                                        placeholder="Número de contacto"
                                        id="contactPhoneInput"
                                        name="contactPhoneInput"
                                        {...register(
                                            "contactPhoneInput",
                                            registerOptions?.contactPhoneInput
                                        )}
                                        onChange={(e) => inputChange(e)}
                                        InputLabelProps={{
                                            shrink: contactPhoneShrink,
                                        }}
                                        className="m-tb-20 form-control data-input"
                                        sx={{
                                            mb: 2,
                                            "& .MuiOutlinedInput-root:hover": {
                                                "& > fieldset": {
                                                    borderColor: "#6C63FF",
                                                },
                                            },
                                        }}
                                    />
                                    <Grid
                                        item
                                        xs={12}
                                        className={"lead-form-input-error"}
                                    >
                                        <span>
                                            {errors?.contactForServiceInput && (
                                                <p className="required-incidence m-0">
                                                    {" "}
                                                    {
                                                        errors
                                                            ?.contactForServiceInput
                                                            .message
                                                    }
                                                </p>
                                            )}
                                        </span>
                                    </Grid>
                                </>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCopyToClipboard}
                            >
                                Cambiar
                            </Button>
                            <Button onClick={handleCloseDialog}>
                                Cancelar
                            </Button>
                        </DialogContent>
                    </Dialog>
                )}
                {isCustomDialogOpen && (
                    <CustomDialog
                        message={dialogMessage}
                        onClose={() => {
                            setIsCustomDialogOpen(false);
                            getSaleOrderDetailAction(simOrderId);
                        }}
                        open={isCustomDialogOpen}
                    />
                )}
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    saleOrderDetail: state?.saleOrderDetail,
    saleOrderEsimQr: state?.saleOrderEsimQr,
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    changeEsimToSim: state?.changeEsimToSim,
    changeSimToEsim: state?.changeSimToEsim,
    simActivation: state?.simActivation,
});

const mapDispatchToProps = {
    getEsimQr,
    municipalityAction,
    neighborhoodAction,
    getSaleOrderDetailAction,
    cleanChangeSimToEsimAction,
    cleanChangeEsimToSimAction,
    changeSimToEsimAction,
    changeEsimToSimAction,
    simActivationAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTableDetailOrder);
