import React, { useState, useEffect, useContext } from "react";

import { actionSuspendLine } from "_models/redux/request-suspend-line/action";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";

import { getEvents } from "_models/redux/events/action";
import { listcommentsTikets } from "_models/redux/request-list-comments/action";

import { LoadingButton } from "@mui/lab";
import ModalCustomActions from "modules/support/my-tickets/flow/detailTickets3/widgets/actions-tickets/modal-custom";

import { LoadersContext } from "_models/context/app";
import { useParams } from "react-router-dom";

const SuspendLine = (props) => {
    const { actionPostBarringAction, ticketId, directNumber } = props || {};
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    let params = useParams();

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    const handleClickSuspendLine = () => {
        setModalOpen(true);
    };
    useEffect(() => {
        const actionSuspendLine = props.actionSuspendLineReducer || {};

        if (actionSuspendLine.actionSuspendLine.length != 0) {
            if (actionSuspendLine.loading == false) {
                setErrorMssge("Error");
                setSeverityAlert("error");
                setAlert(true);
                setIsLoading(false);
            } else {
                setSeverityAlert("success");
                setErrorMssge("Se suspendió la línea satisfactoriamente");
                setAlert(true);
                setIsLoading(false);
                props.getEvents({ ticket_id: params.ticketId });
            }
        }
    }, [props.actionSuspendLineReducer]);

    const handleSubmit = () => {
        setIsLoading(true);
        props.actionSuspendLine(ticketId);
    };
    const clearFunction = () => {
        setAlert(false);
    };

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickSuspendLine}
                variant="outlined"
                className="button"
            >
                <h4>Suspender línea</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalCustomActions
                    modalOpen={modalOpen}
                    setOpenModal={setModalOpen}
                    titleModal="Suspender la línea"
                    subTitleModal="¿Está seguro que desea suspender la línea?"
                    actionRequest={handleSubmit}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    alert={alert}
                    setAlert={setAlert}
                    errorMssge={errorMssge}
                    setErrorMssge={setErrorMssge}
                    severityAlert={severityAlert}
                    setSeverityAlert={setSeverityAlert}
                    clearFunction={clearFunction}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    actionSuspendLineReducer: state.actionSuspendLine,
    events: state.events,
});
const mapDispatchToProps = {
    listcommentsTikets,
    actionSuspendLine,
    getEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(SuspendLine);
