import React from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { listTransferSalesAction } from "_models/redux/list-transfer-sales/action";
import { cleanTransferSalesClean } from "_models/redux/list-transfer-sales/clean-action";
import { CircularProgressStyle } from "./styles/Style";
import DataTable from "widgets/datagrid/DataTable";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { PageListSaleTransferHook } from "./hooks/PageListSaleTransferHook";
import SearchForm from "./components/SearchForm";
import GenericAddButton from "widgets/GenericAddButton/GenericAddButton";

const PageListSaleTransfer = ({
    cleanTransferSalesClean,
    listTransferSalesAction,
    listTransferSales,
}) => {
    const {
        loading,
        columns,
        mappedRows,
        handleSearch,
        handleRowClick,
        handleButtonClick,
        pagination,
        page,
        size,
        handleSubmit,
        errors,
        register,
        errorMessage,
    } = PageListSaleTransferHook({
        cleanTransferSalesClean,
        listTransferSalesAction,
        listTransferSales,
    });

    return (
        <>
            <Grid container justifyContent="left" marginBottom={4}>
                <Grid item xs={10} sm={8} md={6} lg={4}>
                    <GenericAddButton
                        onClick={handleButtonClick}
                        buttonText="Nueva venta por transferencia"
                        backgroundColor="#4C2484"
                        size="small"
                        icon={<AddCircleIcon />}
                    />
                </Grid>
            </Grid>

            <Grid>
                <SearchForm
                    handleSubmit={handleSubmit}
                    handleSearch={handleSearch}
                    errors={errors}
                    register={register}
                    errorMessage={errorMessage}
                />
            </Grid>
            {loading ? (
                <CircularProgress
                    style={CircularProgressStyle.CircularProgress}
                />
            ) : (
                <>
                    <Grid>
                        {mappedRows.length === 0 ? (
                            <p>No hay registros disponibles.</p>
                        ) : (
                            <DataTable
                                rows={mappedRows}
                                columns={columns}
                                onRowClick={handleRowClick}
                                page={page}
                                pageSize={size}
                                rowCount={pagination?.total_rows ?? 0}
                                onPageChange={pagination?.handleChangePage}
                                handleChangePageSize={
                                    pagination?.handleChangePageSize
                                }
                            />
                        )}
                    </Grid>
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    listTransferSales: state?.listTransferSales,
});

const mapDispatchToProps = {
    cleanTransferSalesClean,
    listTransferSalesAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageListSaleTransfer);
