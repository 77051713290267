import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { getLoginDataHelper } from "_controllers/helpers/getLoginData";
import PrepaidPage from "./dashboard";

const ManagerPrepaidKPIsPage = () => {
    const permission = "MANAGEMENT_REPORTS";
    // const permission = "MARKETING_REPORTS";

    const history = useNavigate();

    const { userPermissions } = getLoginDataHelper();

    useEffect(() => {
        if (
            typeof userPermissions
                ?.split(",")
                ?.find((element) => element === permission) === "undefined"
        ) {
            history("/");
        }

        return () => {};
    }, []);

    return userPermissions
        ?.split(",")
        ?.find((element) => element === permission) ? (
        <div>
            <h4> Dashboard recargas </h4>
            <PrepaidPage />
        </div>
    ) : null;
};

export default ManagerPrepaidKPIsPage;
