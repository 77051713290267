import React, { useState, useEffect, useContext } from "react";
import { SessionContext } from "_models/context/app";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Box, Button, Collapse, Alert, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import { getEvents } from "_models/redux/events/action";
import { connect } from "react-redux";
import { unlockImei } from "_models/redux/request-unlock-imei/action";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import { LoadersContext } from "_models/context/app";
import { useParams } from "react-router-dom";
const UnlockImeiContainer = (props) => {
    let params = useParams();
    const { directNumber, ticketId, imeiNumber } = props;

    const [modalOpenUnlockImei, setModalOpenUnlockImei] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [severityAlert, setSeverityAlert] = useState("info");

    const [errorMssge, setErrorMssge] = useState("");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(false);

        const unlockImei = props.unlockImeiReducer || undefined;

        if (unlockImei.loading) {
            setSeverityAlert("success");
            setErrorMssge("Desbloqueo de IMEI se realizó satisfactoriamente");
            setAlert(true);
            props.getEvents({ ticket_id: params.ticketId });
        }
    }, [props.unlockImeiReducer]);

    const handleUnlockImei = () => {
        setIsLoading(true);
        props.unlockImei({
            ticket_id: ticketId,
            imei: imeiNumber,
        });
    };

    const closePopup = () => {
        setModalOpenUnlockImei(false);
        setAlert(false);
        // props.cleanUnlockImei();
    };

    function ModalUnblockImei({ setOpenModal }) {
        return (
            <Grid container className="modalBackground">
                <Grid container xs={4} className="modalContainer">
                    <Grid container>
                        <Grid
                            item
                            xs={6}
                            className="title"
                            direction={"column"}
                        >
                            <h3>Desbloqueo de IMEI</h3>
                        </Grid>
                        <Grid item xs={6} className="titleCloseBtn">
                            <button onClick={closePopup}>
                                <CloseIcon />
                            </button>
                        </Grid>{" "}
                    </Grid>
                    <Grid item xs={12}>
                        <h4>¿Está seguro de desbloquear IMEI?</h4>
                    </Grid>

                    <Grid item xs={12} className="body">
                        <TextField
                            variant="standard"
                            disabled
                            fullWidth
                            type="text"
                            className="form-control"
                            placeholder={imeiNumber}
                            name="imeiNumber"
                            value={imeiNumber}
                        />
                    </Grid>
                    <Grid item xs={12} paddingTop={2}>
                        <Collapse in={alert}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={closePopup}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                                severity={severityAlert}
                            >
                                {errorMssge}
                            </Alert>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} className="footer">
                        <button onClick={closePopup} id="cancelBtn">
                            Cancelar
                        </button>

                        <LoadingButton
                            size="small"
                            onClick={handleUnlockImei}
                            endIcon={<SendIcon />}
                            loading={isLoading}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Unlock
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={() => {
                    setModalOpenUnlockImei(true);
                }}
                variant="outlined"
                className="button"
            >
                <h4>Desbloquear IMEI</h4>
            </LoadingButton>

            {modalOpenUnlockImei && (
                <ModalUnblockImei
                    modalOpen={modalOpenUnlockImei}
                    setOpenModal={setModalOpenUnlockImei}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    unlockImeiReducer: state.unlockImei,
    events: state.events,
});
const mapDispatchToProps = {
    unlockImei,
    getEvents,
    // cleanUnlockImei,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnlockImeiContainer);
