import React, { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { AppContext } from "_models/context/app";
import { RouterContext } from "./context";

import ErrorPage from "modules/error/page";

import { getLoginDataHelper } from "_controllers/helpers/getLoginData";

const RouterContainer = (props) => {
    const history = useNavigate();

    const routerContext = useContext(RouterContext);
    const { userLogged } = getLoginDataHelper();

    const { loggedChildren, unloggedChildren } = props || {
        loggedChildren: <ErrorPage />,
        unloggedChildren: <ErrorPage />,
    };

    useEffect(() => {
        routerContext.setRouterTitle("Back Office");
        if (userLogged) {
            // history("/dashboard");
        } else {
            // history("/login");
        }
    }, []);

    return userLogged ? loggedChildren : unloggedChildren;
};

export default RouterContainer;
