import React, { useState, useEffect, useContext } from "react";
import { SessionContext } from "_models/context/app";
import { postUpdateApn } from "_models/redux/request-upadate-apn/action";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import ModalCustomActions from "modules/support/my-tickets/flow/detailTickets3/widgets/actions-tickets/modal-custom";

import { LoadersContext } from "_models/context/app";

const ActionSendApn = (props) => {
    const { actionPostUpdateApn, ticketId, directNumber } = props || {};

    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [errorMssge, setErrorMssge] = useState("");
    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    const handleClickApn = () => {
        setModalOpen(true);
    };

    useEffect(() => {
        const { postUpdateApnObj } = actionPostUpdateApn || {};

        const { message } = postUpdateApnObj || {};

        if (message == "success") {
            setSeverityAlert("success");
            setErrorMssge("Se actualizó APN satisfactoriamente");
            setAlert(true);
        } else if (message == "Request failed with status code 409") {
            setErrorMssge("Error");
            setSeverityAlert("error");
            setAlert(true);
            setIsLoading(false);
        }
    }, [actionPostUpdateApn]);

    const handleSubmit = () => {
        setIsLoading(true);
        props.postUpdateApn(ticketId);
    };
    const clearFunction = () => {
        setAlert(false);
    };

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickApn}
                variant="outlined"
                className="button"
            >
                <h4>Actualizar APN</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalCustomActions
                    modalOpen={modalOpen}
                    setOpenModal={setModalOpen}
                    titleModal="Actualizar APN"
                    subTitleModal="¿Desea actualizar APN?"
                    actionRequest={handleSubmit}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    alert={alert}
                    setAlert={setAlert}
                    errorMssge={errorMssge}
                    setErrorMssge={setErrorMssge}
                    severityAlert={severityAlert}
                    setSeverityAlert={setSeverityAlert}
                    clearFunction={clearFunction}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    actionPostUpdateApn: state.actionPostUpdateApn,
});
const mapDispatchToProps = {
    postUpdateApn,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionSendApn);
