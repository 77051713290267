import React, { useState, useEffect } from "react";
import { useStopwatch } from "react-timer-hook";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { Button } from "@mui/material";
import { getStatusList } from "_models/redux/get-status/action";
import { connect } from "react-redux";
import { changeStatusTicket } from "_models/redux/change-status/actions";
import { useParams } from "react-router-dom";

const TicketStatus = (props) => {
    // const { ticketId } = props;
    const [value, setValue] = useState(2);
    const [customerStatus, setCustomerStatus] = useState([]);
    const [ticketId, setTicketId] = useState();
    let params = useParams();
    useEffect(() => {
        props.getStatusList();
        setTicketId(params.ticketId);
    }, []);

    useEffect(() => {
        const response = props.ticketById.response || undefined;
        if (typeof response != "undefined") {
            setValue(response.status_id);
        }
    }, [props.ticketById]);

    useEffect(() => {
        const { response } = props.statusList || { respose: undefined };
        const status = response.status || undefined;

        if (typeof status != "undefined") {
            setCustomerStatus(status);
        }
    }, [props.statusList]);

    const handleChange = (event) => {
        setValue(event.target.value);
        props.changeStatusTicket({
            ticketId: ticketId,
            statusId: event.target.value,
        });
    };

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
            >
                <FormControl variant="standard" size="small">
                    <Select
                        variant="standard"
                        defaultValue={2}
                        id="status-select"
                        value={value}
                        onChange={handleChange}
                        sx={{
                            background: "#74329d",
                            marginLeft: 2,
                            marginTop: 2,
                            color: "white",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                            },
                            ".MuiSvgIcon-root ": {
                                fill: "white !important",
                            },
                        }}
                    >
                        {customerStatus.map((status) => {
                            return (
                                <MenuItem
                                    key={status.id}
                                    value={status.id || -1}
                                >
                                    {status.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    statusList: state.statusList,
    changeStatusTicket: state.changeStatusTicket,
    incidence: state.incidence,
    ticketById: state.ticketById,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getStatusList,
    changeStatusTicket,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(TicketStatus);
