import React, { useContext, useEffect, useState } from "react";
import { Grid, Item } from "@mui/material";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import { width } from "@mui/system";

const DataTableDeviceInfo = (props) => {
    const [brandDevice, setBrandDevice] = useState("NA");
    const [modelDevice, setModelDevice] = useState("NA");
    const [homologateDevice, setHomolagateDevice] = useState("NA");
    const [subcategoryDevice, setSubcategoryDevice] = useState("NA");
    const [imeiDevice, setImeiDevice] = useState("NA");
    const [esimDevice, setEsimDevice] = useState("");
    const [statusDevice, setStatusDevice] = useState("NA");
    const [band28Device, setBand28Device] = useState("NA");
    const [volteCapableDevice, setVolteCapableDevice] = useState("NA");
    const [supportedBearersDevice, setSupportedBearersDevice] = useState("NA");
    const [radioStackDevice, setRadioStackDevice] = useState("NA");
    const [deviceType, setDeviceType] = useState("NA");
    const [osDevice, setOsDevice] = useState("NA");

    useEffect(() => {
        const { imeiObj } = props.getActionImei || {};
        const imei = imeiObj.imei || {};
        const deviceFeatures = imeiObj.deviceFeatures || {};
        setBrandDevice(imeiObj.brand);
        setModelDevice(imeiObj.model);
        setHomolagateDevice(imei.homologated);
        setSubcategoryDevice(imei.sub_category);
        setImeiDevice(imei.imei);
        setEsimDevice(imei.soportaESIM);
        setStatusDevice(imei.status);
        setBand28Device(deviceFeatures.band28);
        setVolteCapableDevice(deviceFeatures.volteCapable);
        setSupportedBearersDevice(deviceFeatures.supportedBearers);
        setRadioStackDevice(deviceFeatures.radioStack);
        setDeviceType(deviceFeatures.deviceType);
        setOsDevice(deviceFeatures.os);
    }, [props.getActionImei]);

    return (
        <Grid container spacing={5} className="device-info-container m-b-15">
            <Grid item xs={6} className="m-t-20">
                <Grid container rowSpacing={2} spacing={2}>
                    <h3>Características del equipo</h3>
                    <Grid item xs={12}>
                        <Grid container className="device-info">
                            <Grid item xs={6}>
                                <p>Marca</p>
                                <span> {brandDevice} </span>
                            </Grid>
                            <Grid item xs={6} className="device-right-data">
                                <p>Modelo</p>
                                <span>{modelDevice}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="device-info">
                            <p>IMEI</p>
                            <span>{imeiDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="device-info">
                            <p>Estatus</p>
                            <span>{statusDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className="device-info">
                            <p>Homologado</p>
                            <span>
                                {homologateDevice}
                                {" / "}
                                {subcategoryDevice}
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="device-info">
                            <p>Soporta Banda28</p>
                            <span>{band28Device}</span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="device-info">
                            <p>Soporta VoLTE</p>
                            <span>{volteCapableDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="device-info">
                            <p>Soporta eSIM</p>
                            <span>{esimDevice}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} className="m-t-20">
                <Grid container rowSpacing={2} spacing={2}>
                    <h3>Características soportadas</h3>
                    <Grid item xs={12}>
                        <div className="device-info">
                            <p>SupportedBearers</p>
                            <span>{supportedBearersDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            overflow="auto"
                            className="device-info device-overflow"
                        >
                            <p>RadioStack</p>
                            <span>{radioStackDevice}</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="device-info">
                            <p>DeviceType</p>
                            <span>{deviceType}</span>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="device-info">
                            <p>OS</p>
                            <span>{osDevice}</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    getActionImei: state.getActionImei,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTableDeviceInfo);
