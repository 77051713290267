import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import { ForgotPasswordContext } from "./context";

import ForgotPasswordComponent from "./component";
import { useForgotPassword } from "./hook";

import { forgotPasswordAction } from "_models/redux/forgot-password/action";

const ForgotPassword = (props) => {
    const history = useNavigate();

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [forgotPasswordResponse, setForgotPasswordResponse] = useState();
    const [forgotPasswordResponseCls, setForgotPasswordResponseCls] = useState({
        container: "",
        text: "",
    });

    const [variant, setVariant] = useState("determinate");
    const [progress, setProgress] = useState(0);

    const { forgotPasswordAction, forgotPassword } = props;

    const { sendMethod } = useForgotPassword({
        forgotPassword,
        forgotPasswordAction,
    });

    const sendForgotPassword = (data) => {
        setVariant("indeterminate");
        sendMethod(data);
    };

    const forgotPasswordValues = {
        sendForgotPassword,

        forgotPasswordResponse,
        setForgotPasswordResponse,
        forgotPasswordResponseCls,
        setForgotPasswordResponseCls,

        variant,
        setVariant,
        progress,
        setProgress,

        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,
    };

    useEffect(() => {}, []);

    useEffect(() => {
        if (typeof forgotPassword.forgotPassword == "undefined") {
            return false;
        }
        const { message } = forgotPassword.forgotPassword || {};

        if (message == "success") {
            setForgotPasswordResponse("Envío exitoso");
            setForgotPasswordResponseCls({
                container: "success-container",
                text: "success-text",
            });
            setVariant("determinate");
            history("/forgot-password-success");
        }
        // else {
        //     setForgotPasswordResponse("Envío fallido");
        //     setForgotPasswordResponseCls({
        //         container: "error-container",
        //         text: "error-text",
        //     });
        //     setVariant("determinate");
        //     history("/forgot-password-error");
        // }
    }, [forgotPassword]);

    return (
        <ForgotPasswordContext.Provider value={forgotPasswordValues}>
            <ForgotPasswordComponent />
        </ForgotPasswordContext.Provider>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    forgotPassword: state.forgotPassword,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    forgotPasswordAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
