import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "moment/locale/es";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadersContext } from "_models/context/app";
import { getMySalesExecutiveDetails } from "_models/redux/my-sales-detail-executive/action";
import { getMySalesExecutive } from "_models/redux/reports-executive/action";
import { cleanMySalesAction } from "_models/redux/my-sales/clean-action";
import { Chart } from "react-google-charts";
import { cleanMySalesExecutiveAction } from "_models/redux/reports-executive/clean";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SalesDataTableExecutive = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};
    const {
        getMySalesExecutiveDetails,
        cleanMySalesAction,
        mySalesExecutive,
        getMySalesExecutive,
        mySalesExecutiveDetails,
    } = props || {};

    dayjs.locale("es");

    const currentDate = dayjs();
    const firstDayOfMonth = currentDate.startOf("month");
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState();
    const [options, setOptions] = useState([]);
    const [dropdownValue, setDropdownValue] = useState("");
    const [startValue, setStartValue] = useState(firstDayOfMonth);
    const [endValue, setEndValue] = useState(currentDate);
    const [fromTime, setFromTime] = useState(firstDayOfMonth.format());
    const [toTime, setToTime] = useState(currentDate.format());
    const [data, setData] = useState([]);
    const [displayDatepickers, setDisplayDatepickers] = useState(
        "displayDatepickers hidden"
    );
    const [render, setRender] = useState(true);
    const [dayDate, setDayDate] = useState("");
    const location = useLocation();
    const history = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search);
    const fromTimeParameters = queryParameters.get("from_time");
    const toTimeParameters = queryParameters.get("to_time");
    const daySelected = queryParameters.get("date");

    useEffect(() => {
        if (fromTimeParameters !== null) {
            const fromDate = dayjs(fromTimeParameters);
            const toDate = dayjs(toTimeParameters);

            const areNotFirstAndLastDay =
                !fromDate.isSame(firstDayOfMonth, "day") ||
                !toDate.isSame(currentDate.endOf("month"), "day");

            setFromTime(fromTimeParameters);
            setToTime(toTimeParameters);

            if (areNotFirstAndLastDay) {
                setDisplayDatepickers("displayDatepickers shown");
                setStartValue(fromDate);
                setEndValue(toDate);
            } else {
                setDisplayDatepickers("displayDatepickers hidden");
            }
        } else if (daySelected !== null) {
        }
    }, [location, fromTimeParameters, toTimeParameters, daySelected]);

    useEffect(() => {
        populateDropdown();
        const urlContent = window.location.href;

        if (urlContent.includes("from_time")) {
            const urlSplit = urlContent.split("?")[1];
            setFromTime(urlSplit.split("&")[0].split("from_time=")[1]);
            setToTime(urlSplit.split("&")[1].split("to_time=")[1]);
            setStartValue(dayjs(urlSplit.split("&")[0].split("from_time=")[1]));
            setEndValue(dayjs(urlSplit.split("&")[1].split("to_time=")[1]));
        }

        return () => {
            cleanMySalesAction();
        };
    }, []);

    useEffect(() => {
        if (location?.search == "" && !render) {
            if (
                fromTime.split("-")[2] !== "01T00:00:00" ||
                toTime.split("-")[2].split("T")[1] !== "23:59:59"
            ) {
                setDisplayDatepickers("displayDatepickers shown");
            }
            if (dropdownValue === 1) {
                getMySalesMethod();
            }
        }
    }, [fromTime, toTime]);

    useEffect(() => {
        if (fromTime && toTime && render) {
            setRender(false);
        }
    }, []);

    useEffect(() => {
        if (dropdownValue !== null && dropdownValue !== "" && !render) {
            setRender(false);
            if (dropdownValue !== 1) {
                getMySalesMethod();
            }
        }
    }, [dropdownValue]);

    useEffect(() => {
        const dataArray = [];
        dataArray.push(["Fecha", "# Venta diaria"]);
        const { payload } = mySalesExecutive || {};
        const { result } = payload || {};
        if (mySalesExecutive?.loaded) {
            setIsLoading(false);
            setRows(payload);
            if (result?.length > 0) {
                result?.map((item, index) => {
                    dataArray.push([item?.date || 0, item?.total_sales]);
                });
            }
        }
        setData(dataArray);
    }, [mySalesExecutive]);

    useEffect(() => {
        // if (mySalesEjecutivoDetails.loaded) {
        //     history("/sales/dashboard/details");
        // }
    }, [mySalesExecutiveDetails]);

    useEffect(() => {
        if (dayDate !== "") {
            getMySalesExecutiveDetails({
                date: dayDate,
            });
            history(`/sales/ejecutivo/details?date=${dayDate}`);
        }
    }, [dayDate]);

    const getMySalesMethod = () => {
        setIsLoading(true);
        const fromTimeFormatted = fromTime
            ? dayjs(fromTime).format("YYYY-MM-DD")
            : "";
        const toTimeFormatted = toTime
            ? dayjs(toTime).format("YYYY-MM-DD")
            : "";

        const queryParams = `from_time=${fromTimeFormatted}&to_time=${toTimeFormatted}`;
        const newUrl = `/sales/ejecutivo?${queryParams}`;

        window.history.pushState(null, "", newUrl);

        getMySalesExecutive({
            from_time: fromTimeFormatted,
            to_time: toTimeFormatted,
        });
    };

    const DialogComponent = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));

    const DialogTitleComponent = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const handleClose = () => {
        setOpen(false);
        setIsScreenLoading(false);
    };

    const handleMySalesDetails = (param) => {
        // window.history.pushState(
        //     null,
        //     "",
        //     `/sales/dashboard/details?date=${param}`
        // );

        setDayDate(param);
    };

    const PopModalError = () => {
        return (
            <div>
                <DialogComponent
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitleComponent
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Error
                    </DialogTitleComponent>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            <h1>Oops algo salió mal....</h1>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </DialogComponent>
            </div>
        );
    };

    const populateDropdown = () => {
        let myArray = [];

        for (let i = 0; i <= 5; i++) {
            const forPreviousID = moment()
                .subtract(i, "months")
                .format("YYYY-MM");

            const forPreviousName = moment()
                .subtract(i, "months")
                .format("MMMM");

            const forPreviousMonth = moment()
                .subtract(i, "months")
                .format("MM");

            const forPreviousYear = moment()
                .subtract(i, "months")
                .format("YYYY");

            myArray.push({
                id: forPreviousID,
                name: forPreviousName,
                month: forPreviousMonth,
                year: forPreviousYear,
            });
        }

        // Obtener el mes de las fechas en la URL
        const urlContent = window.location.href;

        if (urlContent.includes("?")) {
            const urlSplit = urlContent.split("?")[1];
            const queryParams = urlSplit.split("&");

            let urlMonth = null;

            queryParams.forEach((param) => {
                const [key, value] = param.split("=");
                if (key === "from_time") {
                    urlMonth = moment(value).format("YYYY-MM");
                }
            });

            // Comprobar si el mes de la URL está en myArray
            const urlMonthExistsInArray = myArray.some(
                (option) => option.id === urlMonth
            );

            if (urlMonthExistsInArray) {
                // Si el mes de la URL existe en myArray, establecer dropdownValue en ese mes
                setDropdownValue(urlMonth);
            } else {
                // Si no existe, establecer dropdownValue en "Personalizado"
                setDropdownValue("Personalizado");
            }
        } else {
            // Si no se encuentran parámetros en la URL, establecer dropdownValue en el mes actual
            setDropdownValue(moment().format("YYYY-MM"));
        }

        setOptions(myArray);
    };

    const inputChange = ({ target }) => {
        const { value, name } = target || {};

        if (value === 1) {
            setDisplayDatepickers("displayDatepickers shown");
            setStartValue(dayjs(null));
            setEndValue(dayjs(null));
        } else if (value === 0) {
            setDisplayDatepickers("displayDatepickers hidden");
            setFromTime(moment().startOf("month").format());
            setToTime(moment().add(1, "months").startOf("month").format());

            setStartValue(
                dayjs(moment().startOf("month").format("MM-DD-YYYY"))
            );
            setEndValue(dayjs(moment().endOf("month").format("MM-DD-YYYY")));
        } else {
            setDisplayDatepickers("displayDatepickers hidden");
            setFromTime(
                moment(value + "-01")
                    .startOf("month")
                    .format()
            );
            setToTime(
                moment(value)
                    // .add(1, "months")
                    .endOf("month")
                    .format()
            );

            setStartValue(
                dayjs(
                    moment(value + "-01")
                        .startOf("month")
                        .format("MM-DD-YYYY")
                )
            );
            setEndValue(
                dayjs(
                    moment(value + "-01")
                        .endOf("month")
                        .format("MM-DD-YYYY")
                )
            );
        }

        setDropdownValue(value);
    };

    const optionsGrid = {
        height: 500,
        annotations: { style: "point" },
        legend: { position: "bottom" },
        hAxis: { textPosition: "out" },
    };

    return (
        <div>
            {isLoading ? (
                <div className="loader-container">
                    <CircularProgress size={80} />
                </div>
            ) : (
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        className={"filters-container container-1 my-sales"}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={4}
                                md={2}
                                className={"filter-container"}
                            >
                                <TextField
                                    variant="standard"
                                    select
                                    fullWidth
                                    label="*Fecha"
                                    placeholder="Seleccionar"
                                    id="monthFilter"
                                    name="monthFilter"
                                    value={dropdownValue || ""}
                                    onChange={(e) => inputChange(e)}
                                    className="m-tb-20 form-control customDropdown"
                                    sx={{
                                        mb: 2,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#6C63FF",
                                            },
                                        },
                                        background: "white",
                                    }}
                                >
                                    {options?.map((option, index) => (
                                        <MenuItem
                                            key={option?.id}
                                            value={option?.id}
                                            className="customDropdownItem"
                                        >
                                            {option?.name} {option?.year}
                                        </MenuItem>
                                    ))}
                                    <MenuItem key={1} value={1}>
                                        {"Personalizado"}
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={4} className={displayDatepickers}>
                                <Grid container>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={4}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale={"es"}
                                        >
                                            <DatePicker
                                                value={startValue}
                                                onChange={(newValue) => {
                                                    setStartValue(newValue);
                                                    setDropdownValue(1);
                                                    setFromTime(
                                                        moment(
                                                            newValue.format(
                                                                "YYYY-MM-DD"
                                                            )
                                                        ).format()
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={4}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale={"es"}
                                        >
                                            <DatePicker
                                                value={endValue}
                                                onChange={(newValue) => {
                                                    setEndValue(newValue);
                                                    setDropdownValue(1);
                                                    setToTime(
                                                        moment(
                                                            newValue.format(
                                                                "YYYY-MM-DD"
                                                            )
                                                        ).format()
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {data?.length > 1 ? (
                        <Grid item xs={12} md={12}>
                            <Chart
                                chartType="LineChart"
                                width="100%"
                                height="auto"
                                data={data}
                                options={optionsGrid}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12} md={12} className="container-2 my-sales">
                        <div
                            style={{ height: "auto", width: "auto" }}
                            className={"leads-list"}
                        >
                            {" "}
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Ver Detalle</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Fecha</TableCell>
                                                    <TableCell align="left">
                                                        Cantidad
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows?.result?.map((row) => (
                                                    <TableRow
                                                        key={row.date}
                                                        sx={{
                                                            "&:last-child td, &:last-child th":
                                                                {
                                                                    border: 0,
                                                                },
                                                        }}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            {row.date}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Button
                                                                onClick={() =>
                                                                    handleMySalesDetails(
                                                                        row.date
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    row.total_sales
                                                                }
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                            <PopModalError />
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    mySalesExecutiveDetails: state?.mySalesExecutiveDetails,
    mySalesExecutive: state?.mySalesExecutive,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanMySalesAction,
    getMySalesExecutive,
    cleanMySalesExecutiveAction,
    getMySalesExecutiveDetails,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesDataTableExecutive);
