import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { SessionContext } from "_models/context/app";
import CardOffers from "./card-offers";

const ContainerModules = (props) => {
    const sessionContext = useContext(SessionContext);
    const { typeServiceSelect, setTypeServiceSelect } = sessionContext || {};
    const { directNumber, ticketId } = props;
    const [services, setServices] = useState([]);
    const [unitTypeService, setUnitTypeService] = useState("");

    useEffect(() => {
        // if (props.getCustomerProfile != undefined) {
        //     props.getCustomerProfile({ directNumber: directNumber });
        // }
    }, []);

    useEffect(() => {
        if (typeServiceSelect == "calls") {
            setUnitTypeService("minutos");
        } else if (typeServiceSelect == "data") {
            setUnitTypeService("MB");
        } else if (typeServiceSelect == "sms") {
            setUnitTypeService("sms");
        }
    }, [typeServiceSelect]);

    useEffect(() => {
        const { customerProfile } = props.customerProfile || undefined;
        const offers = customerProfile?.offers || undefined;

        if (typeof offers != "undefined") {
            setServices(offers);
        }
    }, [props.customerProfile]);

    return <CardOffers services={services} typeService={unitTypeService} />;
};

const mapStateToProps = (state) => ({
    customerProfile: state.customerProfile,
});
const mapDispatchToProps = { getCustomerProfile };

export default connect(mapStateToProps, mapDispatchToProps)(ContainerModules);
