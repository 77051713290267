import {
    SALE_ORDERS,
    SALE_ORDERS_ERROR,
    CLEAN_SALE_ORDERS,
    CLEAN_SALE_ORDERS_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SALE_ORDERS:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };

        case SALE_ORDERS_ERROR:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_SALE_ORDERS:
            return {
                ...state,
                loaded: false,
                method: undefined,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_SALE_ORDERS_ERROR:
            return {
                ...state,
                loaded: false,
                method: undefined,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
