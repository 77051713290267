import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";

function HeaderTitle(props) {
    const [timePassed, setTimePassed] = useState("");
    const { ticketId, directNumber, titleTicket, ticketById } = props;
    const { response } = ticketById || undefined;
    const { created } = response || undefined;

    useEffect(() => {
        const interval = setInterval(() => {
            const createdMoment = moment.unix(created);

            const duration = moment.duration(moment().diff(createdMoment));

            const days = duration.asDays();
            const hours = duration.hours();
            const minutes = duration.minutes();

            const formattedTime = `${Math.floor(
                days
            )} días, ${hours} horas, ${minutes} minutos`;

            setTimePassed(formattedTime);
        }, 1000);

        return () => clearInterval(interval);
    }, [created]);

    return (
        <Grid container className="page-header ticket-detail">
            <Grid
                item
                xs={6}
                textAlign={"start"}
                display="flex"
                alignItems="center"
                paddingTop={"15px"}
            >
                <a href="/my-tickets">
                    <ArrowBackIosIcon className={"icon"} />
                </a>
                <h3>{titleTicket}</h3>
            </Grid>
            <Grid
                item
                xs={6}
                className="right"
                display="flex"
                justifyContent="flex-end"
                paddingTop={"15px"}
            >
                <div className="counter" style={{ textAlign: "end" }}>
                    <Button disabled variant="outlined" className="item">
                        Abierto hace: {timePassed}
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}

export default HeaderTitle;
