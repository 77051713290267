import { Grid } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuCheckImeiWidget from "../widgets/menu_check_imei_widget";
import { useLayoutMenuList } from "modules/layout/hooks/useLayoutMenuList";
import CustomListItem from "./components/CustomListItem";
import MarketingMenu from "./components/MarketingMenu";
import SalesReportsSimMenu from "./components/SalesReportsSimMenu";
import LineReportsMenu from "./components/LineReportsMenu";
import ShippingMenu from "./components/ShippingMenu";
import AccountingMenu from "./components/AccountingMenu";
import CallCenterSalesMenu from "./components/CallCenterSalesMenu";
import SupportTicketsMenu from "./components/SupportTicketsMenu";

const LayoutMenuList = (props) => {
    const {
        activeStyle,
        open,
        openMenuSupport,
        openMenuSales,
        openMenuMarketing,
        openMenuReportsSales,
        openMenuReportsLines,
        openMenuAccounting,
        openShipping,
        handleClickShipping,
        handleClickSupport,
        handleClickMarketing,
        handleClickReportsSales,
        handleClickReportsLines,
        handleClickSales,
        handleClickMenuAccounting,
        userPermissions,
    } = useLayoutMenuList() || {};

    return (
        <Grid className={"layout-menu-list"}>
            <List>
                <MenuCheckImeiWidget
                    parentProps={props}
                    userPermissions={userPermissions}
                />
                <CustomListItem
                    text="Inicio"
                    to="dashboard"
                    title="Inicio"
                    activeStyle={activeStyle}
                    open={open}
                    icon={<CottageOutlinedIcon />}
                />

                <SupportTicketsMenu
                    userPermissions={userPermissions}
                    openMenuSupport={openMenuSupport}
                    handleClickSupport={handleClickSupport}
                    activeStyle={activeStyle}
                    props={props}
                />

                <CallCenterSalesMenu
                    userPermissions={userPermissions}
                    openMenuSales={openMenuSales}
                    handleClickSales={handleClickSales}
                    activeStyle={activeStyle}
                />

                <AccountingMenu
                    userPermissions={userPermissions}
                    openMenuAccounting={openMenuAccounting}
                    handleClickMenuAccounting={handleClickMenuAccounting}
                    activeStyle={activeStyle}
                />

                <MarketingMenu
                    userPermissions={userPermissions}
                    permission="MARKETING_REPORTS"
                    handleClick={handleClickMarketing}
                    title="Marketing KPIs"
                    IconComponent={InsightsIcon}
                    openMenu={openMenuMarketing}
                    activeStyle={activeStyle}
                />

                <SalesReportsSimMenu
                    userPermissions={userPermissions}
                    openMenuReportsSales={openMenuReportsSales}
                    handleClickReportsSales={handleClickReportsSales}
                    activeStyle={activeStyle}
                />

                <LineReportsMenu
                    userPermissions={userPermissions}
                    openMenuReportsLines={openMenuReportsLines}
                    handleClickReportsLines={handleClickReportsLines}
                    activeStyle={activeStyle}
                />

                <ShippingMenu
                    userPermissions={userPermissions}
                    openShipping={openShipping}
                    handleClickShipping={handleClickShipping}
                    activeStyle={activeStyle}
                />
            </List>
            <Divider />
            <List>
                <CustomListItem
                    text="Salir"
                    to="close"
                    title="Cerrar sesión"
                    activeStyle={activeStyle}
                    open={open}
                    icon={<LogoutIcon />}
                />
            </List>
        </Grid>
    );
};

export default LayoutMenuList;
