import React, { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import CustomDialog from "widgets/component/CustomDialog";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomFormApplyPackage from "modules/support/my-tickets/flow/detailTickets3/components/custom-form-apply-package";
import { postPortabilityAction } from "_models/redux/portability/action";
import { cleanPortabilityAction } from "_models/redux/portability/clean-action";
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { LoadersContext } from "_models/context/app";

const ActionApplyPackage = (props) => {
    const { cleanPortabilityAction, portability } = props;
    const { loaded, payload, status, state } = portability || {};
    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    const [success, setSuccess] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loaderButton, setLoaderButton] = useState(false);
    const [response, setResponse] = useState("");
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);

    const {
        handleSubmit,
        control,
        register,
        setValue,
        watch,
        reset,
        getValues,
        formState: { errors },
    } = useForm();

    const onSubmit = (formData) => {};

    const handleSuccessDialogClose = () => {
        setSuccessDialogOpen(false);
    };

    const handleClickButton = () => {
        setIsLoading(true);
        setIsDialogOpen(true);
    };

    useEffect(() => {
        cleanPortabilityAction();
        return () => {
            cleanPortabilityAction();
        };
    }, []);

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        const { message, additional_details, context, detail, title, type } =
            payload || {};

        if (loaderButton && loaded) {
            setResponse(
                <Grid container className={"m-t-15 response-container"}>
                    <Grid item xs={12} className={"response-title"}>
                        {title || ""}
                    </Grid>
                    <Grid item xs={12} className={"response-text"}>
                        {detail || message || ""}
                    </Grid>
                </Grid>
            );
            setLoaderButton(false);
        }
    }, [portability]);

    useEffect(() => {
        if (isDialogOpen) {
            setIsLoading(false);
        }
    }, [isDialogOpen]);

    const myWrapper = (myComponents) => {
        return <form onSubmit={handleSubmit(onSubmit)}>{myComponents} </form>;
    };

    const handleAccept = () => {
        // Después de aplicar el paquete con éxito, abrir el diálogo de éxito
        setIsDialogOpen(false); // Cerrar el diálogo principal
        setSuccessDialogOpen(true);
    };
    return (
        <Grid item>
            {/* <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickButton}
                variant="outlined"
                className="button"
            >
                <h4>aplicar paquete</h4>
            </LoadingButton> */}

            <CustomDialog
                title="Aplicar paquete"
                wrapperCallback={myWrapper}
                content={
                    <CustomFormApplyPackage
                        control={control}
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        reset={reset}
                        getValues={getValues}
                        errors={errors}
                    />
                }
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                fullWidth
                maxWidth="sm"
                buttonPosition="end"
                extraButtons={[
                    {
                        label: "Cancelar",
                        variant: "text",
                        onClick: () => {
                            setIsDialogOpen(false);
                        },
                        buttonColor: "#ffff",
                    },
                    {
                        label: "Aceptar",
                        variant: "contained",
                        buttonColor: "#502CAA",
                        textColor: "white",
                        onClick: handleAccept,
                        type: "submit",
                    },
                ]}
            />
            <CustomDialog
                title=""
                content={
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            {success ? (
                                <CheckCircleIcon
                                    sx={{ fontSize: 40, color: "#724ECB" }}
                                />
                            ) : (
                                <CancelIcon
                                    sx={{ fontSize: 40, color: "#CB4E4E" }}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <Typography align="center">
                                {success
                                    ? "Se ha aplicado correctamente el paquete Dale 50 con el número de autorización 12345"
                                    : "Ocurrió un problema al aplicar el paquete, reportar a TI."}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                maxWidth="xs"
                buttonPosition="center"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "text",
                        onClick: handleSuccessDialogClose,
                        textColor: success ? "#724ECB" : "#CB4E4E",
                    },
                ]}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    portability: state?.portability,
});
const mapDispatchToProps = {
    postPortabilityAction,
    cleanPortabilityAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionApplyPackage);
