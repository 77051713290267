import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#e9e0fd",
        color: "#715d7d",
        fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({}) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#faf6ff",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const LinksTable = ({ columns, rows }) => {
    return (
        <>
            <TableContainer className="reportsTableCLS" component={Paper}>
                <Table
                    stickyHeader
                    sx={{ minWidth: 250 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow key={1}>
                            {columns?.map((column, columnIndex) => {
                                return column.headerName === "lead_id" ? (
                                    <StyledTableCell
                                        key={columnIndex}
                                        align={column.headerAlign}
                                    >
                                        {column.headerName}
                                    </StyledTableCell>
                                ) : (
                                    <StyledTableCell
                                        key={columnIndex}
                                        align={column.headerAlign}
                                    >
                                        {column.headerName}
                                    </StyledTableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, rowIndex) => (
                            <StyledTableRow
                                key={rowIndex}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                {columns?.map((column, columnIndex) => {
                                    const item = column
                                        ? Object?.keys(column).map(
                                              (key) => column[key]
                                          )
                                        : [];

                                    return item[0] === "lead_id" ? (
                                        <StyledTableCell align={column?.align}>
                                            <a
                                                href={
                                                    "lead-detail/" + row.lead_id
                                                }
                                                classClasName={
                                                    "lead-detail-link"
                                                }
                                            >
                                                <b>
                                                    <u>{row[item[0]]}</u>
                                                </b>
                                            </a>
                                        </StyledTableCell>
                                    ) : (
                                        <StyledTableCell align={column?.align}>
                                            {row[item[0]]}
                                        </StyledTableCell>
                                    );
                                })}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default LinksTable;
