import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";

import { Chart } from "react-google-charts";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { SessionContext } from "_models/context/app";
import ProgressBar from "../components/progress-bar";
import { getSpeed } from "../../../../../../_controllers/helpers/getSpeed";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CardOffers = ({ services, typeService }) => {
    const sessionContext = useContext(SessionContext);
    const { typeServiceSelect, setTypeServiceSelect } = sessionContext || {};

    const colorsList = ["#6D39EF", "#FF28DF", "#00F5FF"];

    const Item1 = styled(Paper)(({ theme }) => ({
        backgroundColor: "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(0),
        textAlign: "center",
        color: theme.palette.text.secondary,
    }));

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "#FBFBFB",
        ...theme.typography.body2,
        padding: theme.spacing(2),
        color: "050505",
        border: "1px solid #DEDEDE",
    }));

    const filterServiceType = (serviceType, offerName) => {
        offerName = offerName.toLowerCase();

        if (serviceType == "calls") {
            return offerName.includes("min");
        } else if (serviceType == "sms") {
            return offerName.includes("sms");
        } else if (serviceType == "data") {
            return !offerName.includes("min") && !offerName.includes("sms");
        }
    };
    const stringToDate = (dateString) => {
        var year = dateString.substring(0, 4);
        var month = dateString.substring(4, 6);
        var day = dateString.substring(6, 8);
        var hours = dateString.substring(8, 10);
        var minutes = dateString.substring(10, 12);
        var secconds = dateString.substring(12, 14);

        // Date.parse
        const date =
            year +
            "/" +
            month +
            "/" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            secconds;
        var dateFormat = new Date(date);

        var dateFormatMoment = moment(dateFormat).format("D MMMM YYYY ");

        return dateFormatMoment;
    };
    const daysFrom = (startDate, endDate) => {
        var a = moment(startDate);
        var b = moment(endDate);

        var today = moment.now();
        //var c = b.from(a);
        var c = b.diff(today, "days");

        return c;
    };

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    //TODO funcionalidad de grafica

    const testData = [
        { bgcolor: "#663CE6", completed: 60 },
        // { bgcolor: "#663CE6", completed: 30 },
        // { bgcolor: "#6DF1FE", completed: 53 },
    ];

    const getSpeedValue = getSpeed;

    return (
        <Grid container rowSpacing={5} columnSpacing={6}>
            {services.map((offer, index) => {
                const { name } = offer;

                const { freeUnit } = offer;
                const { totalAmt, unusedAmt } = freeUnit;

                let pValue = (unusedAmt * 100) / totalAmt;

                const options = {
                    legend: "none",
                    pieHole: 0.7,
                    pieSliceText: "none",
                    pieStartAngle: -125,
                    tooltip: { trigger: "none" },
                    slices: {
                        0: { color: colorsList[index % colorsList.length] },
                        1: { color: "#F1F1F1" },
                        2: { color: "transparent" },
                    },
                };
                return !filterServiceType(
                    typeServiceSelect,
                    offer.name
                ) ? null : (
                    <Grid item xs={4} key={index} className="offers-container">
                        <Grid container sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Item1 sx={{ boxShadow: 0 }}>
                                    <h4 className="header">{offer.name}</h4>
                                    <Grid container className="chart-container">
                                        <Grid item xs={12} className="chart">
                                            <Chart
                                                chartType="PieChart"
                                                data={[
                                                    ["", "Percentage"],
                                                    ["", pValue * 0.7],
                                                    ["", 70 - pValue * 0.7],
                                                    ["", 30],
                                                ]}
                                                options={options}
                                                width={"100%"}
                                                height={"250px"}
                                                position={"relative"}
                                            />
                                        </Grid>

                                        <Grid container sx={{ zIndex: "1" }}>
                                            <Grid item xs={6}>
                                                <p className="colored">
                                                    {Math.round(
                                                        offer.freeUnit
                                                            .totalAmt -
                                                            offer.freeUnit
                                                                .unusedAmt
                                                    )}{" "}
                                                    {typeService}
                                                </p>
                                                <p>Consumidos </p>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <p className="colored">
                                                    {offer.freeUnit.totalAmt}{" "}
                                                    {typeService}
                                                </p>
                                                <p>Totales</p>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className="available m-t-20"
                                            display={"flex"}
                                            sx={{
                                                px: 2,
                                                py: 1,
                                            }}
                                        >
                                            <Grid xs={10}>
                                                <p>Disponibles </p>
                                                <h3>
                                                    {Math.round(
                                                        offer.freeUnit.unusedAmt
                                                    )}{" "}
                                                    {typeService}{" "}
                                                </h3>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                display={"flex"}
                                                justifyContent={"flex-end"}
                                                sx={{
                                                    mt: 1,
                                                }}
                                            >
                                                <ExpandMore
                                                    expand={expanded}
                                                    onClick={handleExpandClick}
                                                    aria-expanded={expanded}
                                                    aria-label="show more"
                                                >
                                                    <ExpandMoreIcon />
                                                </ExpandMore>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Item1>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" rowSpacing={1}>
                            {offer.detailOfferings.map((offerDetail, i) => {
                                const { freeUnit } = offer;
                                const { unusedAmt } = freeUnit;

                                const startDate = stringToDate(
                                    offerDetail.effectiveDate
                                );
                                const endDate = stringToDate(
                                    offerDetail.expireDate
                                );
                                const remainderDays = daysFrom(
                                    startDate,
                                    endDate
                                );
                                return (
                                    <Collapse
                                        in={expanded}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <Grid item xs={12} key={i}>
                                            <Item
                                                sx={{
                                                    boxShadow: 0,
                                                    background: "#fbf9ff",
                                                    borderRadius: 5,
                                                    paddingLeft: 2,
                                                    paddingRight: 2,
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        textAlign={"start"}
                                                        className="info"
                                                    >
                                                        <h3>OFFERID</h3>
                                                        <h4>
                                                            {Math.round(
                                                                offerDetail.offeringId
                                                            )}
                                                        </h4>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        textAlign={"end"}
                                                        className="info"
                                                    >
                                                        <h3>VELOCIDAD</h3>
                                                        <h4>
                                                            {getSpeedValue(
                                                                offer.name
                                                            )}
                                                        </h4>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        textAlign={"start"}
                                                        className="info"
                                                    >
                                                        <h3>CONSUMIDOS</h3>
                                                        <h4>
                                                            {Math.round(
                                                                offerDetail.initialAmt -
                                                                    offerDetail.unusedAmt
                                                            )}{" "}
                                                            {typeService}
                                                        </h4>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        textAlign={"end"}
                                                        className="info"
                                                    >
                                                        <h3>TOTALES</h3>
                                                        <h4>
                                                            {
                                                                offerDetail.initialAmt
                                                            }{" "}
                                                            {typeService}
                                                        </h4>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="info"
                                                    >
                                                        <h3>INICIO</h3>
                                                        <h4>{startDate}</h4>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="info"
                                                        textAlign={"end"}
                                                    >
                                                        <h3>FIN</h3>
                                                        <h4>{endDate}</h4>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        className="info bar"
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            textAlign={"end"}
                                                        >
                                                            <h3 className="m-t-15">
                                                                {" "}
                                                                Quedan{" "}
                                                                {
                                                                    remainderDays
                                                                }{" "}
                                                                días
                                                            </h3>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className="bar"
                                                        >
                                                            {testData.map(
                                                                (item, idx) => (
                                                                    <ProgressBar
                                                                        key={
                                                                            idx
                                                                        }
                                                                        bgcolor={
                                                                            colorsList[
                                                                                index %
                                                                                    colorsList.length
                                                                            ]
                                                                        }
                                                                        completed={
                                                                            item.completed
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Item>
                                        </Grid>
                                    </Collapse>
                                );
                            })}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default CardOffers;
