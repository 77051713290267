import React from "react";
import { Grid } from "@mui/material";

const DeviceDetails = ({ response }) => {
    return (
        <>
            <Grid item xs={6}>
                <div>
                    <span>Marca:</span> {response?.purchase?.device?.brand}{" "}
                </div>
                <div>
                    <span>Modelo:</span> {response?.purchase?.device?.model}{" "}
                </div>
                <div>
                    <span>IMEI:</span> {response?.purchase?.device?.imei}
                </div>
            </Grid>
        </>
    );
};

export default DeviceDetails;
