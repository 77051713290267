import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

const CustomFormApplyPackage = ({ control, register, errors }) => {
    const [formState, setFormState] = useState({
        firstSelect: "",
        secondSelect: "",
        thirdTextField: "",
        showAdditionalTextField: false,
        motivoInput: "",
    });

    const handleFirstSelectChange = (event) => {
        const selectedValue = event.target.value;
        setFormState({
            ...formState,
            firstSelect: selectedValue,
            showAdditionalTextField: selectedValue === "otro",
        });
    };

    const handleSecondSelectChange = (event) => {
        setFormState({ ...formState, secondSelect: event.target.value });
    };

    const handleThirdTextFieldChange = (event) => {
        setFormState({ ...formState, thirdTextField: event.target.value });
    };

    const handleMotivoInputChange = (event) => {
        setFormState({ ...formState, motivoInput: event.target.value });
    };

    const commonTextFieldProps = {
        fullWidth: true,
        error: Boolean(errors.firstSelect),
        helperText: errors.firstSelect && errors.firstSelect.message,
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    {...register("firstSelect", {
                        required: "Este campo es obligatorio",
                    })}
                    select
                    control={control}
                    label="Seleccionar motivo"
                    onChange={handleFirstSelectChange}
                    value={formState.firstSelect}
                    {...commonTextFieldProps}>
                    <MenuItem value="option1">Option 1</MenuItem>
                    <MenuItem value="option2">Option 2</MenuItem>
                    <MenuItem value="option3">Option 3</MenuItem>
                    <MenuItem value="otro">Otro</MenuItem>
                </TextField>
            </Grid>

            {formState.showAdditionalTextField && (
                <>
                    <Grid item xs={12}>
                        <Grid style={{ marginTop: "5px", marginBottom: "5px" }}>
                            Motivo
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            {...register("motivoInput", {
                                validate: (value) =>
                                    formState.firstSelect === "otro"
                                        ? value !== "" ||
                                          "Este campo es obligatorio"
                                        : true,
                            })}
                            label="Ingresa el motivo"
                            onChange={handleMotivoInputChange}
                            value={formState.motivoInput}
                            {...commonTextFieldProps} />
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    {...register("secondSelect", {
                        required: "Este campo es obligatorio",
                    })}
                    select
                    label="Seleccionar oferta"
                    onChange={handleSecondSelectChange}
                    fullWidth
                    error={Boolean(errors.secondSelect)}
                    helperText={
                        errors.secondSelect && errors.secondSelect.message
                    }>
                    <MenuItem value="choice1">Choice 1</MenuItem>
                    <MenuItem value="choice2">Choice 2</MenuItem>
                    <MenuItem value="choice3">Choice 3</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Autenticación
                </div>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    {...register("thirdTextField", {
                        required: "Este campo es obligatorio",
                    })}
                    label="Ingresa tu token para autenticarte"
                    onChange={handleThirdTextFieldChange}
                    fullWidth
                    error={Boolean(errors.thirdTextField)}
                    helperText={
                        errors.thirdTextField && errors.thirdTextField.message
                    } />
            </Grid>
        </Grid>
    );
};

export default CustomFormApplyPackage;
