import React from "react";
import { Grid } from "@mui/material";
import TicketContainer from "./container";
import UserMyTicketsPage from "modules/support/my-tickets/flow/userTickets1/page";

const MyTicketsPage = () => {
    return <UserMyTicketsPage />;
};

export default MyTicketsPage;
