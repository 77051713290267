import { useEffect, useState } from "react";

import { Grid, Box, Checkbox } from "@mui/material";
import { Chart } from "react-google-charts";

import BarChartIcon from "@mui/icons-material/BarChart";
import TableComponent from "widgets/table/component";
import ProductTypesRowsComponent from "./product_types_rows";
import RowsComponent from "./rows";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LeadsByCampaingCharts = (props) => {
    const { parentProperties } = props || {};
    const { redux } = parentProperties || {};

    const { leadsByCampaing, ordersByProduct } = redux || {};

    const [data, setData] = useState([]);
    const [dataForGrid, setDataForGrid] = useState([]);
    const [dataForGridProductType, setDataForGridProductType] = useState([]);
    const [noDataLeadsByCampaing, setNoDataLeadsByCampaing] = useState(false);
    const [noDataOrdersByProduct, setNoDataOrdersByProduct] = useState(false);
    const [logScale, setLogScale] = useState(false);
    const [logScaleFunnel, setLogScaleFunnel] = useState(false);
    const [dataType, setDataType] = useState([]);

    const handleCheckboxChange = () => {
        setLogScale(!logScale);
    };

    const handleCheckboxChangeFunnel = () => {
        setLogScaleFunnel(!logScaleFunnel);
    };

    const options = {
        height: 500,
        colors: ["#51328B", "#24C1D1"],
        legend: { position: "top", maxLines: 3 },
        vAxis: {
            title: "Cantidad",
            scaleType: logScaleFunnel ? "log" : undefined,
        },
        isStacked: "absolute",
        fontName: "Inter-Light",
        italic: "false",
        bold: "false",
        with: "40px",
        fontSize: "14",
        background: "none",
    };

    const optionsType = {
        height: 500,
        colors: ["#CF3A9F", "#7F27A8", "#F599D7", "#906CD6"],
        legend: { position: "top", maxLines: 3 },
        vAxis: { title: "Cantidad", scaleType: logScale ? "log" : undefined },
        isStacked: "absolute",
        fontName: "Inter-Light",
        italic: "false",
        bold: "false",
        with: "40px",
        fontSize: "15",
        background: "none",
    };
    const [columns, setColumns] = useState([]);
    const [columnsProductTypes, setColumnsProductTypes] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsProductType, setRowsProductType] = useState([]);

    useEffect(() => {
        generateDataColumns();
        generateDataColumnsProductTypes();
        return () => {};
    }, []);

    useEffect(() => {
        generateDataRows();
    }, [dataForGrid]);

    useEffect(() => {
        generateDataRowsProductTypes();
    }, [dataForGridProductType]);

    useEffect(() => {
        const { payload } = leadsByCampaing || {};
        let totalA = 0;
        let totalR = 0;

        if (payload) {
            const columns = Object?.keys(payload).map((key) => key);
            const rows = Object.keys(payload).map((key) => payload[key]);

            const dataArray = [];
            const dataArrayPro = [];
            dataArray.push(["Flujo Compra", "Autoservicio", "Recuperado"]);

            if (rows?.length > 0) {
                rows?.map((item, index) => {
                    const leadsItemQuantity = item?.quantity || 0;
                    const leadsItemRecovered = item?.recovered || 0;
                    totalA = totalA + parseInt(leadsItemQuantity);
                    totalR = totalR + parseInt(leadsItemRecovered);

                    dataArray.push([
                        columns?.[index],
                        leadsItemQuantity,
                        leadsItemRecovered,
                    ]);
                    dataArrayPro.push([
                        columns?.[index],
                        leadsItemQuantity,
                        leadsItemRecovered,
                    ]);
                });
            } else {
                dataArray.push([0, 0]);
                dataArrayPro.push([0, 0]);
            }

            dataArrayPro.push(["Total", totalA, totalR]);
            setData(dataArray);
            setDataForGrid(dataArrayPro);
            if (Object.keys(leadsByCampaing.payload).length === 0) {
                setNoDataLeadsByCampaing(true);
            } else {
                setNoDataLeadsByCampaing(false);
            }
        }
    }, [leadsByCampaing]);

    useEffect(() => {
        const { payload } = ordersByProduct || {};
        let totalSimA = 0;
        let totalSimR = 0;
        let totalEsimaA = 0;
        let totalEsimaR = 0;

        if (payload) {
            const columns = Object?.keys(payload).map((key) => key);
            const rows = Object?.keys(payload).map((key) => payload[key]);

            const dataArrayType = [];
            const dataArrayTypePro = [];

            dataArrayType.push([
                "Tipo de SIM",
                "SIM - Autoservicio",
                "SIM - Recuperado",
                "eSIM - Autoservicio",
                "eSIM - Recuperado",
                { role: "annotation" },
            ]);

            if (rows?.length > 0) {
                rows?.map((item, index) => {
                    const simQuantity = item?.["SIM"]?.quantity || 0;
                    const simRecovered = item?.["SIM"]?.recovered || 0;
                    const esimQuantity = item?.["E-SIM"]?.quantity || 0;
                    const esimRecovered = item?.["E-SIM"]?.recovered || 0;
                    totalSimA = totalSimA + parseInt(simQuantity);
                    totalSimR = totalSimR + parseInt(simRecovered);
                    totalEsimaA = totalEsimaA + parseInt(esimQuantity);
                    totalEsimaR = totalEsimaR + parseInt(esimRecovered);
                    dataArrayType.push([
                        columns?.[index],
                        simQuantity,
                        simRecovered,
                        esimQuantity,
                        esimRecovered,
                        "",
                    ]);
                    dataArrayTypePro.push([
                        columns?.[index],
                        simQuantity,
                        simRecovered,
                        esimQuantity,
                        esimRecovered,
                        "",
                    ]);
                });
            } else {
                dataArrayType.push([0, 0, 0, ""]);
                dataArrayTypePro.push([0, 0, 0, ""]);
            }

            dataArrayTypePro.push([
                "Total",
                totalSimA,
                totalSimR,
                totalEsimaA,
                totalEsimaR,
            ]);
            setDataType(dataArrayType);
            setDataForGridProductType(dataArrayTypePro);
            if (Object.keys(ordersByProduct.payload).length === 0) {
                setNoDataOrdersByProduct(true);
            } else {
                setNoDataOrdersByProduct(false);
            }
        }
    }, [ordersByProduct]);

    const generateDataColumns = () => {
        let leadsColumns = [
            {
                field: "leadStatus",
                headerName: "Lead Status",
                width: 350,
            },

            {
                field: "autoService",
                headerName: "Autoservicio",
                width: 140,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "reco",
                headerName: "Recuperado",
                width: 140,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "total",
                headerName: "Total",
                width: 140,
                headerAlign: "center",
                align: "right",
            },
        ];

        setColumns(leadsColumns);
    };
    const generateDataColumnsProductTypes = () => {
        let leadsColumns = [
            {
                field: "product",
                headerName: "Producto",
                width: 220,
                position: "sticky",
            },

            {
                field: "simAutoService",
                headerName: "SIM A",
                width: 130,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "simReco",
                headerName: "SIM R",
                width: 130,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "eSimAutoService",
                headerName: "eSIM A",
                width: 130,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "eSimReco",
                headerName: "eSIM R",
                width: 130,
                headerAlign: "center",
                align: "right",
            },
            {
                field: "total",
                headerName: "Total",
                width: 120,
                headerAlign: "center",
                align: "right",
            },
        ];

        setColumnsProductTypes(leadsColumns);
    };

    const generateDataRows = () => {
        let leadsRows = [];
        if (dataForGrid != undefined) {
        }
        dataForGrid?.map((element, index) => {
            leadsRows.push({
                id: index,
                leadStatus: element[0],
                autoService: element[1],
                reco: element[2],
                total: element[1] + element[2],
                name: "row-a-" + index,
            });
        });

        setRows(leadsRows);
    };
    const generateDataRowsProductTypes = () => {
        let leadsRows = [];
        if (dataForGridProductType != undefined) {
        }
        dataForGridProductType?.map((element, index) => {
            leadsRows.push({
                id: index,
                product: element[0],
                simAutoService: element[1],
                simReco: element[2],
                eSimAutoService: element[3],
                eSimReco: element[4],
                total: element[1] + element[2] + element[3] + element[4],
                name: "row-b-" + index,
            });
        });

        setRowsProductType(leadsRows);
    };

    return (
        <Grid container spacing={5}>
            {leadsByCampaing.loaded === true &&
            noDataLeadsByCampaing === false ? (
                <Grid item xs={12}>
                    <h3>
                        Funnel de leads<span className="line"></span>
                    </h3>
                    <Grid container>
                        <Grid item xs={12} md={12} mt={3}>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="subtitle1">
                                    Escala logaritmica:
                                </Typography>
                                <Checkbox
                                    checked={logScaleFunnel}
                                    onChange={handleCheckboxChangeFunnel}
                                    label="Enable Log Scale"
                                />
                            </Box>

                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="auto"
                                data={data}
                                options={options}
                            />
                        </Grid>

                        <Grid item xs={12} md={1}></Grid>

                        <Grid item xs={12} md={12} mt={4}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Ver Detalle</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableComponent
                                        columns={columns}
                                        rowsComponent={
                                            <RowsComponent rows={rows} />
                                        }
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            ) : noDataLeadsByCampaing ? (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    height={345}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>No contiene datos, por favor intente nuevamente </p>
                    </div>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    height={363}
                >
                    <div className="kpi-empty">
                        <p>
                            <BarChartIcon />{" "}
                        </p>
                        <p>Utiliza los filtros para mostrar las gráficas </p>
                    </div>
                </Grid>
            )}

            {ordersByProduct.loaded === true &&
            noDataOrdersByProduct == false ? (
                <Grid item xs={12}>
                    <h3>
                        Distribución de compras por producto
                        <span className="line"></span>
                    </h3>

                    <Grid container>
                        <Grid item xs={12} md={12} mt={3}>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="subtitle1">
                                    Escala logaritmica:
                                </Typography>
                                <Checkbox
                                    checked={logScale}
                                    onChange={handleCheckboxChange}
                                    label="Enable Log Scale"
                                />
                            </Box>
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="auto"
                                data={dataType}
                                options={optionsType}
                            />
                        </Grid>

                        <Grid item xs={12} md={1}></Grid>

                        <Grid item xs={12} md={12} mt={4}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Ver Detalle</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableComponent
                                        columns={columnsProductTypes}
                                        rowsComponent={
                                            <ProductTypesRowsComponent
                                                rows={rowsProductType}
                                            />
                                        }
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            ) : noDataOrdersByProduct ? null : null}
        </Grid>
    );
};

export default LeadsByCampaingCharts;
