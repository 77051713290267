import React, { useState } from "react";
import { Grid } from "@mui/material";
import ShippingDetailHeaderComponent from "./_sections/_header/_component";
import ShippingDetailFooterComponent from "./_sections/_footer/_component";
import ShippingDetailDatagridComponent from "./_sections/_datagrid/_component";
import FiltersComponentDetails from "./_sections/filters/filters";

const ShippingDetailComponent = (props) => {
    return (
        <Grid container>
            <ShippingDetailHeaderComponent />
            <FiltersComponentDetails />
            <ShippingDetailDatagridComponent />
            <ShippingDetailFooterComponent />
        </Grid>
    );
};

export default ShippingDetailComponent;
