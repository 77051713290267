import { useEffect } from "react";
import { useContext } from "react";

import { GiftOrdersContext } from "../../data/giftOrdersContexts";

export const useGiftOrdersListEffect = ({
    generateDatagridColumns,
    generateDatagridRows,
}) => {
    // Obtener el contexto del módulo y sus propiedades
    const giftOrdersContext = useContext(GiftOrdersContext);

    // Desestructuración de las propiedades del contexto
    const { reduxProps, reducerProps, moduleMethods } = giftOrdersContext || {};

    // Desestructuración de las propiedades del Redux
    const {
        getGiftOrders,
        getGiftOrdersDetail,
        getGiftOrdersCleanAction,
        getGiftOrdersDetailCleanAction,
    } = reduxProps || {};

    // Desestructuración de las propiedades del Reducer
    const { giftOrdersReducer, dispatchGiftOrdersReducer } = reducerProps || {};
    const { datagridPage, datagridPageSize, datagridPageCounter } =
        giftOrdersReducer;

    // Desestructuración de las propiedades del "método del módulo"
    const { getGiftOrdersMethod, handleOpenModal, handleErrorOpenModal } =
        moduleMethods || {};

    // Renderizado del módulo
    useEffect(() => {
        // Genera las columnas del datagrid
        generateDatagridColumns();

        return () => {
            // Al destruir el componente:

            // Limpia el estado del Redux
            getGiftOrdersCleanAction();
            getGiftOrdersDetailCleanAction();

            // Cambia el estado de loadingDatagrid a false (quitar loader)
            dispatchGiftOrdersReducer({
                property: "loadingDatagrid",
                value: false,
            });
        };
    }, []);

    // Efecto que se ejecuta al obtener las órdenes de regalos
    useEffect(() => {
        // Desestructuración de las propiedades de la respuesta del endpoint
        const { status, state, response, object } = getGiftOrders || {};
        const { data } = response || {};
        const { detail } = data || {};
        const { message } = object || {};

        // Cambia el estado de loadingDatagrid a false (quitar loader)
        dispatchGiftOrdersReducer({
            property: "loadingDatagrid",
            value: false,
        });

        // Si la respuesta es exitosa, genera las filas del datagrid
        if (status === 200 && state === "success") {
            generateDatagridRows();
        } else if (detail) {
            // Si no y tiene el objeto detail (error controlado por el endpoint):
            // Cambia el estado de rows a un arreglo vacío
            dispatchGiftOrdersReducer({
                property: "rows",
                value: [],
            });

            // Agregar al modal el mensaje de error
            dispatchGiftOrdersReducer({
                property: "errorModalMessage",
                value: detail,
            });

            // Y abrir el modal de error
            handleErrorOpenModal();
        } else if (message) {
            // De lo contrario, si tiene el objeto message
            // (normlamente error no controlado: 500 o de conexión):

            // Agregar al modal el mensaje de error
            dispatchGiftOrdersReducer({
                property: "errorModalMessage",
                value:
                    message ||
                    "Error al obtener el listdo de órdenes de regalo",
            });

            // Y abrir el modal de error
            handleErrorOpenModal();
        }
    }, [getGiftOrders]);

    // Efecto que se ejecuta al obtener el detalle de una orden de regalo
    useEffect(() => {
        // Destrcuturación de las propiedades de la respuesta del endpoint
        const { status, state, response, object } = getGiftOrdersDetail || {};
        const { data } = response || {};
        const { detail } = data || {};
        const { message } = object || {};

        // Si la respuesta es exitosa, abre el modal de detalle
        if (status === 200 && state === "success") {
            handleOpenModal();
        } else if (detail) {
            // Si no y tiene el objeto detail (error controlado por el endpoint):
            // Agregar al modal el mensaje de error
            dispatchGiftOrdersReducer({
                property: "errorModalMessage",
                value: detail,
            });

            // Y abrir el modal de error
            handleErrorOpenModal();
        } else if (message) {
            // De lo contrario, si tiene el objeto message
            // (normlamente error no controlado: 500 o de conexión):

            // Agregar al modal el mensaje de error
            dispatchGiftOrdersReducer({
                property: "errorModalMessage",
                value: message,
            });

            // Y abrir el modal de error
            handleErrorOpenModal();
        }
    }, [getGiftOrdersDetail]);

    // Efecto que se ejecuta al cambiar el tamaño de la página (número de rows x pág.) del datagrid
    useEffect(() => {
        const { dpCounter, npSize } = datagridPageCounter || {};

        if (dpCounter > 0) {
            dispatchGiftOrdersReducer({
                property: "datagridPageSize",
                value: npSize,
            });
        }
    }, [datagridPageCounter]);

    // Efecto que se ejecuta al cambiar el tamaño de la página (número de rows x pág.) del datagrid
    useEffect(() => {
        if (datagridPage == 0 && datagridPageCounter?.dpCounter > 0) {
            getGiftOrdersMethod("[datagridPageSize]");
        } else {
            dispatchGiftOrdersReducer({
                property: "datagridPage",
                value: 0,
            });
        }
    }, [datagridPageSize]);

    // Efecto que se ejecuta al cambiar la página del datagrid
    useEffect(() => {
        getGiftOrdersMethod("ue[datagridPage]");
    }, [datagridPage]);

    return {};
};
