import React, { useState, useEffect, useContext } from "react";

import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { AuthContext } from "_models/context/app";

export default function Loader(props) {
    const authContext = useContext(AuthContext);
    const { loading, complete, mainScreenLoader } = props || {
        loading: false,
        mainScreenLoader: false,
    };
    const [open, setOpen] = useState(false);

    const [leftMargin, setLeftMargin] = useState();

    useEffect(() => {
        if (authContext?.isMenuOpen) {
            setLeftMargin(240);
        } else {
            setLeftMargin(36);
        }
    }, [authContext?.isMenuOpen]);

    useEffect(() => {
        if (typeof loading == "boolean") {
            if (authContext?.isMenuOpen) {
                if (mainScreenLoader) {
                    setLeftMargin(240);
                    setOpen(loading);
                } else {
                    setLeftMargin(0);
                    setOpen(loading);
                }
            } else {
                if (mainScreenLoader) {
                    setLeftMargin(36);
                    setOpen(loading);
                } else {
                    setLeftMargin(0);
                    setOpen(loading);
                }
            }
        }
    }, [loading]);

    return (
        <Backdrop
            className=""
            sx={{
                bgcolor: "rgba(88,88,88,0.2)",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <div>
                <CircularProgress color="inherit" />
            </div>
        </Backdrop>
    );
}
