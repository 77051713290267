import React from "react";
import { Grid } from "@mui/material";

const ClientDetails = ({ response }) => {
    return (
        <>
            <Grid item xs={12}>
                <div>
                    <span>Nombre:</span> {response?.purchase?.client?.name}{" "}
                </div>
                <div>
                    <span>Teléfono:</span> {response?.purchase?.client?.phone}
                </div>
                <div>
                    <span>Correo:</span> {response?.purchase?.client?.email}{" "}
                </div>
                <div>
                    <span>Dirección:</span>{" "}
                    {response?.purchase?.client?.address}{" "}
                </div>
                <hr></hr>
                <div>
                    <span>Correo del ticket:</span>{" "}
                    {response?.purchase?.client?.ticket_email}
                </div>
            </Grid>
        </>
    );
};

export default ClientDetails;
