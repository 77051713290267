import { useManagerSalesHook } from "./hooks/hook_old";

import SalesMonthlyPage from "./dashboard";
import MonthlySalesReportContainer from "./components/container-component";

const ManagerSalesMonthlyKPIsPage = () => {
    const { permission, userPermissions } = useManagerSalesHook();

    return userPermissions
        ?.split(",")
        ?.find((element) => element === permission) ? (
        <div>
            <h4> Dashboard Ventas por mes </h4>
            <MonthlySalesReportContainer />
        </div>
    ) : null;
};

export default ManagerSalesMonthlyKPIsPage;
