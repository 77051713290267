import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

//Component - Layout content (child):
import LayoutContent from "./LayoutContent";
//Hook - Layout component:
import { useLayoutHook } from "../hooks/useLayoutHook";

import LayoutAppBar from "./LayoutAppBar";
import LayoutMenu from "./LayoutMenu";

const LayoutComponent = () => {
    //Properties from hook:
    const { layoutComponentWrapper } = useLayoutHook() || {};

    //Main Layout Wrapper
    return (
        <Container
            maxWidth="false"
            className="no-prod-container"
            sx={{ display: "flex" }}
            // sx={layoutComponentWrapper || {}}
        >
            <CssBaseline />

            <LayoutAppBar />
            <LayoutMenu />

            <LayoutContent />
        </Container>
    );
};

export default LayoutComponent;
