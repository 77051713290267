import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { Grid, Container, Button, TextField } from "@mui/material";
import { getLeadsByCampaingAction } from "_models/redux/leads-by-campaing/action";
import LeadsByCampaingFilters from "modules/dashoboard/filters/filters";
import { LoadersContext } from "_models/context/app";
import { getActivationsAndDeactivationsAction } from "_models/redux/get-activations-and-deactivations/action";
import { cleanActivationsAndDeactivations } from "_models/redux/get-activations-and-deactivations/clean-action";
import PrepaidChart from "./chartPrepaid";
import { getEmailReportAction } from "_models/redux/email-report/action";
import { getIpRestrictionReportAction } from "_models/redux/ip-restriction/action";
import { getContactPhoneReportAction } from "_models/redux/contact-phone-report/action";

const PrepaidPage = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    const {
        leadsByCampaing,
        cleanLeadsByCampaingAction,
        getLeadsByCampaingAction,
        getOrdersByProductAction,
        getActivationsAndDeactivationsAction,
        activationsAndDeactivation,
        getEmailReportAction,
        getContactPhone,
        getIpRestrictionReportAction,
        getEmailReport,
        getChannelObj,
        getCampaingObj,
        landingListObj,
        cleanOrdersByProductAction,
        ordersByProduct,
        ipRestriction,
        getContactPhoneReportAction,
    } = props || {};

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [filters, setFilters] = useState({});
    const [fromTime, setFromTime] = useState(
        moment().startOf("month").format()
    );

    const [toTime, setToTime] = useState(
        moment().add(1, "months").startOf("month").format()
    );
    const [channelId, setChannelId] = useState(0);
    const [campaignId, setCampaignId] = useState(0);
    const [landingId, setLandingId] = useState(0);
    const [numberInput, setNumberInput] = useState("2");
    const [loadDataClicked, setLoadDataClicked] = useState(false);

    const [displayDatepickers, setDisplayDatepickers] = useState(
        "displayDatepickers hidden"
    );

    useEffect(() => {
        return () => {
            setIsScreenLoading(false);
        };
    }, []);

    useEffect(() => {
        const { loaded, method, payload, state, status } = ipRestriction || {};

        const { rows } = payload || {};

        if (loaded) {
            setIsScreenLoading(false);
        }
    }, [ipRestriction]);

    useEffect(() => {
        const { loaded, method, payload, state, status } =
            activationsAndDeactivation || {};

        const { rows } = payload || {};

        if (loaded) {
            setIsScreenLoading(false);
        }
    }, [activationsAndDeactivation]);

    useEffect(() => {
        const { loaded, method, payload, state, status } =
            getContactPhone || {};

        const { rows } = payload || {};

        if (loaded) {
            setIsScreenLoading(false);
        }
    }, [getContactPhone]);

    useEffect(() => {
        // Format the dates in "YYYY-MM-DD" format
        const formattedFromTime = moment(fromTime).format("YYYY-MM-DD");
        const formattedToTime = moment(toTime).format("YYYY-MM-DD");

        // Set the formatted dates back into the state
        setFromTime(formattedFromTime);
        setToTime(formattedToTime);

        // getLoadedMethods();
    }, [fromTime, toTime]);

    const getLoadedMethods = () => {
        getEmailReportAction({
            from_time: fromTime,
            to_time: toTime,
            min_ocurrences: numberInput,
        });
        getIpRestrictionReportAction({
            from_time: fromTime,
            to_time: toTime,
            min_ocurrences: numberInput,
        });
        getContactPhoneReportAction({
            from_time: fromTime,
            to_time: toTime,
            min_ocurrences: numberInput,
        });
    };

    const loadData = (e) => {
        setIsScreenLoading(true);
        getLoadedMethods();
    };

    const parentProperties = {
        form: {
            watch,
            reset,
            control,
            setValue,
            getValues,
            register,
            handleSubmit,
            setError,
            errors,
        },
        redux: {
            leadsByCampaing,
            cleanLeadsByCampaingAction,
            getLeadsByCampaingAction,
            getOrdersByProductAction,
            getActivationsAndDeactivationsAction,
            activationsAndDeactivation,
            getEmailReportAction,
            getContactPhone,
            ipRestriction,
            getContactPhoneReportAction,
            getIpRestrictionReportAction,
            getEmailReport,
            getChannelObj,
            getCampaingObj,
            landingListObj,
            cleanOrdersByProductAction,
            ordersByProduct,
        },
        states: {
            filters,
            setFilters,
            fromTime,
            setFromTime,
            toTime,
            setToTime,
            channelId,
            setChannelId,
            campaignId,
            landingId,
            setLandingId,
            setCampaignId,
            setLandingId,
            setIsScreenLoading,
            displayDatepickers,
            setDisplayDatepickers,
        },
        methods: {
            loadData,
        },
    };

    return (
        <Container className="kpi-container">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <LeadsByCampaingFilters
                        parentProperties={parentProperties}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div style={{ display: "flex" }}>
                        <TextField
                            label="Número"
                            variant="outlined"
                            value={numberInput}
                            onChange={(e) => {
                                const value = e.target.value;

                                if (/^\d*$/.test(value)) {
                                    setNumberInput(value);
                                }
                            }}
                            inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                            }}
                        />
                        <Button
                            type="button"
                            variant="contained"
                            onClick={loadData}
                            style={{ marginLeft: "8px" }} // Espacio entre el TextField y el botón
                        >
                            Cargar
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <PrepaidChart parentProperties={parentProperties} />
                </Grid>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    getContactPhone: state.getContactPhone,
    getEmailReport: state.getEmailReport,
    activationsAndDeactivation: state.activationsAndDeactivation,
    ipRestriction: state.ipRestriction,
});

const mapDispatchToProps = {
    getLeadsByCampaingAction,
    getEmailReportAction,
    getIpRestrictionReportAction,
    getContactPhoneReportAction,
    getActivationsAndDeactivationsAction,
    cleanActivationsAndDeactivations,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrepaidPage);
