import axiosTokenInstance from "../../../services/api/backoffice";
import {
    CHANGE_SUPPLEMENTARY_SERVICE,
    CHANGE_SUPPLEMENTARY_SERVICE_ERROR,
} from "_models/redux/types";

// Action function
export const postSupplementaryService = (params) => async (dispatch) => {
    const {
        ticket_id,
        service,
        service_direct_number,
        tripartite_call_waiting,
        show_private_numbers,
    } = params || {
        ticket_id: undefined,
        service: undefined,
        service_direct_number: undefined,
        tripartite_call_waiting: undefined,
        show_private_numbers: undefined,
    };

    const data = {
        service: service,
        service_direct_number: service_direct_number,
        tripartite_call_waiting: tripartite_call_waiting ? 1 : 2,
        show_private_numbers: show_private_numbers ? 1 : 2,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.post(
            `/api/support/tickets/${ticket_id}/actions/suplementary-services`
        );

        const { data } = response || {};

        dispatch({
            type: CHANGE_SUPPLEMENTARY_SERVICE,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: CHANGE_SUPPLEMENTARY_SERVICE_ERROR,
            payload: e || {},
        });
    }
};
