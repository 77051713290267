import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { resetPasswordStatusAction } from "_models/redux/reset-password/statusAction";

const ResetPasswordValidation = (props) => {
    let params = useParams();
    const history = useNavigate();

    const [urlKey, setUrlKey] = useState("");
    const [urlToken, setUrlToken] = useState("");

    const { resetPasswordStatus, resetPasswordStatusAction } = props;

    useEffect(() => {
        const { token, key } = params || {
            token: undefined,
            key: undefined,
        };

        setUrlKey(key);
        setUrlToken(token);
        resetPasswordStatusAction({ token });
    }, []);

    useEffect(() => {
        if (typeof resetPasswordStatus.resetPasswordStatus == "undefined") {
            return false;
        }

        const { message, response, status } =
            resetPasswordStatus.resetPasswordStatus || {
                message: undefined,
                response: undefined,
                status: undefined,
            };

        const { data, statusText } = response || {
            data: undefined,
            statusText: undefined,
        };
        const responseStatus =
            typeof response != "undefined" ? response.status || "" : "";

        const { additional_details, context, detail, title, type } = data || {
            additional_details: undefined,
            context: undefined,
            detail: undefined,
            title: undefined,
            type: undefined,
        };
        const additionalDetailsMessage =
            typeof additional_details != "undefined"
                ? additional_details.message || ""
                : "";

        if (responseStatus == 404) {
            history("/reset-password-expired");
        } else if (message == "ok") {
            let redirectUrl =
                "/reset-password-success/" + urlToken + "/" + urlKey;
            history(redirectUrl);
        }
    }, [resetPasswordStatus]);

    return <span></span>;
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    resetPasswordStatus: state.resetPasswordStatus,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    resetPasswordStatusAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordValidation);
