import { UNBARRING_ACTION, UNBARRING_ACTION_ERROR } from "_models/redux/types";

const initialState = {
    postUpdateUnbarringObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UNBARRING_ACTION:
            return {
                ...state,
                postUpdateUnbarringObj: action.payload,
                loading: true,
            };

        case UNBARRING_ACTION_ERROR:
            return {
                ...state,
                postUpdateUnbarringObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
