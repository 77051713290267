import moment from "moment";

import { useEffect, useReducer } from "react";

import { useReducerHelper } from "utils/helpers/reducers/useReducerHelper";

import { useFormHelper } from "utils/helpers/forms/useFormHelper";

// Hook principal - contenedor de los objetos: funciones y variables del módulo
// @param {Object} props - Propiedades del componente, en este caso las acciones de Redux
export const useGiftOrdersContainer = (reduxProps) => {
    // Llamada al helper de useFormHelper, que contiene los métodos y estados de los formularios
    const formProps = useFormHelper();
    // Desestructuración de las propiedades del formulario para futuro uso en este hook:
    const { getValues } = formProps || {};

    // Inicialización de las variables del reducer del módulo
    const initGiftOrdersReducer = {
        rows: [],
        rowCount: 50,
        columns: [],

        datagridPage: 0,
        datagridPageSize: 50,
        datagridPageCounter: {
            dpCounter: 0,
            npSize: 50,
        },

        fromDateValue: null,
        toDateValue: null,

        loadingDatagrid: true,

        openModal: false,
        errorOpenModal: false,
        errorModalMessage: "",

        searchClicked: false,
    };

    // Reducer principal del módulo
    const [giftOrdersReducer, dispatchGiftOrdersReducer] = useReducer(
        useReducerHelper,
        initGiftOrdersReducer
    );
    // Desestructuración de las propiedades del reducer para futuro uso en este hook:
    const { fromDateValue, toDateValue, searchClicked } =
        giftOrdersReducer || {};

    // Objeto del "reducer" para exportación y uso en el contexto del módulo:
    const reducerProps = {
        giftOrdersReducer,
        dispatchGiftOrdersReducer,
    };

    // GENERAL Module Methods:

    const getSearchValuesMethod = () => {
        // fromDateValue
        // Obtener el valor (formateado) del input de fecha "desde"
        let fromDateFormatted = "";
        let formatFromDateValue = "";
        if (fromDateValue) {
            formatFromDateValue = moment(
                fromDateValue.format("YYYY-MM-DD")
            ).format();
        }
        if (formatFromDateValue?.split("T")[0]) {
            fromDateFormatted = formatFromDateValue?.split("T")[0];
        }

        // toDateValue
        // Obtener el valor (formateado) del input de fecha "hasta"
        let toDateFormatted = "";
        let formatToDateValue = "";
        if (toDateValue) {
            formatToDateValue = moment(
                toDateValue.format("YYYY-MM-DD")
            ).format();
        }
        if (formatToDateValue?.split("T")[0]) {
            toDateFormatted = formatToDateValue?.split("T")[0];
        }

        // Obtiene los valores del formulario
        const filtersValues = getValues();
        // Desestructuración del valor del input de teléfono
        const { phoneInput } = filtersValues || {};

        return { phoneInput, fromDateFormatted, toDateFormatted };
    };

    // Método que llama al endpoint (request) para obtener las órdenes de regalos:
    const callActionMethod = ({
        phoneInput,
        fromDateFormatted,
        toDateFormatted,
    }) => {
        // Cambia el estado de loadingDatagrid a true (mostrar loader)
        dispatchGiftOrdersReducer({
            property: "loadingDatagrid",
            value: true,
        });

        // Llamada a la acción de Redux para obtener las órdenes de regalos
        reduxProps?.getGiftOrdersAction({
            direct_number: phoneInput,
            from_time: fromDateFormatted,
            to_time: toDateFormatted,
        });
    };

    // Método que valida los valores del formulario y llama al método de la acción
    const validateMethod = ({
        phoneInput,
        fromDateFormatted,
        toDateFormatted,
    }) => {
        //Valida que la fecha de inicio no sea menor a la fecha de fin
        if (Date.parse(fromDateFormatted) > Date.parse(toDateFormatted)) {
            // Agrega el mensaje de error al estado del reducer
            dispatchGiftOrdersReducer({
                property: "errorModalMessage",
                value: "La fecha de inicio debe ser igual o estar antes de la fecha de fin",
            });
            // Y muestra el modal del error
            handleErrorOpenModal();
        }
        // Valida teléfono NO este vacía y fechas NO estén vacías
        // Valida teléfono NO este vacía y fechas estén vacías
        // Valida que sí una fecha fue seleccionada, la otra también
        else if (
            (phoneInput !== "" &&
                phoneInput !== undefined &&
                toDateFormatted !== "" &&
                fromDateFormatted !== "") ||
            (phoneInput !== "" &&
                phoneInput !== undefined &&
                toDateFormatted === "" &&
                fromDateFormatted === "") ||
            (toDateFormatted !== "" && fromDateFormatted !== "")
        ) {
            // Llama al método de la acción exitosamente
            callActionMethod({
                phoneInput,
                fromDateFormatted,
                toDateFormatted,
            });
        }
        //Valida que ningún campo esté vacío
        else if (
            (phoneInput !== "" || phoneInput !== undefined) &&
            toDateFormatted === "" &&
            fromDateFormatted === ""
        ) {
            // Agrega el mensaje de error al estado del reducer
            dispatchGiftOrdersReducer({
                property: "errorModalMessage",
                value: "Debe llenar por lo menos un campo",
            });
            // Y muestra el modal del error
            handleErrorOpenModal();
        } else if (toDateFormatted !== "" || fromDateFormatted !== "") {
            // Agrega el mensaje de error al estado del reducer
            dispatchGiftOrdersReducer({
                property: "errorModalMessage",
                value: "Debe llenar fecha de inicio y fin",
            });
            // Y muestra el modal del error
            handleErrorOpenModal();
        }
    };

    // Método que realiza la llamada para obtener las órdenes de regalos
    const getGiftOrdersMethod = (from) => {
        // Si la variable searchClicked es true
        // (se ha dado click en el botón de búsqueda, esto para evitar que se ejecute al cargar la página)
        // obtiene los valores de los filtro (formulario de búsqueda)
        // limpia los registros del datagrid
        // llama al método de validación
        if (searchClicked) {
            // Cambia el estado de rows a un arreglo vacío
            dispatchGiftOrdersReducer({
                property: "rows",
                value: [],
            });

            const searchValues = getSearchValuesMethod() || {};

            validateMethod(searchValues);
        }
    };

    // Efecto que se ejecuta al cambiar el valor de la variable
    // que indica que se ha dado click en el botón de búsqueda
    useEffect(() => {
        if (searchClicked) {
            // Sí es true, cambia el estado de searchClicked a false
            // para poder ejecutar el método de búsqueda nuevamente
            dispatchGiftOrdersReducer({
                property: "searchClicked",
                value: false,
            });
            // Y llama al método de obtener las órdenes de regalos
            getGiftOrdersMethod("searchClicked");
        }
    }, [searchClicked]);

    // Función para abrir el modal de detalle
    const handleOpenModal = () => {
        dispatchGiftOrdersReducer({
            property: "openModal",
            value: true,
        });
    };
    // Función para cerrar el modal de detalle
    const handleCloseModal = () => {
        // Limpia el estado del Redux del detalle
        reduxProps?.getGiftOrdersDetailCleanAction();
        // Y cambia el estado de openModal a false (cierra el modal)
        dispatchGiftOrdersReducer({
            property: "openModal",
            value: false,
        });
    };

    // Función para abrir y cerrar el modal de error
    const handleErrorOpenModal = () => {
        dispatchGiftOrdersReducer({
            property: "errorOpenModal",
            value: true,
        });
    };
    // Función para cerrar el modal de error
    const handleErrorCloseModal = () => {
        dispatchGiftOrdersReducer({
            property: "errorOpenModal",
            value: false,
        });
    };

    // Objeto de los "métodos del módulo" para exportación y uso en el contexto:
    const moduleMethods = {
        // MÉTODOS:
        getGiftOrdersMethod,

        // MODALES:
        // Detalle
        handleOpenModal,
        handleCloseModal,
        // Error
        handleErrorOpenModal,
        handleErrorCloseModal,
    };

    // Propiedades / variables / estados y métodos / funciones que se inyectarán en el contexto
    return {
        reducerProps,
        reduxProps,
        formProps,
        moduleMethods,
    };
};
