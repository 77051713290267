import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { getMySalesExecutiveDetails } from "_models/redux/my-sales-detail-executive/action";
import { cleanMySalesDetailsAction } from "_models/redux/my-sales-details/clean-action";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import "moment/locale/es";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

const MySalesExecutiveDetailsPage = (props) => {
    const {
        cleanMySalesDetailsAction,
        mySalesExecutiveDetails,
        getMySalesExecutiveDetails,
    } = props || {};

    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState();
    const [dateValue, setDateValue] = useState();

    const location = useLocation();

    const queryParameters = new URLSearchParams(window.location.search);
    const daySelected = queryParameters.get("date");

    useEffect(() => {
        cleanMySalesDetailsAction();
        const urlContent = window.location.href;

        if (urlContent.includes("date")) {
            setDateValue(dayjs(urlContent.split("=")[1]));
        }
    }, []);

    useEffect(() => {
        if (daySelected !== null) {
            setIsLoading(true);
            getMySalesExecutiveDetails({
                date: daySelected,
            });
        }
    }, [location]);

    useEffect(() => {
        setIsLoading(true);

        if (mySalesExecutiveDetails.loaded) {
            setIsLoading(false);
            const { payload } = mySalesExecutiveDetails || {};
            setRows(payload?.result);
        }
    }, [mySalesExecutiveDetails]);

    const handleSubmit = (params) => {
        setIsLoading(true);
        const formatDateValue = moment(params.format("YYYY-MM-DD")).format();

        const formatDateString = formatDateValue.split("T")[0];

        setDateValue(formatDateString);

        window.history.replaceState(
            null,
            "",
            `/sales/dashboard/details?date=${formatDateString}`
        );
        getMySalesExecutiveDetails({
            date: formatDateString,
        });
    };

    return (
        <div>
            {isLoading ? ( // Show the loader if isLoading is true
                <div className="loader-container">
                    <CircularProgress size={80} />
                </div>
            ) : (
                <Grid container>
                    <Grid item xs={12}>
                        <h2>Detalles</h2>
                    </Grid>

                    <Grid>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={"es"}
                        >
                            <DatePicker
                                value={dateValue || ""}
                                onChange={(newValue) => {
                                    handleSubmit(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            style={{ height: "500px", width: "auto" }}
                            className={"leads-list"}
                        >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Fecha</TableCell>
                                            <TableCell>Usuario</TableCell>
                                            <TableCell>Nombre </TableCell>
                                            <TableCell>
                                                Número de Orden
                                            </TableCell>
                                            {/* <TableCell>Producto</TableCell> */}
                                            <TableCell>Tipo de SIM</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows?.map((row) => (
                                            <TableRow
                                                key={row.created}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.created.split("T")[0] +
                                                        " " +
                                                        row.created
                                                            .split("T")[1]
                                                            .split(".")[0]}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.backoffice_user}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.lead_client_name}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.order_number}
                                                </TableCell>
                                                {/* <TableCell component="th" scope="row">
                                            {row.product}
                                        </TableCell> */}
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.sim_type}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    mySalesExecutiveDetails: state?.mySalesExecutiveDetails,
});

const mapDispatchToProps = {
    cleanMySalesDetailsAction,
    getMySalesExecutiveDetails,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MySalesExecutiveDetailsPage);
