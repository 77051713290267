import axiosTokenInstance from "../../../services/api/backoffice";

import {
    INCIDENCE_CHANNEL,
    INCIDENCE_CHANNEL_ERROR,
} from "_models/redux/types";

// Action function
export const getIncidenceChannel = (params) => async (dispatch) => {
    const { token, key, new_password } = params || {
        token: undefined,
        key: undefined,
        new_password: undefined,
    };

    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/catalogs/support-tickets-channels`
        );

        const { data } = response || {};

        dispatch({
            type: INCIDENCE_CHANNEL,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: INCIDENCE_CHANNEL_ERROR,
            payload: e || {},
        });
    }
};
