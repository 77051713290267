import React, { useState, useEffect } from "react";

import { Grid, SvgIcon } from "@mui/material";

import miniLogo from "assets/images/mini-logo.png";
import forgotPasswordErrorImage from "assets/images/login/login-image.png";
import FormFooterComponent from "widgets/formFooter/component";

import { ReactComponent as forgotPasswordErrorIcon } from "assets/icons/login/error.svg";

const ErrorForgotPasswordPage = (props) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [errorNext, setErrorNext] = useState("");
    const [errorLast, setErrorLast] = useState("");

    useEffect(() => {
        const { from } = props || { from: undefined };
        if (from == "password-expired") {
            setErrorMessage("La liga expiró");
            setErrorNext("");
            setErrorLast("Ya puedes cerrar esta ventana");
        } else {
            setErrorMessage("No se pudo procesar la solicitud");
            setErrorNext("Inténtelo más tarde");
            setErrorLast("");
        }
        return () => {
            //action del clean
        };
    }, []);
    return (
        <Grid item container xs={12} className={"forgot-password-page"}>
            <Grid
                item
                container
                xs={0}
                sm={0}
                md={4}
                lg={6}
                className={"forgot-password-image-container"}
            >
                <img
                    title="Forgot Password Error Image"
                    alt="Forgot Password Error Image"
                    src={forgotPasswordErrorImage}
                    className={"forgot-password-image"}
                />
            </Grid>
            <Grid
                item
                container
                xs={12}
                sm={12}
                md={8}
                lg={6}
                className={"forgot-password-component-container"}
            >
                <Grid
                    item
                    container
                    xs={12}
                    className={"forgot-password-component"}
                >
                    <Grid
                        item
                        container
                        xs={12}
                        className={"forgot-password-form no-form"}
                    >
                        <Grid item container xs={12}>
                            <Grid item container xs={6} className={""}></Grid>
                            <Grid
                                item
                                container
                                xs={6}
                                className={
                                    "forgot-password-logo-container custom-logo"
                                }
                            >
                                <img
                                    title="Mini Logo"
                                    alt="Mini Logo"
                                    src={miniLogo}
                                    className={"forgot-password-logo"}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            className={"forgot-password-icon-container"}
                        >
                            <SvgIcon
                                className={"forgot-password-icon error-icon"}
                                component={forgotPasswordErrorIcon}
                                viewBox="0 0 60 60"
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            className={"forgot-password-title-container"}
                        >
                            <h3 className={"forgot-password-title custom-text"}>
                                {errorMessage}
                            </h3>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            className={"forgot-password-text-container"}
                        >
                            <p className={"forgot-password-text custom-text"}>
                                {errorNext}
                            </p>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            className={
                                "forgot-password-text-container custom-text-container"
                            }
                        >
                            <p className={"forgot-password-text"}>
                                {errorLast}
                            </p>
                        </Grid>
                        <FormFooterComponent />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ErrorForgotPasswordPage;
