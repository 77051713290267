import { Grid, Button, CircularProgress } from "@mui/material";
import OrderDetails from "./OrderDetails";
import ClientDetails from "./ClientDetails";
import DeviceDetails from "./DeviceDetails";
import ProductDetails from "./ProductDetails";
import PaymentDetails from "./PaymentDetails";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CustomDialogContent from "./DialogContent";

const PurchaseSection = ({
    response,
    handleOpenDialog,
    handleRequest,
    getEsimQr,
    copySuccess,
    handleCloseDialog,
    inputChange,
    register,
    registerOptions,
    errors,
    loading,
    labelSimType,
    isDialogOpen,
    description,
    handleDescriptionChange,
    newOne,
    states,
    stateShrink,
    newTwo,
    municipalities,
    municipalityShrink,
    newThree,
    neighborhoods,
    neighborhoodShrink,
    zipcodeShrink,
    addressShrink,
    contactPhoneShrink,
    handleCopyToClipboard,
    isCustomDialogOpen,
    CustomDialog,
    dialogMessage,
    setIsCustomDialogOpen,
    getSaleOrderDetailAction,
    simOrderId,
}) => (
    <>
        <Grid item xs={6} direction="column">
            <h2>Orden de compra</h2>
            <Grid container className="device-info">
                <OrderDetails response={response} />
            </Grid>

            <h2>Cliente</h2>
            <Grid container className="device-info">
                <ClientDetails response={response} />
            </Grid>

            <h2>Equipo</h2>
            <Grid container className="device-info">
                <DeviceDetails response={response} />
                <Grid item xs={6} className="device-right-data">
                    <Grid
                        container
                        xs={12}
                        className="type-label"
                        id={labelSimType()}
                    >
                        <div>{labelSimType()}</div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={6} direction={"column"}>
            <h2>Producto</h2>
            <Grid container className="device-info">
                <ProductDetails response={response} />
            </Grid>

            <h2>Forma de pago</h2>
            <Grid container className="device-info">
                <PaymentDetails response={response} />
            </Grid>
            <h2></h2>
            <Grid
                container
                className={
                    response?.purchase?.order_details?.sim_type_id === 2
                        ? "device-info"
                        : "hidden-element"
                }
            >
                <Grid item xs={6}>
                    <span>
                        <div>URL del QR</div>
                    </span>
                </Grid>
                <Grid item xs={6} className="device-right-data">
                    <Button
                        variant="outlined"
                        startIcon={<ContentPasteIcon />}
                        className="copy-btn"
                        onClick={() =>
                            getEsimQr(
                                response?.purchase?.order_details?.sim_order_id
                            )
                        }
                    >
                        Copiar enlace
                    </Button>
                    <p className={"copy-success"}>{copySuccess}</p>
                </Grid>
            </Grid>
            <h2></h2>

            <Grid
                container
                className={
                    response?.purchase?.order_details?.sim_type_id === 1
                        ? "device-info"
                        : "hidden-element"
                }
            >
                <Grid item xs={6}>
                    <span>
                        <div>Acción de cambio de SIM a eSIM</div>
                    </span>
                </Grid>
                <Grid item xs={6} className="device-right-data">
                    <button className="copy-btn" onClick={handleOpenDialog}>
                        Cambiar
                    </button>
                </Grid>
            </Grid>

            <Grid
                container
                className={
                    response?.purchase?.order_details?.sim_type_id === 2
                        ? "device-info"
                        : "hidden-element"
                }
            >
                <Grid item xs={6}>
                    <span>
                        <div>Acción de cambio de eSIM a SIM</div>
                    </span>
                </Grid>
                <Grid item xs={6} className="device-right-data">
                    <button className="copy-btn" onClick={handleOpenDialog}>
                        Cambiar
                    </button>
                </Grid>
            </Grid>

            <Grid container className={"device-info"} marginTop={2}>
                <Grid item xs={6}>
                    <span>
                        <div>Activación de SIM </div>
                    </span>
                </Grid>
                <Grid item xs={6} className="device-right-data">
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <button
                            className="copy-btn"
                            onClick={() =>
                                handleRequest(
                                    response?.purchase?.order_details
                                        ?.sim_order_id
                                )
                            }
                        >
                            Activar
                        </button>
                    )}
                </Grid>
            </Grid>

            <Grid container className="hidden-element">
                <Grid item xs={6}>
                    <span>
                        <div>Reembolso</div>
                    </span>
                </Grid>
                <Grid item xs={6} className="device-right-data">
                    <span>
                        <p>-ButtonGenerar-</p>
                    </span>
                </Grid>
            </Grid>
            {isDialogOpen && (
                <CustomDialogContent
                    open={isDialogOpen}
                    description={description}
                    register={register}
                    registerOptions={registerOptions}
                    handleDescriptionChange={handleDescriptionChange}
                    inputChange={inputChange}
                    newOne={newOne}
                    states={states}
                    stateShrink={stateShrink}
                    newTwo={newTwo}
                    municipalities={municipalities}
                    municipalityShrink={municipalityShrink}
                    newThree={newThree}
                    neighborhoods={neighborhoods}
                    neighborhoodShrink={neighborhoodShrink}
                    zipcodeShrink={zipcodeShrink}
                    addressShrink={addressShrink}
                    contactPhoneShrink={contactPhoneShrink}
                    handleCopyToClipboard={handleCopyToClipboard}
                    handleCloseDialog={handleCloseDialog}
                    errors={errors}
                />
            )}
            {isCustomDialogOpen && (
                <CustomDialog
                    message={dialogMessage}
                    onClose={() => {
                        setIsCustomDialogOpen(false);
                        getSaleOrderDetailAction(simOrderId);
                    }}
                    open={isCustomDialogOpen}
                />
            )}
        </Grid>
    </>
);

export default PurchaseSection;
