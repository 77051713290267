import { connect } from "react-redux";
import { Grid, CircularProgress } from "@mui/material";
import { getEsimQr } from "_models/redux/esim-qr/action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { cleanChangeSimToEsimAction } from "_models/redux/change-sim-esim/clean-action";
import { cleanChangeEsimToSimAction } from "_models/redux/change-esim-sim/clean-action";
import { changeSimToEsimAction } from "_models/redux/change-sim-esim/action";
import { changeEsimToSimAction } from "_models/redux/change-esim-sim/action";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { DataTableDetailOrderController } from "modules/my-sales/submodules/orders/hook/DataTableDetailOrderController";
import { simActivationAction } from "_models/redux/sim-activation/action";
import PrepaidOrderDetails from "./components/PrepaidOrderDetails";
import { PrepaidOrder } from "./components/PrepaidOrder";
import PurchaseSection from "./components/PurchaseSection";
const DataTableDetailOrder = (props) => {
    const {
        response,
        saleOrderDetail,
        getEsimQr,
        saleOrderEsimQr,
        municipality,
        municipalityAction,
        neighborhoodAction,
        neighborhood,
        getSaleOrderDetailAction,
        cleanChangeSimToEsimAction,
        cleanChangeEsimToSimAction,
        changeSimToEsimAction,
        changeEsimToSimAction,
        changeSimToEsim,
        changeEsimToSim,
        simActivationAction,
        simActivation,
    } = props || {};
    const {
        isDialogOpen,
        description,
        stateShrink,
        municipalityShrink,
        neighborhoodShrink,
        newOne,
        newTwo,
        newThree,
        zipcodeShrink,
        isCustomDialogOpen,
        contactPhoneShrink,
        states,
        dialogMessage,
        copySuccess,
        handleOpenDialog,
        handleCloseDialog,
        handleCopyToClipboard,
        handleDescriptionChange,
        CustomDialog,
        inputChange,
        labelSimType,
        register,
        registerOptions,
        municipalities,
        errors,
        neighborhoods,
        setIsCustomDialogOpen,
        simOrderId,
        handleRequest,
        loading,
    } = DataTableDetailOrderController({
        saleOrderDetail,
        getEsimQr,
        saleOrderEsimQr,
        municipality,
        municipalityAction,
        neighborhoodAction,
        neighborhood,
        getSaleOrderDetailAction,
        cleanChangeSimToEsimAction,
        cleanChangeEsimToSimAction,
        changeSimToEsimAction,
        changeEsimToSimAction,
        changeSimToEsim,
        changeEsimToSim,
        simActivationAction,
        simActivation,
        response,
    });

    const isLoading = !response;
    const hasPrepaidData = Object.keys(response?.prepaid || {}).length > 0;
    const hasPurchaseData = Object.keys(response?.purchase || {}).length > 0;

    return (
        <>
            {isLoading && <CircularProgress />}

            <Grid
                container
                spacing={5}
                className="device-info-container m-b-15"
            >
                {!isLoading && (
                    <>
                        {hasPrepaidData && (
                            <>
                                <Grid item xs={6} direction="column">
                                    <h2>Orden de compra</h2>
                                    <Grid container className="device-info">
                                        <PrepaidOrder
                                            prepaidData={response.prepaid}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={6} direction="column">
                                    <h2>Producto</h2>
                                    <Grid container className="device-info">
                                        <PrepaidOrderDetails
                                            prepaidData={response.prepaid}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {hasPurchaseData && (
                            <PurchaseSection
                                response={response}
                                handleOpenDialog={handleOpenDialog}
                                handleRequest={handleRequest}
                                getEsimQr={getEsimQr}
                                copySuccess={copySuccess}
                                handleCloseDialog={handleCloseDialog}
                                inputChange={inputChange}
                                register={register}
                                registerOptions={registerOptions}
                                errors={errors}
                                loading={loading}
                                labelSimType={labelSimType}
                                isDialogOpen={isDialogOpen}
                                description={description}
                                handleDescriptionChange={
                                    handleDescriptionChange
                                }
                                newOne={newOne}
                                states={states}
                                stateShrink={stateShrink}
                                newTwo={newTwo}
                                municipalities={municipalities}
                                municipalityShrink={municipalityShrink}
                                newThree={newThree}
                                neighborhoods={neighborhoods}
                                neighborhoodShrink={neighborhoodShrink}
                                zipcodeShrink={zipcodeShrink}
                                contactPhoneShrink={contactPhoneShrink}
                                handleCopyToClipboard={handleCopyToClipboard}
                                isCustomDialogOpen={isCustomDialogOpen}
                                CustomDialog={CustomDialog}
                                dialogMessage={dialogMessage}
                                setIsCustomDialogOpen={setIsCustomDialogOpen}
                                getSaleOrderDetailAction={
                                    getSaleOrderDetailAction
                                }
                                simOrderId={simOrderId}
                            />
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => ({
    saleOrderDetail: state?.saleOrderDetail,
    saleOrderEsimQr: state?.saleOrderEsimQr,
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    changeEsimToSim: state?.changeEsimToSim,
    changeSimToEsim: state?.changeSimToEsim,
    simActivation: state?.simActivation,
});

const mapDispatchToProps = {
    getEsimQr,
    municipalityAction,
    neighborhoodAction,
    getSaleOrderDetailAction,
    cleanChangeSimToEsimAction,
    cleanChangeEsimToSimAction,
    changeSimToEsimAction,
    changeEsimToSimAction,
    simActivationAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTableDetailOrder);
