import React, { useState, useEffect, useContext } from "react";
import { Tab } from "@mui/material";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import LineEvents from "./widgets/line-events";
import Tabs from "@mui/material/Tabs";
import CommentTickets from "./widgets/comment-tickets";
import OffersWidget from "./widgets/offers_widget";

import { SessionContext } from "_models/context/app";

const TabsTicket = (props) => {
    const { directNumber, ticketId } = props;

    const [value, setValue] = useState("1");

    const sessionContext = useContext(SessionContext);
    const { setTypeServiceSelect } = sessionContext || {};

    useEffect(() => {
        setTypeServiceSelect("data");
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box xs={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="action tabs"
                        sx={{
                            "& button:hover": {
                                background: "#FBF9FF",
                            },
                            "& button.Mui-selected": {
                                color: "#673ab7",
                                fontWeight: 600,
                            },
                        }}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#673ab7",
                            },
                        }}
                    >
                        <Tab label="Eventos del ticket" value="1" />
                        <Tab label="Eventos de la línea" value="2" />
                        <Tab label="Ofertas" value="3" />
                    </Tabs>
                </Box>
                <TabPanel value="1" className="tab-panel">
                    <CommentTickets
                        directNumber={directNumber}
                        ticketId={ticketId}
                    />
                </TabPanel>
                <TabPanel value="2" className="tab-panel">
                    <LineEvents />
                </TabPanel>
                <TabPanel value="3" className="tab-panel">
                    <OffersWidget
                        directNumber={directNumber}
                        ticketId={ticketId}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default TabsTicket;
