import React, { useState, useEffect, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Collapse, Grid, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { getSupplementaryService } from "_models/redux/get-supplementary-service/action";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { cleanSupplementaryService } from "_models/redux/get-supplementary-service/clean-action";
import { postSupplementaryService } from "_models/redux/post-supplementary-service/action";
import { getEvents } from "_models/redux/events/action";
import { useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";

import { LoadersContext } from "_models/context/app";

const SupplementaryService = (props) => {
    let params = useParams();

    const { directNumber, ticketId } = props;

    const [modalOpen, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [hiddenComponent, setHiddenComponent] = useState("hidden-element");
    const [tripartiteCallWaiting, setTripartiteCallWaiting] = useState(false);
    const [showPrivateNumbers, setShowPrivateNumbers] = useState(false);
    const [typeCallForwarding, setTypeCallForwarding] = useState("none");
    const [msisdnCallForwarding, setMsisdnCallForwarding] = useState("");

    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const [alert, setAlert] = useState(false);

    const [errorMssge, setErrorMssge] = useState("");

    const [severityAlert, setSeverityAlert] = useState("info");

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(false);

        const supplementaryService = props.supplementaryService || undefined;

        const supplementaryService_ =
            supplementaryService.supplementaryService || undefined;

        if (supplementaryService_.msisdn === directNumber) {
            setOpenModal(true);

            if (supplementaryService_.tripartiteCallWaiting === "SI") {
                setTripartiteCallWaiting(true);
            } else {
                setTripartiteCallWaiting(false);
            }
            if (supplementaryService_.showPrivateNumbers === "SI") {
                setShowPrivateNumbers(true);
            } else {
                setShowPrivateNumbers(false);
            }
            if (supplementaryService_.callForwarding === "SI") {
                setTypeCallForwarding("call-forwarding");
                setHiddenComponent("form-control");
                setMsisdnCallForwarding(
                    supplementaryService_.msisdn_callForwarding
                );
            } else if (supplementaryService_.voiceMail === "SI") {
                setTypeCallForwarding("voice-mail");
                setHiddenComponent("hidden-element");
                setMsisdnCallForwarding(undefined);
            } else if (
                supplementaryService_.unconditionalCallForwarding === "SI"
            ) {
                setTypeCallForwarding("unconditional-call-forwarding");
                setHiddenComponent("form-control");
                setMsisdnCallForwarding(
                    supplementaryService_.msisdn_unconditionalCallForwarding
                );
            }
        }
    }, [props.supplementaryService]);

    useEffect(() => {
        setIsLoadingButton(false);

        const changeSupplementaryService =
            props.changeSupplementaryService || {};

        const changeSuppServ =
            changeSupplementaryService.changeSupplementaryService || {};

        if (changeSuppServ.status == "success") {
            // handleCloseClean();

            setSeverityAlert("success");
            setErrorMssge("El cambió se realizó satisfactoriamente");
            setAlert(true);
            props.getEvents({ ticket_id: params.ticketId });
        } else {
            // handleCloseClean();
            setErrorMssge("Error");
            setSeverityAlert("error");
            setAlert(true);
        }
    }, [props.changeSupplementaryService]);

    const handleClickButton = () => {
        setIsLoading(true);
        props.getSupplementaryService({ ticket_id: ticketId });
    };

    const handleCloseClean = () => {
        setOpenModal(false);
        setAlert(false);
        setMsisdnCallForwarding("");
        props.cleanSupplementaryService();
    };

    const handleChangeCallForwarding = (event) => {
        setAlert(false);
        setTypeCallForwarding(event.target.value);
        if (
            event.target.value == "call-forwarding" ||
            event.target.value == "unconditional-call-forwarding"
        ) {
            setHiddenComponent("form-control");
        } else {
            setHiddenComponent("hidden-element");
        }
        if (
            event.target.value == "none" ||
            event.target.value == "voice-mail"
        ) {
            setMsisdnCallForwarding(undefined);
        }
    };

    const handleChangetripartiteCallWaiting = (event) => {
        setAlert(false);
        setTripartiteCallWaiting(event.target.checked);
    };

    const handleChangeShowPrivateNumbers = (event) => {
        setAlert(false);
        setShowPrivateNumbers(event.target.checked);
    };

    const handleChangeNumberCallForwarding = ({ target }) => {
        const { value } = target;
        setMsisdnCallForwarding(value);
    };

    const handleSubmitChangeSupplementaryService = () => {
        setIsLoadingButton(true);
        let service_direct_number = msisdnCallForwarding;
        if (msisdnCallForwarding == undefined) {
            service_direct_number = undefined;
        } else {
            service_direct_number = service_direct_number.toString();
        }

        props.postSupplementaryService({
            ticket_id: ticketId,
            service: typeCallForwarding,
            service_direct_number: service_direct_number,
            tripartite_call_waiting: tripartiteCallWaiting,
            show_private_numbers: showPrivateNumbers,
        });
    };

    const RowButtons = () => {
        return (
            <FormControl variant="standard">
                <FormLabel id="demo-row-radio-buttons-group-label">
                    <h4> Desviar la llamada a:</h4>
                </FormLabel>
                <RadioGroup
                    defaultValue="none"
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={typeCallForwarding}
                    onChange={handleChangeCallForwarding}
                >
                    <FormControlLabel
                        value="none"
                        control={<Radio />}
                        label="Ninguno"
                    />
                    <FormControlLabel
                        value="voice-mail"
                        control={<Radio />}
                        label="Correo de voz"
                    />
                    <FormControlLabel
                        value="call-forwarding"
                        control={<Radio />}
                        label="Re dirección de llamadas"
                    />
                    <FormControlLabel
                        value="unconditional-call-forwarding"
                        control={<Radio />}
                        label="Incondicional"
                    />
                </RadioGroup>
            </FormControl>
        );
    };
    const CheckBox = () => {
        return (
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={tripartiteCallWaiting}
                            onChange={handleChangetripartiteCallWaiting}
                        />
                    }
                    label="Llamada tripartita"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showPrivateNumbers}
                            onChange={handleChangeShowPrivateNumbers}
                        />
                    }
                    label="Mostrar números privados"
                />
            </FormGroup>
        );
    };

    const ModalVoiceServices = () => {
        return (
            <Grid container className="modalBackground">
                <Grid container xs={6} className="modalContainer">
                    <Grid container>
                        <Grid item xs={6} className="title">
                            <h3>Gestión de servicios de voz</h3>
                        </Grid>
                        <Grid item xs={6} className="titleCloseBtn">
                            <button onClick={handleCloseClean}>
                                <CloseIcon />
                            </button>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} direction={"column"}>
                        <Grid item xs>
                            <RowButtons />
                        </Grid>
                        <Grid item xs className={hiddenComponent}>
                            <h4>Número de desvío</h4>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                variant="standard"
                                fullWidth
                                inputProps={{
                                    maxLength: 10,
                                }}
                                name="msisdnCallForwarding"
                                type="text"
                                className={hiddenComponent}
                                placeholder="Ingrese valor"
                                value={msisdnCallForwarding}
                                onChange={handleChangeNumberCallForwarding}
                            ></TextField>
                        </Grid>
                        <Grid item xs>
                            <h4>Servicios adicionales</h4>
                        </Grid>
                        <CheckBox />
                    </Grid>
                    <Grid item xs={12} paddingTop={2}>
                        <Collapse in={alert}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={handleCloseClean}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                                severity={severityAlert}
                            >
                                {errorMssge}
                            </Alert>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} className="footer">
                        <button onClick={handleCloseClean} id="cancelBtn">
                            Cancelar
                        </button>
                        {/* <button
                            onClick={handleSubmitChangeSupplementaryService}
                        >
                            Aceptar
                        </button> */}

                        <LoadingButton
                            size="small"
                            onClick={handleSubmitChangeSupplementaryService}
                            endIcon={<SendIcon />}
                            loading={isLoadingButton}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Cambiar
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid item>
            <LoadingButton
                fullWidth
                size="small"
                onClick={handleClickButton}
                variant="outlined"
                className="button"
            >
                <h4>Gestión de servicios de voz</h4>
            </LoadingButton>
            {modalOpen && (
                <ModalVoiceServices
                    modalOpen={modalOpen}
                    setOpenModal={setOpenModal}
                />
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    supplementaryService: state.supplementaryService,
    changeSupplementaryService: state.changeSupplementaryService,
});
const mapDispatchToProps = {
    getSupplementaryService,
    cleanSupplementaryService,
    postSupplementaryService,
    getEvents,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplementaryService);
