import React from "react";
import { connect } from "react-redux";
import FiltroNetPay from "./list-transactions-netpay/components/FiltroNetPay";
import { netPayAction } from "_models/redux/list-transactions-netpay/action";
import { cleanNetPayAction } from "_models/redux/list-transactions-netpay/clean-action";
import { Grid, Typography } from "@mui/material";
import { PageNetPayHook } from "./list-transactions-netpay/hooks/PageNetPayHook";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressStyle } from "./list-transactions-netpay/style/style";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import { detailNetPayAction } from "_models/redux/detail-transactions-netpay/action";
import { cleanDetailNetPayAction } from "_models/redux/detail-transactions-netpay/clean-action";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/componentDialog";

const PageNetPay = ({
    netPayAction,
    netPay,
    cleanNetPayAction,
    cleanDetailNetPayAction,
    detailNetPayAction,
    detailTransactionsNetpay,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
    } = PageNetPayHook({
        netPayAction,
        cleanNetPayAction,
        netPay,
        cleanDetailNetPayAction,
        detailNetPayAction,
        detailTransactionsNetpay,
    });

    return (
        <>
            <Grid marginBottom={4}>
                <FiltroNetPay
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Grid>
            <Grid>
                {loading ? (
                    <CircularProgress
                        style={CircularProgressStyle.CircularProgress}
                    />
                ) : (
                    <>
                        {mappedRows?.length > 0 ? (
                            <Grid paddingRight={5} paddingLeft={0}>
                                <DataTable
                                    rows={mappedRows}
                                    columns={Columns}
                                    onRowClick={handleRowClick}
                                    page={page}
                                    pageSize={size}
                                    rowCount={pagination?.total_rows ?? 0}
                                    onPageChange={pagination?.handleChangePage}
                                    handleChangePageSize={
                                        pagination?.handleChangePageSize
                                    }
                                />
                            </Grid>
                        ) : (
                            <Typography variant="h6">
                                No hay registros disponibles
                            </Typography>
                        )}
                    </>
                )}
            </Grid>

            <CustomDialog
                fullWidth
                title="Transacción netpay"
                content={
                    <DataTableDetailOrder
                        response={detailTransactionsNetpay?.response}
                    />
                }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    netPay: state?.netPay,
    detailTransactionsNetpay: state?.detailTransactionsNetpay,
});

const mapDispatchToProps = {
    cleanNetPayAction,
    netPayAction,
    cleanDetailNetPayAction,
    detailNetPayAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageNetPay);
