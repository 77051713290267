import React, { useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import "moment/locale/es";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, TextField } from "@mui/material";
import { getSaleOrdersAction } from "_models/redux/sales-orders/action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadersContext } from "_models/context/app";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomNoRowsOverlay from "widgets/datagrid/CustomNoRowsOverlay";
import CustomLinearProgress from "widgets/datagrid/CustomLinearProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { cleanSaleOrdersAction } from "_models/redux/sales-orders/clean-action";
import { LoadingButton } from "@mui/lab";
import ModalDetailsInfo from "./modal-details-info";

const OrdersDatagrid = (props) => {
    const loadersContext = useContext(LoadersContext);

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const { setIsScreenLoading } = loadersContext || {};

    const {
        getSaleOrdersAction,
        saleOrders,
        cleanSaleOrdersAction,
        getSaleOrderDetailAction,
        saleOrderDetail,
        cleanSaleOrderDetailAction,
    } = props || {};

    const { payload, state, status, loaded, method } = saleOrders || {};

    const { result } = payload || {};

    const changePage = (newPage) => {
        setDatagridPage(newPage);
    };
    const changePageSize = (newPageSize) => {
        setDatagridPageSize(newPageSize);
        setDatagridPage(0);
    };

    const [dateValue, setDateValue] = useState(null);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [datagridPageSize, setDatagridPageSize] = useState(50);
    const [rowCount, setRowCount] = useState(50);
    const [datagridPage, setDatagridPage] = useState(0);
    const [loadingDatagrid, setLoadingDatagrid] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalOpenDetailOrder, setModalOpenDetailOrder] = useState(false);

    const [frontErrorMessage, setFrontErrorMessage] = useState("");

    useEffect(() => {
        generateDataColumns();

        return () => {
            cleanSaleOrdersAction();
            setIsScreenLoading(false);
        };
    }, []);

    useEffect(() => {
        if (dateValue) {
            const formatDateValue = moment(
                dateValue.format("YYYY-MM-DD")
            ).format();
        }
    }, [dateValue]);

    useEffect(() => {
        if (
            saleOrders?.state === "error" &&
            (saleOrders?.status === null || saleOrders?.status >= 300)
        ) {
            setOpen(true);
        } else {
            setOpen(false);
        }
        setLoadingDatagrid(false);
    }, [saleOrders]);

    useEffect(() => {
        generateDataRows();
    }, [result]);

    useEffect(() => {
        const { state, status } = saleOrderDetail || {};

        if (state === "success" && (status >= 200 || status < 300)) {
            setOpen(false);
            setModalOpenDetailOrder(true);
        } else if (state === "error" && (status >= 300 || status === null)) {
            setOpen(true);
        } else {
            setOpen(false);
        }

        setIsScreenLoading(false);
    }, [saleOrderDetail]);

    const handleActionOrderDetail = (props) => {
        getSaleOrderDetailAction(props);
        setIsScreenLoading(true);
    };

    const generateDataColumns = () => {
        let leadsColumns = [
            {
                field: "id",
                headerName: "Número de orden",
                width: 200,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { id, order_id } = row || {};
                    return (
                        <div>
                            <LoadingButton
                                fullWidth
                                property="textAlign"
                                size="small"
                                onClick={() =>
                                    handleActionOrderDetail(order_id)
                                }
                                className="button"
                                sx={{
                                    fontSize: "17px",
                                    color: "#624591",
                                }}
                            >
                                {id}
                            </LoadingButton>
                        </div>
                    );
                },
            },
            {
                field: "date",
                headerName: "Fecha de compra",
                width: 310,
            },

            {
                field: "name_order",
                headerName: "Nombre",
                width: 310,
            },
            {
                field: "contact_number_order",
                headerName: "Teléfono",
                width: 310,
            },
            {
                field: "email_order",
                headerName: "Correo electrónico",
                width: 310,
            },
        ];

        setColumns(leadsColumns);
    };
    const generateDataRows = () => {
        let ordersRow = [];

        if (result) {
            result?.map((element, index) => {
                ordersRow.push({
                    id: element?.order_number,
                    date: (element?.created).split("T")[0],
                    name_order: element?.name,
                    contact_number_order: element?.contact_phone,
                    email_order: element?.email,
                    order_id: element?.order_id,
                });
            });
        }

        setRows(ordersRow);
    };

    const inputChange = (e) => {};

    //
    const filtersValues = getValues();
    const { numberOrder } = filtersValues || {};
    //

    useEffect(() => {
        if (!open) {
            setFrontErrorMessage();
        }
    }, [open]);

    const getSaleOrdersMethod = (from) => {
        setLoadingDatagrid(true);

        const filtersValues = getValues();

        const { numberOrder, nameOrder, phoneNumberOrder, emailOrder } =
            filtersValues || {};

        if (
            numberOrder === "" &&
            nameOrder === "" &&
            phoneNumberOrder === "" &&
            emailOrder === "" &&
            (dateValue === "" || dateValue === null || dateValue === undefined)
        ) {
            setFrontErrorMessage(<p>"Debe ingresar al menos un filtro."</p>);
            setLoadingDatagrid(false);
            setOpen(true);
        } else {
            let formatDateValue = "";

            if (dateValue) {
                formatDateValue = moment(
                    dateValue.format("YYYY-MM-DD")
                ).format();
            }

            let dateFormatted = "";
            if (formatDateValue.split("T")[0]) {
                dateFormatted = formatDateValue.split("T")[0];
            }

            if (from === "search") {
                setDatagridPage(0);
            }
            getSaleOrdersAction({
                page: from === "search" ? 0 : datagridPage,
                size: datagridPageSize,
                number_order: numberOrder,
                name_order: nameOrder,
                phone_number_order: phoneNumberOrder,
                email_order: emailOrder,
                date_order: dateFormatted,
            });
        }
    };

    const onSubmit = (e) => {
        getSaleOrdersMethod("search");
    };

    const registerOptions = {
        numberOrder: {
            // required: "Número de orden ",
            minLength: {
                value: 3,
                message: "Mínimo 3 caracteres",
            },
            maxLength: {
                value: 150,
                message: "Máximo 150 caracteres",
            },
        },
        nameOrder: {
            // required: "Nombre es requerido",
            minLength: {
                value: 3,
                message: "Mínimo 3 caracteres",
            },
            maxLength: {
                value: 150,
                message: "Máximo 150 caracteres",
            },
        },

        phoneNumberOrder: {
            // required: "Número telefónico es requerido",
            pattern: {
                value: /^(0|[0-9]\d*)(\.\d+)?$/,
                message: "El formato telefónico no es correcto",
            },
        },

        emailOrder: {
            // required: "El correo electrónico es requerido",
            pattern: {
                value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                message:
                    "El valor ingresado no coincide con el formato del correo electrónico",
            },
        },
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };
    const handleClose = () => {
        setOpen(false);
        setIsScreenLoading(false);
    };

    const PopModalError = () => {
        return (
            <div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Error
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {"---"}
                            {frontErrorMessage}
                            {saleOrders?.payload?.code ||
                            saleOrders?.payload?.response?.data?.detail ||
                            saleOrderDetail?.payload?.response?.data?.detail ||
                            saleOrderDetail?.payload?.code ? (
                                <>
                                    <p>
                                        {
                                            saleOrders?.payload?.response?.data
                                                ?.detail
                                        }
                                    </p>
                                    <p>
                                        {saleOrders?.payload?.message
                                            ? "[" +
                                              saleOrders?.payload?.message +
                                              "]"
                                            : ""}
                                    </p>
                                    <p>
                                        {
                                            saleOrderDetail?.payload?.response
                                                ?.data?.detail
                                        }
                                    </p>
                                    <p>
                                        {saleOrderDetail?.payload?.message
                                            ? "[" +
                                              saleOrderDetail?.payload
                                                  ?.message +
                                              "]"
                                            : ""}
                                    </p>
                                </>
                            ) : null}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        );
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={6} md={2} className={"filter-container"}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"es"}
                            >
                                <DatePicker
                                    value={dateValue}
                                    onChange={(newValue) => {
                                        setDateValue(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="No. de orden"
                                placeholder="No. de orden"
                                id="numberOrder"
                                name="numberOrder"
                                {...register(
                                    "numberOrder",
                                    registerOptions?.numberOrder
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            />

                            {errors?.numberOrder && (
                                <span>
                                    <p className="required-incidence">
                                        {" "}
                                        {errors?.numberOrder?.message}
                                    </p>
                                </span>
                            )}
                        </Grid>
                        <Grid item xs={6} md={2} className={"filter-container"}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Nombre"
                                placeholder="Nombre"
                                id="nameOrder"
                                name="nameOrder"
                                {...register(
                                    "nameOrder",
                                    registerOptions?.nameOrder
                                )}
                                onChange={(e) => inputChange(e)}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            />

                            {errors?.nameOrder && (
                                <span>
                                    <p className="required-incidence">
                                        {" "}
                                        {errors?.nameOrder?.message}
                                    </p>
                                </span>
                            )}
                        </Grid>

                        <Grid item xs={6} md={2} className={"filter-container"}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Teléfono"
                                placeholder="Teléfono"
                                id="phoneNumberOrder"
                                name="phoneNumberOrder"
                                {...register(
                                    "phoneNumberOrder",
                                    registerOptions?.phoneNumberOrder
                                )}
                                onChange={(e) => inputChange(e)}
                                // InputLabelProps={{
                                //     shrink: nameShrink,
                                // }}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            />

                            {errors?.phoneNumberOrder && (
                                <span>
                                    <p className="required-incidence">
                                        {" "}
                                        {errors?.phoneNumberOrder?.message}
                                    </p>
                                </span>
                            )}
                        </Grid>
                        <Grid item xs={6} md={2} className={"filter-container"}>
                            <TextField
                                fullWidth
                                type="text"
                                variant="outlined"
                                label="Correo electrónico"
                                placeholder="Correo electrónico"
                                id="emailOrder"
                                name="emailOrder"
                                {...register(
                                    "emailOrder",
                                    registerOptions?.emailOrder
                                )}
                                onChange={(e) => inputChange(e)}
                                // InputLabelProps={{
                                //     shrink: nameShrink,
                                // }}
                                className="m-tb-20 form-control data-input"
                                sx={{
                                    mb: 2,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#6C63FF",
                                        },
                                    },
                                }}
                            />

                            {errors?.emailOrder && (
                                <span>
                                    <p className="required-incidence">
                                        {" "}
                                        {errors?.emailOrder?.message}
                                    </p>
                                </span>
                            )}
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                className="m-0 h-55 small-button"
                                type="submit"
                            >
                                <SearchIcon className="svg" />
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <div style={{ height: "500px", width: "100%", overflow: "auto" }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    autoHeight={true}
                    pagination
                    pageSize={datagridPageSize}
                    rowCount={rowCount}
                    paginationMode="server"
                    onPageChange={(newPage) => changePage(newPage)}
                    onPageSizeChange={(newPageSize) =>
                        changePageSize(newPageSize)
                    }
                    rowsPerPageOptions={[50, 75, 100]}
                    page={datagridPage}
                    loading={loadingDatagrid}
                    disableColumnMenu
                    disableSelectionOnClick={true}
                    checkboxSelection={false}
                    overflow={true}
                    sx={{
                        "& .MuiDataGrid-columnHeaders": {
                            position: "sticky",
                            zIndex: "999",
                            background: "white",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            marginTop: "0!important",
                        },

                        "& .MuiDataGrid-main": {
                            overflow: "hidden",
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                            width: "0.4em",
                        },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track":
                            {
                                background: "#f1f1f1",
                            },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb":
                            {
                                backgroundColor: "#E7DEE7",
                            },
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover":
                            {
                                background: "#DFD4DF",
                            },
                    }}
                    components={{
                        // Toolbar: GridToolbar,
                        NoRowsOverlay: CustomNoRowsOverlay,
                        NoResultsOverlay: CustomNoRowsOverlay,
                        LoadingOverlay: CustomLinearProgress,
                    }}
                />
            </div>
            <PopModalError />
            <ModalDetailsInfo
                setOpenModal={setModalOpenDetailOrder}
                openModal={modalOpenDetailOrder}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    saleOrders: state?.saleOrders,
    saleOrderDetail: state?.saleOrderDetail,
});

const mapDispatchToProps = {
    getSaleOrdersAction,
    cleanSaleOrdersAction,
    getSaleOrderDetailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDatagrid);
