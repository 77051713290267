import React, { useState, useEffect, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Box, Button, Collapse, Alert, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { lockImei } from "_models/redux/request-lock-imei/action";
import { getEvents } from "_models/redux/events/action";
import { cleanLockImei } from "_models/redux/request-lock-imei/clean";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";

import { LoadersContext } from "_models/context/app";

const LockImeiContainer = (props) => {
    const { ticketId, imeiNumber } = props;

    const [modalOpenLockImei, setModalOpenLockImei] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [errorMssge, setErrorMssge] = useState("");

    const [severityAlert, setSeverityAlert] = useState("info");

    const [alert, setAlert] = useState(false);

    const loadersContext = useContext(LoadersContext);
    const { isScreenLoading, setIsScreenLoading } = loadersContext || {};

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {}, []);

    useEffect(() => {
        setIsLoading(false);
        const { lockImei_ } = props.lockImei_ || {};
        const imei = lockImei_.imei || undefined;
        if (lockImei_.length != 0) {
            if (imei === imeiNumber) {
                setSeverityAlert("success");
                setErrorMssge("Bloqueo de IMEI se realizó satisfactoriamente");
                setAlert(true);
            }
        }
    }, [props.lockImei_]);

    const handleLockImei = () => {
        setIsLoading(true);
        props.lockImei({
            ticket_id: ticketId,
            imei: imeiNumber,
        });
    };

    const closePopup = () => {
        setModalOpenLockImei(false);
        props.cleanLockImei();
        setAlert(false);
    };

    function ModalLockImei({ setOpenModal }) {
        return (
            <Grid container className="modalBackground">
                <Grid container xs={4} className="modalContainer">
                    <Grid container>
                        <Grid
                            item
                            xs={6}
                            className="title"
                            direction={"column"}
                        >
                            <h3>Bloqueo de IMEI</h3>
                        </Grid>
                        <Grid item xs={6} className="titleCloseBtn">
                            <button onClick={closePopup}>
                                <CloseIcon />
                            </button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <h4>¿Está seguro de bloquear IMEI?</h4>
                    </Grid>

                    <Grid item xs={12} className="body">
                        <TextField
                            variant="standard"
                            disabled
                            fullWidth
                            type="text"
                            className="form-control"
                            placeholder={imeiNumber}
                            name="imeiNumber"
                            value={imeiNumber}
                        />
                    </Grid>
                    <Grid item xs={12} paddingTop={2}>
                        <Collapse in={alert}>
                            <Alert
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={closePopup}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                                severity={severityAlert}
                            >
                                {errorMssge}
                            </Alert>
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} className="footer">
                        <button onClick={closePopup} id="cancelBtn">
                            Cancelar
                        </button>

                        <LoadingButton
                            size="small"
                            onClick={handleLockImei}
                            endIcon={<SendIcon />}
                            loading={isLoading}
                            loadingPosition="end"
                            variant="contained"
                        >
                            Bloquear
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return (
        <div>
            <LoadingButton
                fullWidth
                size="small"
                onClick={() => {
                    setModalOpenLockImei(true);
                }}
                variant="outlined"
                className="button"
            >
                <h4>Bloquear IMEI</h4>
            </LoadingButton>

            {modalOpenLockImei && (
                <ModalLockImei
                    modalOpen={modalOpenLockImei}
                    setOpenModal={setModalOpenLockImei}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    lockImei_: state.lockImei,
    events: state.events,
});
const mapDispatchToProps = {
    lockImei,
    getEvents,
    cleanLockImei,
};

export default connect(mapStateToProps, mapDispatchToProps)(LockImeiContainer);
