import React, { useEffect, useState } from "react";
import moment from "moment";

/**
 * Helper para calcular el tiempo transcurrido.
 *
 * @param {number} created - Marca de tiempo (en formato Unix) desde la cual se calculará el tiempo transcurrido.
 * @returns {string} - Tiempo transcurrido formateado en días, horas y minutos.
 */
export const useTimePassed = (created) => {
    const [timePassed, setTimePassed] = useState("");

    useEffect(() => {
        // Configurar un intervalo para actualizar el tiempo transcurrido cada segundo
        const interval = setInterval(() => {
            // Calcular el tiempo de cuando se creo y el tiempo actual
            const createdMoment = moment.unix(created);
            const duration = moment.duration(moment().diff(createdMoment));
            const days = duration.asDays();
            const hours = duration.hours();
            const minutes = duration.minutes();

            // Formatear el tiempo transcurrido
            const formattedTime = `${Math.floor(
                days
            )} días, ${hours} horas, ${minutes} minutos`;

            // Actualizar el tiempo transcurrido formateado
            setTimePassed(formattedTime);
        }, 1000);

        // Limpiar el intervalo cuando el componente se desmonte o cuando la marca de tiempo cambie
        return () => clearInterval(interval);
    }, [created]);

    // Devolver el tiempo transcurrido formateado
    return timePassed;
};
