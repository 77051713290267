import { useContext } from "react";

import { LayoutContext } from "../data/layoutContext";

import { getLoginDataHelper } from "_controllers/helpers/getLoginData";

export const useLayoutMenuList = () => {
    const { userPermissions } = getLoginDataHelper();

    const layoutContext = useContext(LayoutContext) || {};
    const { layoutVariables, methods } = layoutContext || {};

    const {
        activeStyle,

        open,
        openMenuSupport,
        openMenuSales,
        openMenuMarketing,
        openMenuManager,
        openSaleTransfer,

        openMenuReportsSales,
        openMenuReportsLines,

        openMenuAccounting,
        openShipping,
    } = layoutVariables || {};

    const { layoutMethods } = methods || {};

    const {
        handleClickShipping,
        handleClickSupport,
        handleClickMarketing,
        handleClickManager,
        handleOpenSaleTransfer,

        handleClickReportsSales,
        handleClickReportsLines,

        handleClickSales,
        handleClickMenuAccounting,
    } = layoutMethods || {};

    return {
        activeStyle,

        open,
        openMenuSupport,
        openMenuSales,
        openMenuMarketing,
        openMenuManager,
        openSaleTransfer,

        openMenuReportsSales,
        openMenuReportsLines,

        openMenuAccounting,
        openShipping,

        handleClickShipping,
        handleClickSupport,
        handleClickMarketing,
        handleClickManager,
        handleOpenSaleTransfer,

        handleClickReportsSales,
        handleClickReportsLines,

        handleClickSales,
        handleClickMenuAccounting,
        userPermissions,
    };
};
