import ContainerModules from "./container-modules";
import OffersHeader from "./offers-header";

const OffersWidget = ({ directNumber, ticketId }) => {
    return (
        <>
            <OffersHeader />
            <ContainerModules directNumber={directNumber} ticketId={ticketId} />
        </>
    );
};

export default OffersWidget;
