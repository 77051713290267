import React from "react";
import { TextField, MenuItem, Grid } from "@mui/material";

const SelectField = ({
    register,
    registerOptions,
    value,
    onChange,
    options,
    label,
    name,
    shrink,
    errors,
}) => (
    <>
        <TextField
            {...register(name, registerOptions?.[name])}
            select
            fullWidth
            variant="outlined"
            placeholder={label}
            id={name}
            name={name}
            value={value}
            defaultValue={0}
            onChange={onChange}
            InputLabelProps={{ shrink }}
            className="m-tb-20 form-control data-input"
            sx={{
                mb: 2,
                "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                        borderColor: "#6C63FF",
                    },
                },
            }}
        >
            <MenuItem key={0} value={0}>
                {`Seleccione un ${label.toLowerCase()}`}
            </MenuItem>
            {options?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
        {errors && (
            <Grid item xs={12} className="lead-form-input-error">
                <span>
                    {errors[name] && (
                        <p className="required-incidence m-0">
                            {errors[name].message}
                        </p>
                    )}
                </span>
            </Grid>
        )}
    </>
);

export default SelectField;
