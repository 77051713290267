import { Route, Routes } from "react-router-dom";

import DashboardPage from "modules/dashoboard/page";
import ClosePage from "modules/login/close";
import SupportPage from "modules/support/page";
import TroubleshootingPage from "modules/troubleshooting/page";
// import ErrorPage from "modules/error/page";
import RedirectTo from "modules/login/redirect-to";
import ClientTicketsPage from "modules/support/my-tickets/flow/clientTickets2/page";
import DetailTicketsPage from "modules/support/my-tickets/flow/detailTickets3/page";
import MyTicketsPage from "modules/support/my-tickets/page";

import MySalesDetailsPage from "modules/my-sales/page";
import LeadDetailPage from "modules/my-sales/submodules/leads/detail/page";
import LeadsPage from "modules/my-sales/submodules/leads/page";

import ChartsPage from "modules/samples/charts/page";
import FiltersPage from "modules/samples/filters/page";
import SamplesPage from "modules/samples/page";

import LeadsByCampaignChannelPage from "modules/dashoboard/campaignChannel/page";
import MarketingKPIsPage from "modules/dashoboard/kpis/marketing/page";
import LeadsByCampaingPage from "modules/dashoboard/leadsByCampaing/page";
import ManagerKPIsPage from "modules/dashoboard/kpis/manager/activations/page";
import ManagerPrepaidKPIsPage from "modules/dashoboard/kpis/manager/prepaid/page";
import HelpdeskPage from "modules/marketing/submodules/helpdesk/list/page";
import HelpdeskContainer from "modules/marketing/submodules/helpdesk/_container";
import ShippingPendingContainer from "modules/shipping/_pendingShipping/_container";

import ShippingSearchContainer from "modules/shipping/_searchShipping/_container";
import ShippingDetailComponent from "modules/shipping/_detailShipping/_component";
import OrdersPage from "modules/my-sales/submodules/orders/page";
import HelpDeskDetailPage from "modules/marketing/submodules/helpdesk/_sections/detail/components/helpdesk-detail-component";
import TicketsPage from "modules/support/tickets/page";
import SaleDashboardPage from "modules/my-sales/submodules/dashboard/page";
import LeadsExecutivePage from "modules/my-sales/page-executive";
import MySalesExecutiveDetailsPage from "modules/my-sales/components/page";

import ManagerSalesMonthlyKPIsPage from "modules/dashoboard/kpis/manager/sales/monthly/page";
import ManagerSalesWeeklyKPIsPage from "modules/dashoboard/kpis/manager/sales/weekly/page";
import DailySalesManagerPage from "modules/dashoboard/kpis/manager/sales/daily/page";
import LinksPage from "modules/my-sales/submodules/links/page";
import ManagerSalesDailyKPIsPage from "modules/dashoboard/kpis/manager/sales/daily/page";
import ManagerPurchaseReportKPIsPage from "modules/dashoboard/kpis/manager/purchase-report/page";
import PageTransferSale from "modules/my-sales/submodules/transfer-sales/page";
import PageListSaleTransfer from "modules/my-sales/submodules/list-transfer-sales/Page";
import PageDetailTransferSale from "modules/my-sales/submodules/detail-transfer-sales/page";

import GiftOrdersPage from "modules/support-gift-orders/GiftOrdersPage";
import PageNetPay from "modules/transactions-netpay/page";

const SubnavigationRoutes = () => {
    return (
        <Routes className="subnavigation-routes">
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/kpis/marketing" element={<MarketingKPIsPage />} />
            <Route
                path="/kpis/marketing/status-lead"
                element={<LeadsByCampaignChannelPage />}
            />
            <Route
                path="/dashboard/manager/activations"
                element={<ManagerKPIsPage />}
            />
            <Route
                path="/dashboard/manager/prepaid"
                element={<ManagerPrepaidKPIsPage />}
            />
            <Route
                path="/dashboard/manager/sales/monthly"
                element={<ManagerSalesMonthlyKPIsPage />}
            />
            <Route
                path="/dashboard/manager/sales/reports-incidence"
                element={<ManagerPurchaseReportKPIsPage />}
            />
            <Route
                path="/dashboard/manager/sales/weekly"
                element={<ManagerSalesWeeklyKPIsPage />}
            />
            <Route
                path="/dashboard/manager/sales/daily"
                element={<DailySalesManagerPage />}
            />

            <Route
                path="/dashboard/leads-by-campaing"
                element={<LeadsByCampaingPage />}
            />
            <Route
                path="/dashboard/campaign-channel"
                element={<LeadsByCampaignChannelPage />}
            />

            {/* <Route path="/troubleshooting" element={<TroubleshootingPage />} /> */}
            <Route path="/support" element={<SupportPage />} />

            <Route
                path="/sales/dashboard/details"
                element={<MySalesDetailsPage />}
            />
            <Route
                path="/sales/ejecutivo/details"
                element={<MySalesExecutiveDetailsPage />}
            />
            <Route
                path="/sales/ejecutivo/transfer-sales"
                element={<PageTransferSale />}
            />
            <Route
                path="/sales/ejecutivo/detail-transfer-sales/:id"
                element={<PageDetailTransferSale />}
            />
            <Route
                path="/sales/ejecutivo/list-transfer-sales"
                element={<PageListSaleTransfer />}
            />
            <Route path="/sales/dashboard" element={<SaleDashboardPage />} />
            <Route path="/sales/ejecutivo" element={<LeadsExecutivePage />} />
            <Route path="/lead-detail/:leadId" element={<LeadDetailPage />} />
            <Route path="/leads" element={<LeadsPage />} />
            <Route path="/paymentlinks" element={<LinksPage />} />
            <Route path="/helpdesk-detail/" element={<HelpDeskDetailPage />} />

            <Route path="/sales/orders" element={<OrdersPage />} />

            <Route path="/my-tickets" element={<MyTicketsPage />} />
            <Route path="/tickets" element={<TicketsPage />} />
            <Route path="/incidenciacliente" element={<ClientTicketsPage />} />

            <Route path="/tickets/:ticketId" element={<DetailTicketsPage />} />

            <Route path="/temporal" element={<SamplesPage />} />
            <Route path="/samples" element={<SamplesPage />} />

            <Route path="/samples/charts" element={<ChartsPage />} />

            <Route path="/samples/filters" element={<FiltersPage />} />

            <Route path="/close" element={<ClosePage />} />

            <Route path="/login" element={<RedirectTo />} />

            <Route path="/*" element={<DashboardPage />} />

            <Route path="/helpdesk" element={<HelpdeskPage />} />
            <Route path="/helpdesk2" element={<HelpdeskContainer />} />

            <Route path="/shipping" element={<ShippingSearchContainer />} />
            <Route
                path="/shipping/detail"
                element={<ShippingDetailComponent />}
            />
            <Route
                path="/shipping/pending"
                element={<ShippingPendingContainer />}
            />

            <Route path="/sales/gift-orders" element={<GiftOrdersPage />} />

            <Route path="transacciones/netpay" element={<PageNetPay />} />

            {/* <Route path="/*" element={<ErrorPage />} /> */}
        </Routes>
    );
};

export default SubnavigationRoutes;
