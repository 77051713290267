import { Route, Routes } from "react-router-dom";

import { Grid } from "@mui/material";

import ForgotPasswordPage from "modules/forgot-password/page";
import ErrorForgotPasswordPage from "modules/forgot-password/pages/error";
import SuccessForgotPasswordPage from "modules/forgot-password/pages/success";

import ResetPasswordPage from "modules/reset-password/page";
import ErrorResetPasswordPage from "modules/reset-password/pages/error";
import SuccessResetPasswordPage from "modules/reset-password/pages/success";
import ResetPasswordValidation from "modules/reset-password/validation";

import ErrorPage from "modules/error/page";
import LoaderPage from "modules/loader/page";
import LoginPage from "modules/login/LoginPage";

import PrivatePage from "router/pages/private";

import ClosePage from "modules/login/close";
import PrivateRoutes from "./private";
import PublicRoutes from "./public";

const NavigationRoutes = () => {
    return (
        <Grid container className="navigation-routes">
            <Routes>
                <Route
                    path="/"
                    exact
                    element={
                        <PublicRoutes>
                            {/* <TicketPage /> */}
                            <LoginPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/login"
                    element={
                        <PublicRoutes>
                            <LoginPage />
                        </PublicRoutes>
                    }
                />

                <Route
                    path="/forgot-password"
                    element={
                        <PublicRoutes>
                            <ForgotPasswordPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/forgot-password-success"
                    element={
                        <PublicRoutes>
                            <SuccessForgotPasswordPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/forgot-password-error"
                    element={
                        <PublicRoutes>
                            <ErrorForgotPasswordPage />
                        </PublicRoutes>
                    }
                />

                <Route
                    path="/reset-password-expired"
                    element={
                        <PublicRoutes>
                            <ErrorForgotPasswordPage
                                from={"password-expired"}
                            />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/reset-password-success/:token/:key"
                    element={
                        <PublicRoutes>
                            <ResetPasswordPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/reset-password/:token/:key"
                    element={
                        <PublicRoutes>
                            <ResetPasswordValidation />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/reset-password-success-page"
                    element={
                        <PublicRoutes>
                            <SuccessResetPasswordPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/reset-password-error-page"
                    element={
                        <PublicRoutes>
                            <ErrorResetPasswordPage />
                        </PublicRoutes>
                    }
                />

                <Route
                    path="/notfound"
                    element={
                        <PublicRoutes>
                            <ErrorPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/error"
                    element={
                        <PublicRoutes>
                            <ErrorPage />
                        </PublicRoutes>
                    }
                />
                <Route
                    path="/loader"
                    element={
                        <PublicRoutes>
                            <LoaderPage />
                        </PublicRoutes>
                    }
                />

                <Route path="/close" element={<ClosePage />} />

                <Route
                    path="/*"
                    element={
                        <PrivateRoutes>
                            <PrivatePage />
                        </PrivateRoutes>
                    }
                />
            </Routes>
        </Grid>
    );
};

export default NavigationRoutes;
