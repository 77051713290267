import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import TicketsComponent from "./component";

const TicketContainer = (props) => {
    return (
        <div>
            <TicketsComponent />
        </div>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({});

/** Redux map dispatch to props **/
const mapDispatchToProps = {};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(TicketContainer);
