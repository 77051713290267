import React, { useEffect, useState } from "react";
import { Grid, Avatar } from "@mui/material";
import { getCustomerProfile } from "_models/redux/customer-profile/action";
import { connect } from "react-redux";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../clientTickets2/modal/new-inicidence";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";

const CustomerProfileHeaderClient = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    // const [simTypeId, setSimTypeId] = useState(0);

    const { customerProfileReducer } = props;
    const { customerProfile } = customerProfileReducer || {};
    const statusLine = customerProfile?.status?.subStatus;
    const { profile } = customerProfile || {};
    const {
        name,
        last_name,
        mothers_last_name,
        carrier_name,
        imsi,
        imei,
        icc_number,
        activated_at,
    } = profile || {};

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (profile != undefined) setIsLoading(false);
    }, [profile]);

    let location = useLocation();
    let navigate = useNavigate();

    const { state } = location || {};
    const { directNumber } = state || {};

    useEffect(() => {
        navigate(<Modal />, {
            state: { name, last_name, mothers_last_name, directNumber },
        });
    }, [name]);

    useEffect(() => {
        if (directNumber != undefined) {
            props.getCustomerProfile({
                directNumber: directNumber,
            });
        }
    }, []);

    const labelSimType = () => {
        switch (
            customerProfileReducer?.customerProfile?.profile
                ?.mobile_subscriber_sim_type_id
        ) {
            case 1:
                return "SIM";

            case 2:
                return "eSIM";

            case 3:
                return "MiFi";

            case 4:
                return "IoT";
            default:
                return "N/A";
        }
    };

    const seeLineStatus = () => {
        switch (statusLine?.toUpperCase()) {
            case "ACTIVE":
                return <p className="line-status">Línea activa</p>;
            case "IDLE":
                return <p className="line-status-Idle">Inactivo</p>;
            case "AOV":
                return (
                    <p className="line-status">
                        Activo con producto facturable
                    </p>
                );
            case "ACT":
                return (
                    <p className="line-status">
                        Activo sin producto facturable
                    </p>
                );
            case "SUP":
                return <p className="line-status-Suspendida">Suspendido</p>;
            case "BAR":
                return <p className="line-status-Barring">Barring</p>;

            case "PRE":
                return (
                    <p className="line-status-Predeactivate">Predesactivo</p>
                );

            case "DAC":
                return <p className="line-status-Suspendida">Desactivado</p>;

            case "PREACT":
                return <p className="line-status-Predeactivate">Pre activo </p>;

            default:
                return (
                    <h1 className="line-status">{statusLine?.toUpperCase()}</h1>
                );
        }
    };

    return (
        <Grid container className="m-t-20">
            <Grid item xs={12} className="ticket-data">
                <Grid container className="basic">
                    <Grid item xs={12}>
                        <Grid container spacing={7}>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                flexDirection={"row"}
                            >
                                <Grid item xs={1} mr={1}>
                                    <Avatar
                                        sx={{
                                            bgcolor: "#441556",
                                            width: 26,
                                            height: 26,
                                        }}
                                    >
                                        <PersonIcon
                                            sx={{
                                                fontSize: 17,
                                            }}
                                        />
                                    </Avatar>
                                </Grid>
                                <Grid item xs={11}>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <h4>
                                                {isLoading ? (
                                                    <CircularProgress />
                                                ) : name &&
                                                  last_name &&
                                                  mothers_last_name !=
                                                      "undefined" ? (
                                                    name +
                                                    " " +
                                                    last_name +
                                                    " " +
                                                    mothers_last_name
                                                ) : null}
                                            </h4>
                                            <p>
                                                {isLoading ? (
                                                    <CircularProgress />
                                                ) : (
                                                    directNumber
                                                )}
                                            </p>
                                        </Grid>
                                        <Grid item xs={2} className="left-data">
                                            <Grid item xs={12}>
                                                <EditIcon />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                className={"type-label"}
                                                id={labelSimType()}
                                            >
                                                {labelSimType()}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="ticket-data">
                <Grid container className="secondary">
                    <Grid item xs={6}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <h4> {carrier_name}</h4>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                    >
                        <p className="status">{seeLineStatus()}</p>
                    </Grid>
                    <Grid item xs={6}>
                        {isLoading ? (
                            <CircularProgress />
                        ) : (
                            <p className="data">
                                {" Se activó el "}
                                {isLoading
                                    ? moment
                                          .unix(activated_at)
                                          .format("DD/MM/YYYY")
                                    : "N/A"}
                            </p>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                    >
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </Grid>
                </Grid>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <hr color="#e9e9e9" />
                    <Grid container className="numeric m-t-10">
                        <Grid item xs={3}>
                            <h4>IMSI</h4>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            {isLoading ? <CircularProgress /> : <p> {imsi}</p>}
                        </Grid>
                    </Grid>

                    <Grid container className="numeric">
                        <Grid item xs={3}>
                            <h4>IMEI</h4>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            {isLoading ? <CircularProgress /> : <p> {imei}</p>}
                        </Grid>
                    </Grid>
                    <Grid container className="numeric">
                        <Grid item xs={3}>
                            <h4>ICC</h4>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            display={"flex"}
                            justifyContent={"flex-end"}
                        >
                            {isLoading ? (
                                <CircularProgress />
                            ) : (
                                <p> {icc_number}</p>
                            )}
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
};
const mapStateToProps = (state) => ({
    customerProfileReducer: state.customerProfile,
    ticketById: state.ticketById,
    incidence: state.incidence,
});
const mapDispatchToProps = {
    getCustomerProfile,
    getTicketById,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerProfileHeaderClient);
