import { useState } from "react";
import { useTheme } from "@emotion/react";

export const useLayoutVariables = () => {
    const theme = useTheme();

    // This styling will be applied to a <NavLink> when the
    // route that it links to is currently selected.
    let activeStyle = {
        textDecoration: "underline",
    };
    let activeClassName = "underline";

    const [open, setOpen] = useState(false);
    const [openMenuSupport, setOpenMenuSupport] = useState(false);
    const [openMenuSales, setOpenMenuSales] = useState(false);
    const [openMenuMarketing, setOpenMenuMarketing] = useState(false);
    const [openMenuManager, setOpenMenuManager] = useState(false);
    const [openMenuReportsSales, setOpenMenuReportsSales] = useState(false);
    const [openMenuReportsLines, setOpenMenuReportsLines] = useState(false);
    const [openMenuAccounting, setOpenMenuAccounting] = useState(false);
    const [openShipping, setOpenShipping] = useState(false);
    const [openSaleTransfer, setOpenSaleTransfer] = useState(false);

    return {
        layoutVariables: {
            //Styles theme:
            theme,

            //NavLink active style:
            activeStyle,
            activeClassName,

            //State variables:
            open,
            setOpen,
            openMenuSupport,
            setOpenMenuSupport,
            openMenuSales,
            setOpenMenuSales,
            openMenuMarketing,
            setOpenMenuMarketing,
            openMenuManager,
            setOpenMenuManager,
            openMenuReportsSales,
            setOpenMenuReportsSales,
            openMenuReportsLines,
            setOpenMenuReportsLines,
            openMenuAccounting,
            setOpenMenuAccounting,
            openShipping,
            setOpenShipping,
            openSaleTransfer,
            setOpenSaleTransfer,
        },
    };
};
