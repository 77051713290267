import { USERS, USERS_ERROR } from "_models/redux/types";

const initialState = {
    response: [],
    error: undefined,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USERS:
            return {
                ...state,
                response: action.payload,
                loading: true,
            };

        case USERS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
