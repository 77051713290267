import { useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import moment from "moment";
import "moment/locale/es";

import { Grid } from "@mui/material";
import { getCampaignChannelAction } from "_models/redux/campaign-channel/action";
import { cleanGetCampaignChannelAction } from "_models/redux/campaign-channel/clean-action";
import { getLandingList } from "_models/redux/get-landing_list/action";

import { getLeadsByCampaingAction } from "_models/redux/leads-by-campaing/action";

import { getLeadStatusList } from "_models/redux/get-lead-status-list/action";

import LeadsByCampaingCharts from "./charts";

import LeadsByCampaingFilters from "../filters/filters";

import StatusLeadCustomFilters from "../filters/statusLeadFilter";

import { LoadersContext } from "_models/context/app";

const LeadsByCampaignChannelPage = (props) => {
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    const {
        campaignChannel,
        cleanGetCampaignChannelAction,
        getCampaignChannelAction,
        getLeadStatusList,
        getLandingList,
        leadStatusListObj,
        getLeadsByCampaingAction,
        leadsByCampaing,
        landingListObj,
    } = props || {};

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    useEffect(() => {
        // getCampaignChannelAction();
        getLandingList();
        getLeadStatusList();
    }, []);

    const [filters, setFilters] = useState({});

    const [fromTime, setFromTime] = useState(
        moment().startOf("month").format()
    );
    const [toTime, setToTime] = useState(moment().format());

    const [leadStatus, setLeadStatus] = useState(0);
    const [landingId, setLandingId] = useState(0);

    const [displayDatepickers, setDisplayDatepickers] = useState(
        "displayDatepickers hidden"
    );

    useEffect(() => {
        return () => {
            setIsScreenLoading(false);
        };
    }, []);

    useEffect(() => {
        const { loaded, method, payload, state, status } =
            campaignChannel || {};

        const { rows } = payload || {};

        if (loaded) {
            setIsScreenLoading(false);
        }
    }, [campaignChannel]);

    useEffect(() => {
        // Format the dates in "YYYY-MM-DD" format
        const formattedFromTime = moment(fromTime).format("YYYY-MM-DD");
        const formattedToTime = moment(toTime).format("YYYY-MM-DD");

        // Set the formatted dates back into the state
        setFromTime(formattedFromTime);
        setToTime(formattedToTime);

        // getLoadedMethods();
    }, [fromTime, toTime]);

    const getLoadedMethods = () => {
        getLeadsByCampaingMethod();
    };

    const getLeadsByCampaingMethod = () => {
        getCampaignChannelAction({
            from_time: fromTime,
            to_time: toTime,
            lead_status_id: leadStatus,
            landing_id: landingId,
        });
    };

    const loadData = (e) => {
        if (landingId != 0) {
            setIsScreenLoading(true);
            getLoadedMethods();
        }
    };

    const parentProperties = {
        form: {
            watch,
            reset,
            control,
            setValue,
            getValues,
            register,
            handleSubmit,
            setError,
            errors,
        },
        redux: {
            campaignChannel,
            cleanGetCampaignChannelAction,
            getCampaignChannelAction,
            getLandingList,
            getLeadStatusList,
            leadStatusListObj,

            getLeadsByCampaingAction,
            leadsByCampaing,
            landingListObj,
        },
        states: {
            filters,
            setFilters,
            fromTime,
            setFromTime,
            toTime,
            setToTime,

            leadStatus,
            setLeadStatus,
            landingId,
            setLandingId,

            setIsScreenLoading,

            displayDatepickers,
            setDisplayDatepickers,
        },
        methods: {
            loadData,
        },
    };

    useEffect(() => {
        setIsScreenLoading(false);
    }, [campaignChannel]);

    return (
        <Grid container className="kpi-container" spacing={3}>
            <Grid item xs={12} className="m-b-15">
                <h2>Estatus de leads</h2>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={5}>
                <LeadsByCampaingFilters parentProperties={parentProperties} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={5}>
                <StatusLeadCustomFilters parentProperties={parentProperties} />
            </Grid>

            <Grid item xs={2}>
                <button
                    type="button"
                    variant="contained"
                    onClick={loadData}
                    className="m-0 h-55"
                >
                    Buscar
                </button>
            </Grid>

            <Grid item xs={12}>
                <LeadsByCampaingCharts parentProperties={parentProperties} />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    campaignChannel: state?.campaignChannel,
    leadStatusListObj: state?.leadStatusListObj,
    landingListObj: state.landingListObj,
    leadsByCampaing: state.leadsByCampaing,
});

const mapDispatchToProps = {
    getCampaignChannelAction,
    cleanGetCampaignChannelAction,
    getLeadStatusList,
    getLandingList,
    getLeadsByCampaingAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeadsByCampaignChannelPage);
