import { SUSPEND_LINE, SUSPEND_LINE_ERROR } from "_models/redux/types";

const initialState = {
    actionSuspendLine: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SUSPEND_LINE:
            return {
                ...state,
                actionSuspendLine: action.payload,
                loading: true,
            };

        case SUSPEND_LINE_ERROR:
            return {
                ...state,
                actionSuspendLine: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
