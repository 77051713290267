import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CustomDialog from "widgets/component/CustomDialog";
import MenuImeiInfoWidget from "./menu_imei_info_widget";
import { CircularProgress, Typography } from "@mui/material";

import { consultingChCheckImei } from "_models/redux/consulting-imei/action";
import { cleanConsultingCheckImeiAction } from "_models/redux/consulting-imei/clean";

import { LayoutContext } from "modules/layout/data/layoutContext";

const MenuCheckImeiWidget = (props) => {
    const {
        parentProps,
        userPermissions,
        ConsultingcheckImeiObj,
        consultingChCheckImei,
        cleanConsultingCheckImeiAction,
    } = props || {};
    // const { ConsultingcheckImeiObj, consultingChCheckImei } = parentProps || {};

    const { payload } = ConsultingcheckImeiObj || {};
    const { response } = payload || {};
    const { status, data } = response || {};
    const { detail } = data || {};

    const [detailError, setDetailError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [searchText, setSearchText] = useState("");

    const handleSearchClick = async () => {
        if (searchText.trim() !== "") {
            try {
                setIsLoading(true);
                const request = await consultingChCheckImei(searchText);

                if (request) {
                    const { status, data } = request.response || {};

                    if (status > 300) {
                        setDetailError(data.detail);
                    } else {
                        setDetailError(null);
                    }
                }
            } catch (error) {
                console.error("Error al consultar IMEI:", error);
            } finally {
                setSearchText(""); // Limpiar el input después de la búsqueda
                setIsDialogOpen(true);
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        // Este efecto se ejecutará cada vez que cambie el payload
        if (response) {
            if (status > 300) {
                setDetailError(detail);
            } else {
                setDetailError(null);
            }
            setIsDialogOpen(true);
            setIsLoading(false);
        }
    }, [payload]);

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const layoutContext = useContext(LayoutContext) || {};
    const { layoutVariables, methods } = layoutContext || {};
    const { open } = layoutVariables || {};
    const { layoutMethods } = methods || {};
    const { handleDrawerOpen } = layoutMethods || {};

    const isInputValid = /^\d{14,}$/.test(searchText);

    return (
        <>
            {open ? (
                <Paper
                    component="form"
                    sx={{
                        p: "2px 4px",
                        m: "10px",
                        display: "flex",
                        alignItems: "center",
                        width: 215,
                    }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Consultar IMEI..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        inputProps={{
                            minLength: 14,
                            pattern: "\\d*",
                        }}
                    />
                    <IconButton
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={handleSearchClick}
                        disabled={!isInputValid}
                        size="large"
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            ) : !open ? (
                <>
                    <IconButton
                        type="button"
                        sx={{ p: "10px", width: "100%" }}
                        aria-label="search"
                        onClick={handleDrawerOpen}
                        size="large"
                    >
                        <SearchIcon />
                    </IconButton>
                </>
            ) : null}
            {isInputValid || searchText === "" ? null : (
                <Typography color="error" variant="caption" sx={{ ml: 2 }}>
                    Se requieren al menos 14 caracteres numéricos.
                </Typography>
            )}
            {isLoading && (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1000,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            {!isLoading && (
                <CustomDialog
                    title="Compatibilidad IMEI"
                    content={
                        status > 300 ? (
                            <MenuImeiInfoWidget dataError={detailError} />
                        ) : (
                            <MenuImeiInfoWidget data={payload} />
                        )
                    }
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    fullWidth
                    maxWidth="sm"
                />
            )}
        </>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    ConsultingcheckImeiObj: state.ConsultingcheckImei,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    consultingChCheckImei,
    cleanConsultingCheckImeiAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuCheckImeiWidget);
