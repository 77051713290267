import {
    BARRING_ACTION,
    BARRING_ACTION_ERROR,
    CLEAN_BARRING_ACTION,
    CLEAN_BARRING_ACTION_ERROR,
} from "_models/redux/types";

const initialState = {
    postUpdateBarringObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case BARRING_ACTION:
        case CLEAN_BARRING_ACTION:
            return {
                ...state,
                postUpdateBarringObj: action.payload,
                loading: true,
            };

        case BARRING_ACTION_ERROR:
        case CLEAN_BARRING_ACTION_ERROR:
            return {
                ...state,
                postUpdateBarringObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
