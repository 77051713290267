import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import TabsTicket from "./tabs";
import CustomerProfileHeaderClient from "../userTickets1/widgets/customer-profile-header-client";
import ActionsBar from "./widgets/actions-bar";
import HeaderTitle from "./widgets/title-header";
import { useLocation, useParams } from "react-router-dom";
import { getTicketById } from "_models/redux/get-ticket-by-id/action";
import TicketInfo from "./widgets/ticket-info";

const DetailTicketsPage = (props) => {
    let location = useLocation();
    // const test = useLoaderData();

    // const directNumber = undefined;
    // const ticketId = location.state.ticketId;

    let params = useParams(props);

    const titleTicket = props?.ticketById?.response?.title;
    const [ticketById, setTicketById] = useState();

    const [directNumberById, setDirectNumberByID] = useState();
    const [nameById, setNameById] = useState();
    const [lastNameById, setLastNameById] = useState();
    const [mothersLastNameById, setMothersLastNameById] = useState();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTicketById(params.ticketId);

        props.getTicketById({ ticketId: params.ticketId });
    }, []);

    useEffect(() => {
        const response = props.ticketById.response || undefined;

        if (typeof response != "undefined") {
            setDirectNumberByID(response.direct_number);
            setTicketById(response.id);
            setNameById(response.created_by_user_name);
            setLastNameById(response.created_by_user_last_name);
            setMothersLastNameById(response.created_by_user_mothers_last_name);
        }
    }, [props.ticketById]);

    return (
        <Grid container>
            <Grid item xs={9} className="tabs-container">
                <HeaderTitle
                    ticketById={props?.ticketById}
                    directNumber={directNumberById}
                    ticketId={ticketById}
                    titleTicket={titleTicket}
                />

                <TabsTicket />
            </Grid>
            <Grid item xs={3} className="ticket-bar">
                <TicketInfo ticketId={ticketById} />
                <CustomerProfileHeaderClient ticketId={ticketById} />
                <ActionsBar />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    tickets: state.tickets,
    ticketById: state.ticketById,
});

const mapDispatchToProps = {
    getTicketById,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailTicketsPage);
