import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import ModalGeneric from "./popup";
import { ChannelLeadAction } from "_models/redux/channel-lead/action";
import { connect } from "react-redux";
import { CreateLead } from "_models/redux/create_lead/action";
import { cleanCreateLead } from "_models/redux/create_lead/clean";
import { cleanChannelLead } from "_models/redux/channel-lead/clean";
import NewAssistedLead from "./new-assisted-lead";

const LeadsHeader = (props) => {
    const {
        channelCreatedLead,
        CreateLead,
        createLeadObj,
        cleanCreateLead,
        cleanChannelLead,
    } = props || {};
    const { payload } = channelCreatedLead || {};
    const { channels } = payload || {};

    const [modalOpen, setModalOpen] = useState(false);
    const [dropdownOptionsChannels, setDropdownOptionsChannels] = useState("");
    const [selectChannel, setSelectChannel] = useState("");

    useEffect(() => {
        if (modalOpen) {
            props.ChannelLeadAction();
        }
    }, [modalOpen]);

    useEffect(() => {
        setDropdownOptionsChannels(channels);
    }, [channelCreatedLead]);

    return (
        <Grid container className="page-header">
            <Grid item xs={12}>
                <Grid container direction={"row"}>
                    <Grid item xs={4} textAlign={"start"}>
                        <h3>Leads pendientes </h3>
                    </Grid>
                    <Grid item xs={4} textAlign={"end"}>
                        <NewAssistedLead />
                    </Grid>
                    <Grid item xs={4} textAlign={"end"}>
                        <button
                            className="right openModalBtn"
                            onClick={() => {
                                setModalOpen(true);
                            }}
                        >
                            <AddIcon className="svg" />
                            Nuevo Lead
                        </button>

                        {modalOpen && (
                            <ModalGeneric
                                cleanChannelLead={cleanChannelLead}
                                cleanCreateLead={cleanCreateLead}
                                modalOpen={modalOpen}
                                setModalOpen={setModalOpen}
                                createLeadObj={createLeadObj}
                                selectChannel={selectChannel}
                                setSelectChannel={setSelectChannel}
                                CreateLead={CreateLead}
                                setDropdownOptionsChannels={
                                    setDropdownOptionsChannels
                                }
                                dropdownOptionsChannels={
                                    dropdownOptionsChannels
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

// export default Modal;
const mapStateToProps = (state) => ({
    channelCreatedLead: state.channelCreatedLead,
    createLeadObj: state.createLeadObj,
});
const mapDispatchToProps = {
    ChannelLeadAction,
    CreateLead,
    cleanCreateLead,
    cleanChannelLead,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsHeader);
