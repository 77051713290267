import { Button, TextareaAutosize, Grid } from "@mui/material";

import { useCommentHook } from "../hooks/comment-hook";

const CommentSection = (props) => {
    const {
        message,
        setMessage,
        register,
        handleSubmit,
        errors,
        onSubmit,
        cancelData,
        registerOptions,
    } = useCommentHook(props);

    return (
        <Grid container className="comments">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Comentarios</h2>
                <Grid item xs={12} className="comment-border">
                    <TextareaAutosize
                        {...register("message", registerOptions.message)}
                        minRows={1}
                        aria-label=""
                        placeholder="Añadir un comentario"
                        className="comment-box"
                        value={message}
                        onChange={(event) => {
                            setMessage(event.target.value);
                        }}
                    />
                </Grid>
                <span>
                    {errors?.message && (
                        <p className="required-incidence">
                            {" "}
                            {errors.message.message}
                        </p>
                    )}
                </span>
                <Grid
                    container
                    justifyContent="flex-end"
                    spacing={2}
                    className="m-t-10"
                >
                    <Grid item>
                        <button
                            variant="text"
                            onClick={cancelData}
                            id="cancelBtn"
                        >
                            Cancelar
                        </button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            type={"submit"}
                            style={{
                                backgroundColor: "#502CAA",
                                color: "#fff",
                            }}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {/* <Grid item xs={12} className={"list-box m-t-20"}>
                {comment.map((comment, index) => {
                    let viewName = comment?.name;
                    let viewLastname = comment?.last_name;
                    const created = moment
                        .unix(comment.created)
                        .format("DD/MM/YYYY");
                    return (
                        <Grid
                            container
                            className="row"
                            key={comment.id}
                            display={"flex"}
                        >
                            {comment.format_type != "PLAIN_MESSAGE" ? (
                                <Grid
                                    item
                                    xs={10}
                                    textAlign={"start"}
                                    display={"block"}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <Avatar className={"avatar"}>
                                            {viewName?.charAt(0) +
                                                viewLastname?.charAt(0)}
                                        </Avatar>

                                        <p>
                                            <b className="user">{viewName}</b>
                                        </p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div>
                                            <p>
                                                {comment.message} {"  "}{" "}
                                                <b> (#{ticketId})</b>{" "}
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={10}
                                    textAlign={"start"}
                                    display={"block"}
                                >
                                    <p>
                                        {comment.data?.message}
                                        <b> (#{ticketId})</b>{" "}
                                    </p>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={2}
                                display={"flex"}
                                justifyContent={"flex-end"}
                                textAlign={"right"}
                                className="events-date"
                            >
                                <p>{created}</p>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid> */}
        </Grid>
    );
};

export default CommentSection;
