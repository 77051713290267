import React, { useContext, useEffect, useState } from "react";

import UserTicketsPage from "modules/support/my-tickets/flow/userTickets1/page";

const TicketsComponent = (props) => {
    useEffect(() => {}, []);

    return (
        <div>
            <UserTicketsPage />
        </div>
    );
};

export default TicketsComponent;
