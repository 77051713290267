import axiosTokenInstance from "../../../services/api/backoffice";

import { TICKET_BY_ID, TICKET_BY_ID_ERROR } from "_models/redux/types";

// Action function
export const getTicketById = (params) => async (dispatch) => {
    const { ticketId } = params;
    // Endpoint: Try & Catch
    try {
        const response = await axiosTokenInstance.get(
            `/api/support/tickets/${ticketId}`
        );

        const { data } = response || {};

        dispatch({
            type: TICKET_BY_ID,
            payload: data || {},
        });
    } catch (e) {
        dispatch({
            type: TICKET_BY_ID_ERROR,
            payload: e || {},
        });
    }
};
