import React from "react";
import { Typography, Grid, Divider, Paper } from "@mui/material";

// Función para definir estilos
const getStyles = () => ({
    sectionTitle: {
        color: "#800080",
        fontWeight: "bold",
        fontFamily: "Arial, sans-serif",
    },
    divider: {
        margin: "20px 0",
    },
});

export const DetailSection = ({ payload }) => {
    const styles = getStyles();

    return (
        <Grid container spacing={2}>
            {/* Información Personal */}
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    gutterBottom
                    style={styles.sectionTitle}
                >
                    INFORMACIÓN DEL CLIENTE
                </Typography>
            </Grid>
            {payload.lead_name && (
                <Grid item xs={12} sm={6} md={12} lg={3}>
                    <Typography variant="body1">
                        <strong>Nombre:</strong> {payload.lead_name}
                    </Typography>
                </Grid>
            )}
            {payload.lead_email && (
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Typography variant="body1">
                        <strong>Email:</strong> {payload.lead_email}
                    </Typography>
                </Grid>
            )}
            {payload.lead_contact_phone && (
                <Grid item xs={12} sm={6} md={12} lg={4}>
                    <Typography variant="body1">
                        <strong>Teléfono de Contacto:</strong>{" "}
                        {payload.lead_contact_phone}
                    </Typography>
                </Grid>
            )}
            {/* Divisor */}
            <Grid item xs={12}>
                <Divider style={styles.divider} />
            </Grid>

            {/* Domicilio */}
            {payload.shipping && (
                <>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            style={styles.sectionTitle}
                        >
                            DOMICILIO
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography variant="body1">
                            <strong>Dirección de Envío:</strong>{" "}
                            {payload.shipping.address}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography variant="body1">
                            <strong>Teléfono de Contacto para Envío:</strong>{" "}
                            {payload.shipping.contact_phone}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Typography variant="body1">
                            <strong>Municipio:</strong>{" "}
                            {payload.shipping.municipality_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography variant="body1">
                            <strong>Colonia:</strong>{" "}
                            {payload.shipping.neighborhood_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography variant="body1">
                            <strong>Código Postal de Envío:</strong>{" "}
                            {payload.shipping.shipping_zipcode}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Typography variant="body1">
                            <strong>Estado:</strong>{" "}
                            {payload.shipping.state_name}
                        </Typography>
                    </Grid>
                    {/* Divisor */}
                    <Grid item xs={12}>
                        <Divider style={styles.divider} />
                    </Grid>
                </>
            )}

            {/* Otros Detalles */}
            {payload.invoices && (
                <>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            style={styles.sectionTitle}
                        >
                            OTROS DETALLES
                        </Typography>
                    </Grid>
                    {payload.invoices.payment_method_name && (
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <Typography variant="body1">
                                <strong>Método de Pago:</strong>{" "}
                                {payload.invoices.payment_method_name}
                            </Typography>
                        </Grid>
                    )}
                    {payload.invoices.tax_regime_name && (
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <Typography variant="body1">
                                <strong>Régimen impuesto:</strong>{" "}
                                {payload.invoices.tax_regime_name}
                            </Typography>
                        </Grid>
                    )}
                    {/* Divisor */}
                    <Grid item xs={12}>
                        <Divider style={styles.divider} />
                    </Grid>
                </>
            )}
            {/* Estado de la Transacción */}
            {payload.transaction_status && (
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={styles.sectionTitle}
                    >
                        ESTADO DE LA TRANSACCIÓN
                    </Typography>
                </Grid>
            )}
            {payload.transaction_status && (
                <Grid item xs={12} sm={12} md={4} lg={12}>
                    <Typography variant="body1">
                        <strong>Estatus de la Transacción:</strong>{" "}
                        {payload.transaction_status}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};
