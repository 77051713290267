import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

export const useMonthlySalesReportTableHook = () => {
    const StyledTableCell = styled(TableCell)(({}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e9e0fd",
            color: "#715d7d",
            fontWeight: "700",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const GreenStyledTableCell = styled(TableCell)(({}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e9e0fd",
            color: "green",
            fontWeight: "700",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: "green",
        },
    }));

    const RedStyledTableCell = styled(TableCell)(({}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#e9e0fd",
            color: "red",
            fontWeight: "700",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            color: "red",
        },
    }));

    const CustomStyledTableCell1 = styled(TableCell)(({}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#f4ebff",
            color: "#715d7d",
            fontWeight: "700",
        },
        [`&.${tableCellClasses.body}`]: {
            backgroundColor: "#f7f1ff",
            color: "#715d7d",
            fontWeight: "700",
            fontSize: 14,
        },
    }));

    const CustomStyledTableCell2 = styled(TableCell)(({}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#dcd2f1",
            color: "#715d7d",
            fontWeight: "700",
        },
        [`&.${tableCellClasses.body}`]: {
            backgroundColor: "#f4ebff",
            color: "#715d7d",
            fontWeight: "700",
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#faf6ff",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return {
        StyledTableCell,
        GreenStyledTableCell,
        RedStyledTableCell,
        CustomStyledTableCell1,
        CustomStyledTableCell2,
        StyledTableRow,
    };
};
