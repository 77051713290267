import { useContext, useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Avatar, Grid, TextareaAutosize, Stack } from "@mui/material";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { postCommentsTikets } from "_models/redux/request-comments-tickets/action";
import { listcommentsTikets } from "_models/redux/request-list-comments/action";

import { useForm } from "react-hook-form";
import { LoadersContext } from "_models/context/app";

const CommentTickets = (props) => {
    const [comment, setComments] = useState([]);
    const [message, setMessage] = useState("");
    const [ticketId, setTicketId] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const { commentTikets, listCommentTickets } = props || {};
    const loadersContext = useContext(LoadersContext);
    const { setIsScreenLoading } = loadersContext || {};

    let params = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (e) => {
        setIsLoading(true);

        const params = {
            message: message,
        };
        try {
            await props.postCommentsTikets(params, ticketId);
            setMessage("");
        } catch (error) {
            console.error("Error al agregar el comentario:", error);
            setIsLoading(false);
        }
    };

    const cancelData = (e) => {
        e.preventDefault();
        setMessage("");
    };

    const registerOptions = {
        message: {
            required: "Escribe un comentario",
            minLength: {
                value: 10,
                message: "mínimo 10 caracteres",
            },
            maxLength: {
                value: 1500,
                message: "máximo 1500 caracteres",
            },
        },
    };

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setTicketId(params.ticketId);
    }, []);

    useEffect(() => {
        const { commentTiketsObj } = commentTikets || {};
        const { message } = commentTiketsObj || {};

        if (message == "success") {
            setIsLoading(false);
            listTicketsComments();
        }
    }, [commentTikets]);

    // Utilizamos useCallback para memorizar la función y evitar llamadas innecesarias
    const listTicketsComments = useCallback(() => {
        if (typeof ticketId !== "undefined") {
            props.listcommentsTikets(ticketId);
        }
    }, [ticketId]);

    useEffect(() => {
        setIsScreenLoading(isLoading);
    }, [isLoading, setIsScreenLoading]);
    useEffect(() => {
        // Llamar a la función para obtener los comentarios del ticket cuando se monte el componente o cuando cambie el ticketId
        listTicketsComments();
    }, [ticketId, listTicketsComments]);
    useEffect(() => {
        const { loading, listCommentTicketObj } = listCommentTickets || {};
        const { comments } = listCommentTicketObj || {};

        if (
            loading &&
            typeof comments !== "undefined" &&
            Array.isArray(comments)
        ) {
            if (comments.length > 0) {
                setComments(comments);
            }
        }
    }, [listCommentTickets]);

    return (
        <Grid container className="comments">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2>Comentarios y eventos del ticket</h2>
                <Grid item xs={12} className="comment-border">
                    <TextareaAutosize
                        {...register("message", registerOptions.message)}
                        minRows={1}
                        aria-label=""
                        placeholder="Agrega un comentario"
                        className="comment-box"
                        value={message}
                        onChange={(event) => {
                            setMessage(event.target.value);
                        }}
                    />
                </Grid>
                <span>
                    {errors?.message && (
                        <p className="required-incidence">
                            {" "}
                            {errors.message.message}
                        </p>
                    )}
                </span>
                <Stack direction="row" justifyContent="end" className="m-t-10">
                    <button onClick={cancelData} id="cancelBtn">
                        Cancelar
                    </button>
                    <Button
                        // FIX: Warning: Received `true` for a non-boolean attribute `enabled`. If you want to write it to the DOM, pass a string instead: enabled="true" or enabled={value.toString()}.
                        // enabled={!commentText}
                        themeColor={"primary"}
                        type={"submit"}
                    >
                        Guardar
                    </Button>
                </Stack>
            </form>
            <Grid item xs={12} className={"list-box m-t-20"}>
                {comment.map((comment, index) => {
                    let viewName = comment?.name;
                    let viewLastname = comment?.last_name;
                    const created = moment
                        .unix(comment.created)
                        .format("DD/MM/YYYY");
                    return (
                        <Grid
                            container
                            className="row"
                            key={comment.id}
                            display={"flex"}
                        >
                            {comment.format_type != "PLAIN_MESSAGE" ? (
                                <Grid
                                    item
                                    xs={10}
                                    textAlign={"start"}
                                    display={"block"}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <Avatar className={"avatar"}>
                                            {viewName?.charAt(0) +
                                                viewLastname?.charAt(0)}
                                        </Avatar>

                                        <p>
                                            <b className="user">{viewName}</b>
                                        </p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div>
                                            <p>
                                                {comment.message} {"  "}{" "}
                                                <b> (#{ticketId})</b>{" "}
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={10}
                                    textAlign={"start"}
                                    display={"block"}
                                >
                                    <p>
                                        {comment.data?.message}
                                        <b> (#{ticketId})</b>{" "}
                                    </p>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={2}
                                display={"flex"}
                                justifyContent={"flex-end"}
                                textAlign={"right"}
                                className="events-date"
                            >
                                <p>{created}</p>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

//export default CommentTickets;
const mapStateToProps = (state) => ({
    commentTikets: state.commentTikets,
    listCommentTickets: state.listCommentTickets,
});
const mapDispatchToProps = {
    postCommentsTikets,
    listcommentsTikets,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentTickets);
