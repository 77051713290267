import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const PageDetailTransferSaleHook = ({
    detailTransferSalesAction,
    cleanDetailTransferSalesClean,
    detailTransferSales,
    processTransferSales,
    processTransferSalesAction,
    processTransferSalesClean,
}) => {
    const { payload } = detailTransferSales || {};
    const [loading, setLoading] = useState(true);
    const [transferId, setTransferId] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogContent, setDialogContent] = useState("");
    const user_permissions = localStorage.getItem("user_permissions");

    const { id } = useParams();
    const history = useNavigate();

    const handleGoBack = () => {
        history("/sales/ejecutivo/list-transfer-sales");
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (processTransferSales?.state === "success") {
            setDialogTitle("Éxito");
            setDialogContent("La transferencia ha sido procesada con éxito.");
            setDialogOpen(true);
            setLoading(false);
        } else if (processTransferSales?.payload?.response?.status > 200) {
            setDialogTitle("Error");
            setDialogContent(
                processTransferSales?.payload?.response?.data?.detail ||
                    "Error al procesar la transferencia"
            );
            setDialogOpen(true);
            setLoading(false);
        }
    }, [processTransferSales]);

    useEffect(() => {
        return () => {
            processTransferSalesClean();
            setDialogOpen(false);
            setLoading(false);
        };
    }, [processTransferSalesClean]);

    useEffect(() => {
        setLoading(true);
        handleCloseDialog(); // Restablece el contenido del diálogo cada vez que se recargan los detalles

        detailTransferSalesAction(id)
            .then(() => setLoading(false))
            .catch((error) => {
                setDialogTitle("Error");
                setDialogContent(
                    "Error al cargar los detalles de la transferencia."
                );
                setDialogOpen(true);
                setLoading(false);
            });

        return () => {
            cleanDetailTransferSalesClean(); // Limpieza al salir
        };
    }, [detailTransferSalesAction, cleanDetailTransferSalesClean, id]);

    const handleTransferIdChange = (event) => {
        setTransferId(event.target.value);
    };

    const handleSendTransferId = () => {
        setLoading(true);
        processTransferSalesAction({
            sim_purchase_by_transfer_id: parseInt(id),
            bank_transaction: transferId,
        });
    };

    return {
        transferId,
        handleTransferIdChange,
        handleSendTransferId,
        payload,
        loading,
        handleGoBack,
        dialogOpen,
        handleCloseDialog,
        dialogTitle,
        dialogContent,
        user_permissions,
    };
};
