import {
    LIST_COMMENT_TICKETS,
    LIST_COMMENT_TICKETS_ERROR,
} from "_models/redux/types";

const initialState = {
    listCommentTicketObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_COMMENT_TICKETS:
            return {
                ...state,
                listCommentTicketObj: action.payload,
                loading: true,
            };

        case LIST_COMMENT_TICKETS_ERROR:
            return {
                ...state,
                listCommentTicketObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
